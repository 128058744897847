import { CategoryViewModel } from 'app/shared/models/category/category.model';
import { GroupResult } from '..';
import { QuestionFilterSettings } from 'app/portal/page/page-item-filter-bar/question-filter/question-filter-settings.model';

export enum NoResultReason {
  AnonNotOk = 'AnonNotOk',
  AnonNotOkView = 'AnonNotOkView',
  NotInSnapshot = 'NotInSnapshot',
  NoAnswer = 'NoAnswer',
  BenchmarkNotAvailable = 'BenchmarkNotAvailable',
  AnonNotOkNPS = 'AnonNotOkNPS',
  QuestionNotInSnapshot = 'QuestionNotInSnapshot',
  TooSmallSample = 'TooSmallSample',
  TooSensitive = 'TooSensitive'
}

export enum ResultDisplay {
  Bar = 'Bar',
  StackedBar = 'StackedBar',
  Text = 'Text'
}

export enum ResultSummaryDisplay {

  None = 'None',
  Average = 'Average',
  EnpsIndex = 'EnpsIndex'
}
export const BENCHMARK_SNAPSHOT_NAME = 'Benchmark';
export interface QuestionResultsData {
  categories: QuestionCategory[];
  groupResults: GroupResult[];
  limitedGroups: boolean;
  selectedSnapshots: SelectedSnapshotViewModel[];
  showCategoryHeader: boolean;
  showLogic: boolean;
  showSettings: boolean;
  collapsable: boolean;
  questions: QuestionResult[];
}
export interface OptionGroupingViewModel {
  optionGroupViewKey: string;
  optionGroupViewName: string;
}
export interface SelectedSnapshotViewModel {
  date: Date;
  id: number;
  isBenchmark: boolean;
  name: string;
  createTime?: Date;
  surveyId?: number;
}

export interface QuestionCategory {
  description?: string;
  icon?: string;
  id: number;
  key: string;
  name: string;
}

export interface QuestionCategoryDisplay {
  key: string;
  value: QuestionResult[];
}

export interface OptionGroup {
  color: string;
  id: number;
  key: string;
  name: string;
  order: number;
}

export interface OptionGrouping {
  fieldOptionGroupName: string;
  optionGroups: OptionGroup[];
}
export interface QuestionResult {
  categories: CategoryViewModel[];
  _categories?: string[];
  defaultOrder: number;
  fieldKey: string;
  hash: number | string;
  hasLogic: boolean;
  id: number;
  optionGroupings: OptionGrouping[];
  questionKey: string;
  questionText: string;
  resultDisplay: string;
  resultSummaryDisplayType: string;
  results: OptionGroupingResult[];
  totalAverageRounded: number;
  visible: boolean;
  max: number;
  _filteredResults?: OptionGroupingResult[];
}

export interface OptionGroupingResult {
  answerCount?: number;
  answerPercentage?: number;
  excludedAnswerCount?: number;
  expectedAnswerCount?: number;
  groupId: number;
  groupName: string;
  filterId: number;
  filterName: string;
  groupResultId: number;
  isBenchmark: boolean;
  noResultReason?: NoResultReason;
  optionGroupResults: OptionGroupResult[];
  order: number;
  snapshotDate: string;
  snapshotId: number;
  snapshotName: string;
  snapshotLabel?: string;
  value?: number;
  valueRounded?: number;
  values?: string[];
  _filteredoptionGroupResults?: OptionGroupResult[];
}

export interface OptionGroupResult {
  anonOk?: boolean;
  color?: string;
  count?: number;
  questionAnswerCount?: number;
  groupResultOrder: number;
  name: string;
  noResultReason?: NoResultReason;
  optionGroupId?: number;
  optionGroupingKey?: string;
  optionOrder: number;
  percent?: number;
  percentFormatted?: number;
  questionOptionGroupKey?: string;
  isBenchmark?: boolean;
  isIndexPage?: boolean;
}

export interface OptionView {
  optionGroupId: number;
  optionName: string;
  optionOrder?: number;
  noResultReason?: NoResultReason;
  answerCount?: number;
  answerPercentage?: number;
  valueRounded?: number;
  expectedAnswerCount?: number;
  excludedAnswerCount?: number;
  optionGroupingResults: OptionGroupingResultView;
}

export interface OptionGroupingResultView {
  groupId: number | string;
  groupName: string;
  snapshotId: number;
  snapshotName: string;
  snapshotDate: string;
  optionGroupResult: OptionGroupResult;
  isBenchmark: boolean;
}

export interface CategoryQuestionTonalityResultsModel {
  models: QuestionTonalityResultsData[];
  questionFilter: QuestionFilterSettings;
  selectedSnapshots: SelectedSnapshotViewModel[];
  groupResults: GroupResult[];
  defaultFilterQuestionKey: string;
}
export interface QuestionTonalityResultsData {
  categoryName: string;
  categoryId: number;
  questionTonalityListModels: QuestionTonalityResultsListModel[];
}
export interface QuestionTonalityResultsListModel {
  options: OptionGroupResult[];
  tonality: number;
  name: string;
  date: Date;
  count: number;
  questionKey: string;
  groupId: number;
  filterName: string;
}
export interface FreetextTonalityModel {
  freetextTonalityResponses: FreetextTonalityResponse[];
  questionFilter: QuestionFilterSettings;
  selectedSnapshots: SelectedSnapshotViewModel[];
  groupResults: GroupResult[];
  defaultFilterQuestionKey: string;
  settings: FreetextTonalitySettings;
}
export interface FreetextTonalityResponse {
  responseId: number;
  answered: Date,
  text: string;
  tonality: string;
  categories: FreetextCategoryTonalityResponse[];
  questionId: number;
  questionKey: string;
  userId: number;
}
export interface FreetextCategoryTonalityResponse {
  tonality: string;
  categoryId: number;
  categoryName: string;
}

export interface FreetextTonalitySettings {
  commentQuestionKey: string;
  valueQuestionKey: string;
}
