<span class="uikit-button-wrapper" [style.visibility]="!showAnimationContainer ? 'inherit' : 'hidden'">
  <uikit-icon *ngIf="icon" class="button-icon" [icon]="icon"></uikit-icon>
  <span class="label">
    <ng-content></ng-content>
  </span>
</span>

<div [style.display]="showAnimationContainer ? 'flex' : 'none'" class="animation-wrapper">
  <div #bodyMovin class="animation-container" [@fadeAnimation]="showAnimationContainer ? 'in': 'out'"></div>
</div>

<div class="uikit-button-focus-overlay"></div>
