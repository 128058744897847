import { ContentItem, ContentItemDetailed, ContentItemDetailedCreate } from 'app/shared/models';

export enum ContentTypes {
  html = 'html',
  json = 'json',
  markdown = 'markdown',
  text = 'text'
}

export interface Content {
  id: number;
  companyId?: number;
  key: string;
  renderOptions?: string;
  contentItems: ContentItem[];
  type?: string;
  visible?: boolean
  masterLanguageId?: number;
}

export type CreateContentDto = Omit<Content, 'id'>;

/* !TODD Refactoring of models */
/* Content and ContentDetailed are basically the same, but is used instead of EditContentModel in some cases */
export interface ContentDetailed {
  id: number;
  companyId?: number;
  key?: string;
  contentTypeId?: number;
  renderOptions?: string;
  contentItems: ContentItemDetailed[];
  visible?: boolean;
  masterLanguageId?: number;
  order?: number;
}

export type ContentDetailedCreate = Omit<ContentDetailed, 'id' | 'contentitems'>
  & { id?: number, contentItems?: ContentItemDetailedCreate[] };

export type ContentItemDetailedWrapper = Pick<ContentDetailed, 'contentItems'>;

export interface ContentList {
  id: number;
  key: string;
  text: string[];
}

export type ContentDetailedList = ContentDetailed[] | ContentDetailedCreate[];
