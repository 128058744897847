import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MenuItem, MenuVisibility, UserState } from 'app/shared/models';
import { ConfigurationService } from 'app/shared/services/configuration.service';
import { AppState } from 'app/state/app.state';
import { Observable } from 'rxjs';
import { filter, mergeMap, take, withLatestFrom } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  constructor(private _http: HttpClient, private _config: ConfigurationService, private _store: Store<AppState>) {
  }

  getMenu(): Observable<MenuItem[]> {
    return this._store.select(state => state.userState).pipe(
      filter(state => state != null && state.companyParameters.length > 0),
      withLatestFrom(this._store.select(state => state.userInfo), this._store.select(state => state.companyContext)),
      filter(([_, userInfo, companyContext]) => companyContext.id !== null && userInfo !== null),
      take(1),
      mergeMap(([userState, userInfo, companyContext]) => {
        const parameters = {
          ...userState,
          userId: userInfo.userId,
          currentCompanyId: companyContext.id,
          languageId: userInfo.displayLanguageId,
        };
        return this._http.post<MenuItem[]>(`${this._config.apiUrl}/api/menus`, parameters);
      }),
    );
  }

  getMenuVisibility(parameters: UserState, menuItems: MenuItem[]): Observable<MenuVisibility> {
    return this._http.post<MenuVisibility>(`${this._config.apiUrl}/api/menu/visibility`, { parameters, menuVisibilityState: menuItems });
  }

}
