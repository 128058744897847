import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ContentDetailed,
  ContentExport,
  ContentList,
  ContentType,
  EditContent,
  EditQuestionContent,
  ExportableEntities,
  ExportContentSettings,
  ImportContentModel,
  ImportContentStatistics
} from 'app/shared/models';
import { ConfigurationService } from 'app/shared/services/configuration.service';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ContentService {
    private _contentTypes: Observable<ContentType[]>;
    constructor(private _http: HttpClient, private _config: ConfigurationService) { }

    get(id: number): Observable<ContentDetailed> {
        return this._http.get<ContentDetailed>(`${this._config.apiUrl}/api/content/${id}`);
    }

    getForEdit(id: number): Observable<EditContent> {
        return this._http
            .get<EditContent>(`${this._config.apiUrl}/api/content/${id}/edit`);
    }
    getItemValue(key: string, moduleKey: string, companyId: number, lang: number) {
        return this._http
            .get<string>(`${this._config.apiUrl}/api/content/${key}/module/${moduleKey}/company/${companyId}/lang/${lang}`);
    }

    create(model: EditContent): Observable<number> {
        return this._http
            .post<number>(`${this._config.apiUrl}/api/content`, model);
    }
    createQuestionContent(model: EditQuestionContent): Observable<EditQuestionContent> {
        return this._http
            .post<EditQuestionContent>(`${this._config.apiUrl}/api/content/question`, model);
    }
    update(model: EditContent): Observable<void> {
        return this._http
            .put<void>(`${this._config.apiUrl}/api/content/${model.id}`, model);
    }

    getContentTypes(): Observable<ContentType[]> {
        if (!this._contentTypes) {
            this._contentTypes = this._http
                .get<ContentType[]>(`${this._config.apiUrl}/api/content/types`).pipe(
                    shareReplay(1));
        }
        return this._contentTypes;
    }
    getByCompany(companyId: number): Observable<ContentList[]> {
        return this._http.get<ContentList[]>(`${this._config.apiUrl}/api/companies/${companyId}/content`);
    }
    getByCompanyAndType(companyId: number, types: number[]): Observable<ContentList[]> {
      return this._http.get<ContentList[]>(`${this._config.apiUrl}/api/companies/${companyId}/content/moduletype?types=${types.join((','))}`);
    }
    getCustomAttributeContentByCompany(companyId: number, attributeId: number): Observable<number> {
        return this._http.get<number>(`${this._config.apiUrl}/api/content/companies/${companyId}/attributes/${attributeId}`);
    }
    getCustomCategoryContentByCompany(companyId: number, attributeId: number): Observable<number> {
        return this._http.get<number>(`${this._config.apiUrl}/api/content/companies/${companyId}/categories/${attributeId}`);
    }
    getByModule(companyId: number, moduleId: number): Observable<ContentList[]> {
        return this._http.get<ContentList[]>(`${this._config.apiUrl}/api/companies/${companyId}/modules/${moduleId}/content`);
    }
    getExportableEntities(companyId: number) {
        return this._http.get<ExportableEntities>(`${this._config.apiUrl}/api/content/exportable-entities`,
            { params: { companyId: companyId.toString() } });
    }
    getExportableContent(companyId: number, languageId: number, settings: ExportContentSettings) {
        return this._http.put<ContentExport[]>(`${this._config.apiUrl}/api/content/export`, settings,
            { params: { companyId: companyId.toString(), languageId: languageId.toString() } });
    }
    importContent(content: ImportContentModel) {
        return this._http.post(`${this._config.apiUrl}/api/content/import`, content);
    }
    getImportLogs(companyId: number): Observable<ImportContentStatistics[]> {
        return this._http.get<ImportContentStatistics[]>(`${this._config.apiUrl}/api/content/import-statistics`,
            { params: { companyId: companyId.toString() } });
    }
}
