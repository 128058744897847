import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, Renderer2 } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { PinCodeService } from 'app/shared/services';
import * as RouteActions from 'app/state/actions/navigation.actions';
import { AppState } from 'app/state/app.state';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pin-code-logon',
  templateUrl: './pin-code-logon.component.html',
  styleUrls: ['./pin-code-logon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PinCodeLogonComponent implements OnInit {

  errorLoggingIn = false;
  submitting = false;
  pinCodeFormControl = new FormControl<string>(this.route.snapshot.queryParams['pin-code'] ?? '', []);

  constructor(private pinCodeService: PinCodeService,
    private translateService: TranslocoService,
    private store: Store<AppState>,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef) {
    this.renderer.removeAttribute(document.body, 'class');
    this.renderer.addClass(document.body, 'pin-login');
  }

  ngOnInit() {
    let userLang = navigator.language || window.navigator.language;
    if (userLang.includes('-')) {
      userLang = userLang.substring(0, userLang.indexOf('-'));
    }
    if (this.translateService.getDefaultLang() !== userLang) {
      this.translateService.setActiveLang(userLang)
    }

    const pinCode = this.route.snapshot.queryParams['pin-code'];
    if(pinCode) {
      this.pinCodeLogon();
    }
  }

  pinCodeLogon() {
    if (!this.pinCodeFormControl.value) { return; }
    this.submitting = true;
    this.errorLoggingIn = false;
    this.pinCodeService.pinCodeLogon(this.pinCodeFormControl.value.toUpperCase())
      .subscribe({
        next: (token) => { this.store.dispatch(new RouteActions.Go({ path: ['/link-logon', token] })); },
        error: (e: HttpErrorResponse) => {
          this.submitting = false;
          this.errorLoggingIn = true;
          this.cdr.markForCheck();
          const message: string = e.error?.message;
          console.log(e, message);
          if (message === 'pinCode has expired.') {
            this.pinCodeFormControl.setErrors({ 'expired': true });
          } else {
            this.pinCodeFormControl.setErrors({ 'invalid': true });
          }
        },
        complete: () => { this.submitting = false; }

      }
      );
  }
}
