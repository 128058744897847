import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Meta } from 'app/shared/models';
import { ConfigurationService } from 'app/shared/services/configuration.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MetaService {

    constructor(private _http: HttpClient, private _config: ConfigurationService) {
    }
    get(): Observable<Meta[]> {
        return this._http.get<Meta[]>(`${this._config.apiUrl}/api/meta`);
    }

    getByAppliesTo(appliesTo: string): Observable<Meta[]> {
        return this._http.get<Meta[]>(`${this._config.apiUrl}/api/meta/for/${appliesTo}`);
    }

    getMetaValuesByKey(metaKey: string): Observable<string[]> {
        return this._http.get<string[]>(`${this._config.apiUrl}/api/meta/MetaValues?metaKey=${metaKey}`);
    }
}
