import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'app/shared/services';
import { NeedsToUnsubscribe } from 'app/shared/core';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-forgotten-password',
  templateUrl: './forgotten-password.component.html'
})
export class ForgottenPasswordComponent extends NeedsToUnsubscribe implements OnInit {

  constructor(private authService: AuthenticationService, private route: ActivatedRoute) { super(); }

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe(params => {
      const token = params['token'];
      this.authService.startChangePassword(token);
    });
  }

}
