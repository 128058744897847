import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    Index, CreateIndex, IndexCalculationType, CopyIndex,
    ContentItem, ReportPageItemType, ReportPageItemDto
} from 'app/shared/models';
import { forkJoin, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ConfigurationService } from 'app/shared/services/configuration.service';
import { ReportPageService } from '.';
import { toReportPageIndexKey } from 'app/admin-tpl/company-overview/reports/templates/default/index-report-page';

@Injectable({
    providedIn: 'root'
})
export class IndexService {
    constructor(private http: HttpClient, private config: ConfigurationService, private reportPageService: ReportPageService) {
    }
    list(): Observable<Index[]> {
        return this.http.get<Index[]>(`${this.config.apiUrl}/api/indexes`);
    }

    getByCompanyOrCore(companyId: number): Observable<Index[]> {
      return this.http.get<Index[]>(`${this.config.apiUrl}/api/indexes`, { params: { companyId: companyId}});
    }

    get(id: number): Observable<Index> {
        return this.http.get<Index>(`${this.config.apiUrl}/api/indexes/${id}`);
    }

    getForEdit(id: number): Observable<CreateIndex> {

        return this.http.get<CreateIndex>(`${this.config.apiUrl}/api/indexes/${id}/edit`);
    }

    update(model: CreateIndex) {
        return this.http.put<void>(`${this.config.apiUrl}/api/indexes/${model.indexId}`, model);
    }

    addIndexToReportStructure(companyId: number, indexKey: string, pageReportContents: ContentItem[]) {
        return this.reportPageService
            .createReportPage(companyId, 'indexes', indexKey, pageReportContents)
            .pipe(switchMap(() => forkJoin([
                this.updateOverviewPageItem(companyId, indexKey, ReportPageItemType.LatestSummary),
                this.updateOverviewPageItem(companyId, indexKey, ReportPageItemType.PreviousOverviewResults),
            ])));
    }


    private updateOverviewPageItem(companyId: number, indexKey: string, type: ReportPageItemType) {
        return this.reportPageService.getReportPageItem(
            companyId,
            'overview',
            type
        ).pipe(switchMap(dto =>
            this.reportPageService.createReportPageItem(
                companyId,
                'overview',
                this.modifyOverviewSettings(dto, indexKey)
            )
        ));
    }

    private modifyOverviewSettings(pageItem: ReportPageItemDto, indexKey: string) {

        // Make sure pageItem is defined and is an object
        const indexReportPageItem = (typeof pageItem === 'object' ? { ...pageItem } : {} as ReportPageItemDto);

        // Make sure settings property is defined and is an object
        if (typeof (indexReportPageItem.settings) !== 'object') {
            indexReportPageItem.settings = {};
        }

        // Make sure indexes is defined and is an array
        if (!Array.isArray(indexReportPageItem.settings.indexes)) {
            indexReportPageItem.settings.indexes = [];
        }

        // assign to new variable for easier access
        const pageItemIndexes = indexReportPageItem.settings.indexes;

        // Initialize index setting
        const newIndexSetting = {
            Key: indexKey,
            SubResultKey: toReportPageIndexKey(indexKey),
        };
        // If the key already exists, update the setting at the same position. Otherwise, push it to the end
        const settingIdx = pageItemIndexes.findIndex(s => (typeof s === 'string' ? s : s.Key) === indexKey);
        if (settingIdx >= 0) {
            pageItemIndexes[settingIdx] = newIndexSetting;
        } else {
            pageItemIndexes.push(newIndexSetting);
        }
        return indexReportPageItem;
    }

    indexCalculationTypes(): Observable<IndexCalculationType[]> {
        return this.http.get<IndexCalculationType[]>(`${this.config.apiUrl}/api/indexes/calculationtypes`);
    }

    create(model: CreateIndex): Observable<Index> {
        return this.http.post<Index>(`${this.config.apiUrl}/api/indexes`, model);
    }

    copy(model: CopyIndex): Observable<Index> {
        return this.http.post<Index>(`${this.config.apiUrl}/api/indexes/copy`, model);
    }
    delete(id: number): Observable<boolean> {
        return this.http.delete<boolean>(`${this.config.apiUrl}/api/indexes/${id}`);
    }
}
