import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ConfigurationService } from 'app/shared';
import {
  AllowedMoveCategories,
  CreateCustomGroupCategoryRequest, CreateCustomGroupCategoryResponse,
  CreateGroupCategory,
  EditGroupCategory,
  EditGroupCategoryModel,
  GroupCategoryList,
  GroupCategoryRelation,
  GroupList,
  HierarchyLevelChildrenViewModel
} from 'app/shared/models';
import { AppState } from 'app/state/app.state';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GroupCategoryService {

  private _allowedMoveCategories: Map<number, Observable<AllowedMoveCategories[]>> = new Map();
  private _creatableCategories: Map<number, Map<number, Observable<HierarchyLevelChildrenViewModel[]>>> = new Map();

  constructor(
    private _http: HttpClient,
    private _config: ConfigurationService,
    private store: Store<AppState>) {
  }

  list(companyId?: number): Observable<GroupCategoryList[]> {
    const params = companyId ? {
      companyId: companyId
    } : {};
    return this._http
      .get<GroupCategoryList[]>(`${this._config.apiUrl}/api/groupcategories`, {
        params: params
      });
  }

  create(model: CreateGroupCategory): Observable<GroupCategoryList> {
    return this._http
      .post<GroupCategoryList>(`${this._config.apiUrl}/api/groupcategories`, model);
  }

  getForEdit(id: number): Observable<CreateGroupCategory> {
    return this._http.get<CreateGroupCategory>(`${this._config.apiUrl}/api/groupcategories/${id}/edit`);
  }

  update(model: CreateGroupCategory): Observable<void> {
    return this._http.put<void>(`${this._config.apiUrl}/api/groupcategories/${model.id}`, model);
  }

  companyGroupCategories(companyId: number): Observable<GroupCategoryList[]> {
    return this._http
      .get<GroupCategoryList[]>(`${this._config.apiUrl}/api/groupcategories/companies/${companyId}`);
  }

  companyGroupCategoriesCreatable(companyId: number, hierarchyId: number): Observable<HierarchyLevelChildrenViewModel[]> {
    if (!this._creatableCategories.get(companyId)) {
      this._creatableCategories.set(companyId, new Map());
    }

    const companyCreatableCategories = this._creatableCategories.get(companyId);

    if (!companyCreatableCategories.get(hierarchyId)) {
      companyCreatableCategories.set(hierarchyId,
        this._http.get<HierarchyLevelChildrenViewModel[]>(`${this._config.apiUrl}/api/groupcategories/creatable?companyId=${companyId}&&hierarchyId=${hierarchyId}`)
          .pipe(shareReplay(1))
      );
    }
    return companyCreatableCategories.get(hierarchyId);
  }

  getByCompanyForEdit(companyId: number): Observable<EditGroupCategoryModel[]> {
    return this._http
      .get<EditGroupCategoryModel[]>(`${this._config.apiUrl}/api/groupcategories/companies/${companyId}/edit`);
  }

  addRelations(companyId: number, groupIds: number[]): Observable<number> {
    const model: GroupList = {
      companyId: companyId,
      groupIds: groupIds
    };

    return this._http
      .post<number>(`${this._config.apiUrl}/api/groupcategories/relations`, model);
  }
  getGroupCategoryRelations(companyId: number): Observable<GroupCategoryRelation[]> {
    return this._http
      .get<GroupCategoryRelation[]>(`${this._config.apiUrl}/api/companies/${companyId}/relation`);
  }
  createGroupCategoryRelation(companyId: number, relation: GroupCategoryRelation): Observable<GroupCategoryRelation[]> {
    return this._http
      .post<GroupCategoryRelation[]>(`${this._config.apiUrl}/api/companies/${companyId}/relation`, relation);
  }
  deleteGroupCategoryRelation(companyId: number, relationId: number): Observable<GroupCategoryRelation[]> {
    return this._http
      .delete<GroupCategoryRelation[]>(
        `${this._config.apiUrl}/api/groupcategories/companies/${companyId}/relation/${relationId}`);
  }
  updateCompanyMappedCategories(companyId: number, mappedCategories: EditGroupCategory[]): Observable<void> {
    return this._http.put<void>(`${this._config.apiUrl}/api/groupcategories/companyMapped/${companyId}`, mappedCategories);
  }

  getAllowedMoveCategories(companyId: number): Observable<AllowedMoveCategories[]> {
    if (!this._allowedMoveCategories.get(companyId)) {
      this._allowedMoveCategories.set(companyId, this._http
        .get<AllowedMoveCategories[]>(`${this._config.apiUrl}/api/groupcategories/companies/${companyId}/allowedMoves`).pipe(
          shareReplay(1)));
    }
    return this._allowedMoveCategories.get(companyId);
  }

  getCompanyBackgroundGroupCategory(companyId: number): Observable<GroupCategoryList[]> {
    return this._http
      .get<GroupCategoryList[]>(`${this._config.apiUrl}/api/groupcategories/companies/${companyId}/background`);
  }

  createCustomGroupCategory(model: CreateCustomGroupCategoryRequest):Observable<CreateCustomGroupCategoryResponse> {
    return this._http.post<CreateCustomGroupCategoryResponse>(`${this._config.apiUrl}/api/groupcategories/custom`, model);
  }
}
