import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'app/shared/services';
import { takeUntil } from 'rxjs/operators';
import { NeedsToUnsubscribe } from 'app/shared/core';

@Component({
    selector: 'app-impersonate-logon',
    templateUrl: './impersonate-logon.component.html'
})
export class ImpersonateLogonComponent extends NeedsToUnsubscribe implements OnInit {
    constructor(private authService: AuthenticationService, private route: ActivatedRoute) { super(); }

    ngOnInit() {
        this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe(params => {
            this.authService.startSigninImpersonate(params['info']);
        });
    }
}

