import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DetailedLibrary,
  EditLibrary,
  Library,
  LibrarySelection,
  ModuleWithQuestionsAndTranslations,
  UpdateLibrary
} from 'app/shared/models';
import { ConfigurationService } from 'app/shared/services/configuration.service';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LibraryService {
  constructor(
    private _http: HttpClient,
    private _config: ConfigurationService
  ) { }

  get(id: number, companyId: number): Observable<DetailedLibrary> {
    return this._http.get<DetailedLibrary>(
      `${this._config.apiUrl}/api/libraries/${id}/companies/${companyId}`
    );
  }
  getQuestionsByModule(companyId: number, id: number): Observable<ModuleWithQuestionsAndTranslations> {
    return this._http.get<ModuleWithQuestionsAndTranslations>(
      `${this._config.apiUrl}/api/companies/${companyId}/libraries/${id}/modulequestions`
    );
  }
  getExport(id: number): Observable<any> {
    return this._http.get<any>(
      `${this._config.apiUrl}/api/libraries/${id}/export`
    );
  }
  getByCompany(companyId: number): Observable<Library[]> {
    return this._http.get<Library[]>(
      `${this._config.apiUrl}/api/companies/${companyId}/libraries`
    );
  }

  list(): Observable<LibrarySelection[]> {
    return this._http.get<LibrarySelection[]>(
      `${this._config.apiUrl}/api/libraries`
    );
  }
  create(library: EditLibrary): Observable<Library> {
    return this._http.post<Library>(
      `${this._config.apiUrl}/api/libraries`,
      library
    );
  }

  update(
    libraryId: number,
    library: EditLibrary
  ): Observable<HttpResponse<Object>> {
    return this._http.put(
      `${this._config.apiUrl}/api/libraries/${libraryId}`,
      library,
      { observe: 'response', responseType: 'text' }
    );
  }

  updateCompanyLibraries(
    companyId: number,
    libraryId: number,
    library: UpdateLibrary
  ): Observable<Library> {
    return this._http.put<Library>(
      `${this._config.apiUrl
      }/api/companies/${companyId}/libraries/${libraryId}`,
      library
    );
  }
}

