import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'app/shared/services';

@Component({
    selector: 'app-signout-callback',
    templateUrl: './signout-callback.component.html'
})
export class SignoutCallbackComponent implements OnInit {


    constructor(private authService: AuthenticationService) {
        authService.endSignoutMainWindow();
    }

    ngOnInit() {

    }

}

