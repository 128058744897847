import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClearAnswersModel } from 'app/shared/models';
import { ConfigurationService } from 'app/shared/services/configuration.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ClearAnswersService {
    constructor(private _http: HttpClient, private _config: ConfigurationService) {
    }
    getModel(surveyId: number): Observable<ClearAnswersModel> {
        return this._http
            .get<ClearAnswersModel>(`${this._config.apiUrl}/api/results/survey/${surveyId}/clear-answers`);
    }
    clearAnswers(surveyId: number, userIds: number[]): Observable<void> {
        return this._http
          .post<void>(`${this._config.apiUrl}/api/results/survey/${surveyId}/clear-answers`, userIds);
      }
}
