import { SurveyResponse } from 'app/shared/models/survey';
import { AnswerImportMap } from 'app/shared/models/organization';

export interface GenerateAnswerSettings {
  skipPercent: number;
  answerFrequency: number;
  stringAnswerGenerationMode: StringAnswerGenerationMode;
  generateTonality: boolean;
  exportOnly?: boolean;
}

export interface ExportGeneratedAnswersModel {
  responses: ExportAnswerModel[]
}
export interface ExportAnswerModel {
  email:string;
  answers: ExportedAnswer[],
  attributes: ExportedAttribute[];
}

export interface ExportedAnswer {
  question: string;
  answer: any;
  skipValue?: number;
}
export interface ExportedAttribute {
  attributeKey: string;
  value: string;
}
export enum StringAnswerGenerationMode {
  EmployeeSentences = 'EmployeeSentences',
  CustomerSentences = 'CustomerSentences',
  RandomWord = 'RandomWord'
}

export interface ValidateImportAnswers {
  surveyId: number;
  answerRows: unknown[];
  answerImportMap: AnswerImportMap;
}

export interface ImportAnswers {
  surveyId: number;
  responses: SurveyResponse
}
