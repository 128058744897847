import { Option } from 'app/shared/models';

export enum QuestionOptionType {
  Single = 'Single',
  Multi = 'Multi',
  FiveGrade = 'FiveGrade',
  ElevenGrade = 'ElevenGrade',
  Text = 'Text',
  Other = 'Other'
}

export enum FieldDisplayType {
  ScaleButton = 'ScaleButton',
  RadioButton = 'RadioButton',
  TextBox = 'TextBox',
  NumericTextbox = 'NumericTextbox',
  Textarea = 'Textarea',
  Slider = 'Slider',
  CheckboxButton = 'CheckboxButton',
  DatePicker = 'DatePicker',
  CustomText = 'CustomText',
  ScaleButtonNPSCx = 'ScaleButtonNPSCx'
}
export enum FieldType {
  Text = 'Text',
  Number = 'Number',
  Date = 'Date',
  SingleChoice = 'SingleChoice',
  MultipleChoice = 'MultipleChoice'
}
export interface Field {
  questionFieldId: number;
  fieldDisplayId: number;
  fieldKey: string;
  fieldDisplayType: FieldDisplayType;
  dataType: string;
  questionOptions: Option[];
  answer: string;
  skipValue: number;
}

export interface QuestionEditField {
  name: string;
  questionFieldId: number;
  fieldKey: string;
  companyId?: number;
  questionOptions: EditQuestionOption[];
  selectable: boolean;
}

export interface EditQuestionOption {
  contentId?: number;
  text: string;
  value: number;
  optionKey: string;
}

export interface HelpText {
  contentId: number;
  helpTextKey: string;
}
