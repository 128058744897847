import { animate, state, style, transition, trigger } from '@angular/animations';
import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import bodymovin, { AnimationItem } from 'lottie-web';
import { fromEvent } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import buttonCompletedState from '../../../../assets/animations/button_completed_state.json';
import buttonFailedState from '../../../../assets/animations/button_failed_state.json';
import buttonLoadingState from '../../../../assets/animations/button_loading_state.json';


/**
 * List of classes to add to UIKitButton instances based on host attributes to
 * style as different variants.
 */
const BUTTON_HOST_ATTRIBUTES = [
  'uikit-button',
  'inverted',
  'delete',
  'big',
  'small',
  'context',
  'context-no-fill',
  'min-width',
  'max-width',
  'icon-button',
  'menu-button',
  'survey'
];

export type ThemePalette = 'primary' | 'secondary' | 'ghost' | 'white' | 'transparent' | 'error';
/**
 * Insight design button.
 */
@Component({
  selector: ' button[uikit-button]',
  exportAs: 'uiButton',
  templateUrl: 'button.html',
  styleUrls: ['button.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeAnimation', [
      state('in', style({ opacity: 1 })),
      state('out', style({ opacity: 0 })),
      transition('in => out', [animate(200)]),
      transition('out => in', [animate(500)])
    ])
  ]
})
export class UIKitButton implements OnDestroy, AfterViewInit, OnChanges {
  @ViewChild('bodyMovin') bodyMovinContainer: ElementRef;
  private _color: ThemePalette | string;
  @Input() icon: string;
  @Input() loading: boolean;
  @Input() buttonActionSuccess = true;

  @Input() get color(): ThemePalette | string {
    return this._color;
  }
  set color(value: ThemePalette | string) {
    const el = this.elementRef.nativeElement;
    if (!this._isIconButton()) {
      const colorPalette = value || 'primary';
      if (colorPalette !== this._color) {
        if (this._color) {
          el.classList.remove(`uikit-${this._color}`);
        }
        if (colorPalette) {
          el.classList.add(`uikit-${colorPalette}`);
        }

        this._color = colorPalette;
      }
    } else {
      el.style.cssText = 'background-color: ' + value?.valueOf();
    }
  }
  private _disabled = false;

  @HostBinding('attr.disabled')
  get isDisabled() {
    return this.disabled || null;
  }

  @Input() iconPosition: string;
  @Input('disabled')
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
  }

  @HostBinding('attr.selected') private _selected: boolean;

  @Input('selected')
  get selected() {
    return this._selected;
  }
  set selected(value: BooleanInput) {
    this._selected = coerceBooleanProperty(value);
  }

  @Output() animationCompleted = new EventEmitter<boolean>();

  @HostBinding('class.unclickable')
  showAnimationContainer: boolean;
  private buttonAnimation: AnimationItem;

  constructor(
    public elementRef: ElementRef,
    private _focusMonitor: FocusMonitor,
    private cd: ChangeDetectorRef
  ) {
    this.color = this.color;
    // For each of the variant selectors that is prevent in the button's host
    // attributes, add the correct corresponding class.
    for (const attr of BUTTON_HOST_ATTRIBUTES) {
      if (this._hasHostAttributes(attr)) {
        (elementRef.nativeElement as HTMLElement).classList.add(attr);
      }
    }
    this._focusMonitor.monitor(this.elementRef.nativeElement, true);
  }

  ngAfterViewInit(): void {
    const el = this.elementRef.nativeElement as HTMLElement;
    if (this.icon && this.icon.length > 0) {
      el.classList.add('icon');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { loading, iconPosition } = changes;
    const el = this.elementRef.nativeElement as HTMLElement;

    if (iconPosition) {
      if (iconPosition.currentValue === 'right') {
        el.classList.add('icon-right');
      } else {
        el.classList.remove('icon-right');
      }
    }

    if (loading) {
      if (loading.currentValue) {
        if (bodymovin != null && this.bodyMovinContainer != null) {
          this.showAnimationContainer = loading.currentValue;
          this.buttonAnimation = bodymovin.loadAnimation({
            container: this.bodyMovinContainer.nativeElement,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: buttonLoadingState
          });
        }
      } else if (this.buttonAnimation) {
        fromEvent(this.buttonAnimation, 'loopComplete')
          .pipe(
            switchMap(() => {
              if (bodymovin != null) {
                this.buttonAnimation.destroy();
                this.buttonAnimation = bodymovin.loadAnimation({
                  container: this.bodyMovinContainer.nativeElement,
                  renderer: 'svg',
                  loop: false,
                  autoplay: true,
                  animationData: this.buttonActionSuccess ? buttonCompletedState : buttonFailedState
                });
              }
              return fromEvent(this.buttonAnimation, 'complete');
            })
          )
          .subscribe(() => {
            this.buttonAnimation.destroy();
            this.showAnimationContainer = loading.currentValue;
            this.cd.detectChanges();
            this.animationCompleted.emit(true);
          });
        this.buttonAnimation.setSpeed(5);
      } else if (loading.previousValue) {
        this.showAnimationContainer = loading.currentValue;
        this.animationCompleted.emit(true);
      }
    }
  }

  ngOnDestroy() {
    this._focusMonitor.stopMonitoring(this.elementRef.nativeElement);
  }

  /** Focuses the button. */
  focus(): void {
    this._getHostElement().focus();
  }

  private _getHostElement() {
    return this.elementRef.nativeElement;
  }

  /** Gets whether the button has one of the given attributes. */
  private _hasHostAttributes(...attributes: string[]) {
    return attributes.some(attribute =>
      this._getHostElement().hasAttribute(attribute)
    );
  }

  private _isIconButton(): boolean {
    return this._hasHostAttributes('icon-button');
  }
}

@Component({
  selector: 'a[uikit-button], a[uikit-icon-button]',
  exportAs: 'uiButton, uiAnchor',
  templateUrl: 'button.html',
  styleUrls: ['button.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[attr.disabled]': 'disabled || null'
  },
  inputs: ['disabled']
})

export class UIKitAnchor extends UIKitButton {

  constructor(focusMonitor: FocusMonitor, elementRef: ElementRef, cd: ChangeDetectorRef) {
    super(elementRef, focusMonitor, cd);
  }

  @HostListener('click', ['$event'])
  _haltDisabledEvents(event: Event) {
    if (this.disabled) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }
}

