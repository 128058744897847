import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EditQuestionContent } from 'app/shared/models';
import { ConfigurationService } from 'app/shared/services/configuration.service';

@Injectable({
    providedIn: 'root'
})
export class QuestionContentService {
    constructor(private _http: HttpClient, private _config: ConfigurationService) { }


    get(id: number): Observable<EditQuestionContent> {
        return this._http
            .get<EditQuestionContent>(`${this._config.apiUrl}/api/questioncontent/${id}`);
    }

    updateQuestionContent(model: EditQuestionContent): Observable<EditQuestionContent> {
        return this._http
            .put<EditQuestionContent>(`${this._config.apiUrl}/api/questioncontent/${model.questionId}`, model);
    }
}
