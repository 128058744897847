import { Condition, MenuLink, ReportPageMetaDto, UserWithRelatedUsers } from 'app/shared/models';

export interface MenuItem2 {
  route: string[];
  path?: string[];
  name: string;
  translateKey?: string;
  pendoId?: string;
  icon?: string;
  children?: MenuItem2[];
  breadcrumbs?: MenuItem2[];
  key?: string;
}

export interface MenuItem extends MenuLink {
  parentKey?: string;
  children: MenuItem[];
  hasChildren: boolean;
  isChild: boolean;
  hasSameTypeChildren: boolean;
  menuIdentifier: string;
  menuIcon: string;
  groupName: string;
  meta: ReportPageMetaDto[];
  reportPageItems: number[];
  reportPageItemComponents: string[];
  fragment: string;
  surveyToken: string;
  reportGroupSelectorSettings: ReportGroupSelectorSettings;
  parameters: MenuItemParameter[];
  displayParameters: DisplayParameter[];
  translate: boolean;
  hasParameters: boolean;
  reportPageId?: number;
  loading: boolean;
  visible: boolean;
  condition: Condition;
  isAdminMenuItem: boolean;
}

export interface MenuVisibility {
  menuItems: MenuItem[];
  missingMenuItems: MenuItem[];
}

export interface DisplayParameter {
  pageKey: string;
  parameterKey: string;
  context: string;
}
export interface MenuItemParameter {
  key: string;
  label: string;
  value: number;
}

export enum DisplayType {
  Sidebar = 'Sidebar',
  Tab = 'Tab',
  CardSelector = 'CardSelector',
  Custom = 'Custom'
}

export interface ReportPageRoute {
  pageKey: string;
  route: string[];
}
export interface ReportGroupSelectorSettings {
  parameter: string;
  isVisible: boolean;
  priorityHierarchies: string[];
}

export interface SurveyMenuItem {
  answerCount: number;
  endDate: Date;
  participantCount: number;
  hasAnswered: boolean;
  hasEnded: boolean;
  surveyName: string;
  groupName: string;
  productName: string;
  responseRate: number;
  snapshotDate: Date;
  surveyToken: string;
  userResponseDate: Date;
  focusPerson?: string;
  focusPersonWithRelations?: UserWithRelatedUsers;
}
