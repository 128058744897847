import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from 'app/shared';
import {
  ChrossGroupInfo,
  CreateRuleBasedSubGroup,
  CreateSubGroup,
  EditGroup,
  EditHierarchy,
  EventLog,
  Group,
  GroupCategoriesHierarchy,
  GroupCategorySelection,
  GroupImport,
  GroupImportSaveRequestDTO,
  ImportSaveResultDTO,
  GroupImportValidationResult,
  GroupInfo,
  GroupRequiredValues,
  GroupUsersCounts,
  Paged,
  RelationImport,
  RelationImportValidationResult,
  SurveyResponseTree,
  RelationImportSaveRequestDTO,
  EditHierarchyModel,
  GroupCategoriesHierarchySimple,
  FullPathGroup
} from 'app/shared/models';
import { Observable } from 'rxjs';
import {map, shareReplay, take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  constructor(private _http: HttpClient, private _config: ConfigurationService) {
  }
  get(id: number): Observable<Group> {
    return this._http.get<Group>(`${this._config.apiUrl}/api/groups/${id}`);
  }
  getForEdit(id: number): Observable<EditGroup> {
    return this._http.get<EditGroup>(`${this._config.apiUrl}/api/groups/${id}/edit`);
  }
  getByCompanyId(companyId: number): Observable<Group[]> {
    return this._http.get<Group[]>(`${this._config.apiUrl}/api/companies/${companyId}/groups`);
  }
  getPinCountByCompanyId(companyId: number): Observable<number> {
    return this._http.get<number>(`${this._config.apiUrl}/api/companies/${companyId}/pin-count`);
  }
  getByCompanyIdWithParentsAttributes(companyId: number, hierarchyId: number): Observable<Group[]> {
    return this._http.get<Group[]>(`${this._config.apiUrl}/api/companies/${companyId}/groupsWithParentsAttributes/${hierarchyId}`);
  }
  getByCompanyIdPermitted(companyId: number): Observable<Group[]> {
    return this._http.get<Group[]>(`${this._config.apiUrl}/api/companies/${companyId}/groups/permitted`);
  }
  getByCompanyIdActiveAndPermitted(companyId: number): Observable<Group[]> {
    return this._http.get<Group[]>(`${this._config.apiUrl}/api/companies/${companyId}/groups/active-permitted`);
  }
  getGroupChildren(categorySelection: GroupCategorySelection[], companyId: number, category: string, dataSourceName: string)
    : Observable<Group[]> {
    return this._http.post<Group[]>(`${this._config.apiUrl}/api/companies/${companyId}/groups/byParent`,
      {
        category: category,
        dataSourceName: dataSourceName,
        filters: categorySelection
      });
  }
  getByCompanyWithOptions(companyId: number, options: GroupInfo): Observable<Group[]> {
    return this._http.post<Group[]>(`${this._config.apiUrl}/api/companies/${companyId}/groups/options`, options);
  }
  getByCompanyAndCategory(companyId: number, category: string, pageNumber: number, pageSize: number, searchString?: string)
    : Observable<Paged<Group>> {
    return this._http.get<Paged<Group>>(
      `${this._config.apiUrl}/api/companies/${companyId}/groups/category/${category}`, {
      params: {
        pageNumber: pageNumber.toString(),
        pageSize: pageSize.toString(),
        searchString: searchString != null ? searchString : ''
      }
    });
  }

  getRequiredValuesForGroup(groupId: number): Observable<GroupRequiredValues> {
    return this._http
      .get<GroupRequiredValues>(`${this._config.apiUrl}/api/groups/${groupId}/required-values`);
  }

  createTopGroup(companyId: number, subGroup: CreateSubGroup): Observable<HttpResponse<Group>> {
    return this._http
      .post<Group>(`${this._config.apiUrl}/api/groups/${companyId}/top-group`,
        subGroup,
        { observe: 'response', responseType: 'json' });
  }

  createSubGroup(parentGroupId: number, subGroup: CreateSubGroup): Observable<HttpResponse<Group>> {
    return this._http
      .post<Group>(`${this._config.apiUrl}/api/groups/${parentGroupId}/sub-group`,
        subGroup,
        { observe: 'response', responseType: 'json' });
  }

  createRuleBasedSubGroup(parentGroupId: number, model: CreateRuleBasedSubGroup): Observable<HttpResponse<Group>> {
    return this._http
      .post<Group>(`${this._config.apiUrl}/api/groups/${parentGroupId}/rule-based-sub-group`,
        model,
        { observe: 'response', responseType: 'json' });
  }

  moveGroup(
    groupId: number,
    moveGroupInfo: { hierarchyId: number, currentParentId: number, newParentId: number }
  ): Observable<HttpResponse<void>> {
    return this._http
      .put<void>(`${this._config.apiUrl}/api/groups/${groupId}/move-group`,
        moveGroupInfo,
        { observe: 'response', responseType: 'text' as 'json' });
  }

  update(groupId: number, group: EditGroup): Observable<HttpResponse<Group>> {
    return this._http
      .put<Group>(`${this._config.apiUrl}/api/groups/${groupId}`, group,
        { observe: 'response', responseType: 'json' });
  }

  updateRuleBasedGroup(groupId: number, group: EditGroup): Observable<HttpResponse<Group>> {
    return this._http
      .put<Group>(`${this._config.apiUrl}/api/groups/${groupId}/edit`, group,
        { observe: 'response', responseType: 'json' });
  }

  delete(groupId: number): Observable<HttpResponse<object>> {
    return this._http.delete(`${this._config.apiUrl}/api/groups/${groupId}`, { observe: 'response' });
  }

  batchDelete(groupIds: number[]): Observable<HttpResponse<object>> {
    return this._http.delete(`${this._config.apiUrl}/api/groups`, { observe: 'response', body: groupIds });
  }

  hardDelete(groupIds: number[]): Observable<HttpResponse<object>> {
    return this._http.post<HttpResponse<object>>(`${this._config.apiUrl}/api/groups/hard-delete`, groupIds, { observe: 'response' });
  }

  removePinCodeUsers(groupIds: number[]): Observable<HttpResponse<void>> {
    return this._http.post<HttpResponse<void>>(`${this._config.apiUrl}/api/users/pin-code/remove-from-groups`, groupIds);
  }

  getUserCounts(groupIds: number[], pinGroupIds: number[], ignoreExcludedFlag: boolean): Observable<GroupUsersCounts> {
    return this._http.post<GroupUsersCounts>(`${this._config.apiUrl}/api/groups/user-counts`,
      { groupIds: groupIds, pinGroupIds: pinGroupIds, ignoreExcludedFlag: ignoreExcludedFlag });
  }

  getGroupsWithUserCount(companyId: number, groupIds: number[]): Observable<Group[]> {
    let params = new HttpParams()
      .set('companyId', companyId.toString());
    for (const id of groupIds) {
      params = params.append('ids', id.toString());
    }
    return this._http.get<Group[]>(`${this._config.apiUrl}/api/companies/${companyId}/groups/usercount`, { params: params })
      .pipe(take(1), shareReplay(1));
  }

  getByCompanyIdHasResult(companyId: number): Observable<Group[]> {
    return this._http.get<Group[]>(`${this._config.apiUrl}/api/companies/${companyId}/groups/hasResult`);
  }

  getChrossGroups(surveyId: number): Observable<ChrossGroupInfo[]> {
    return this._http.get<ChrossGroupInfo[]>(
      `${this._config.configuration.ChrossApiUrl}/api/DataConverter/GetExternalIds?surveyId=${surveyId}`
    );
  }
  getPinGroupsBySurvey(companyId: number, surveyId: number): Observable<Group[]> {
    return this._http
      .get<Group[]>(`${this._config.apiUrl}/api/companies/${companyId}/surveys/${surveyId}/pin-groups`);
  }
  updateChrossMeta(companyId: number, groupId: number, chrossId: string): Observable<void> {
    return this._http.put<void>(`${this._config.apiUrl}/api/groups/chrossmapping`, {
      companyId, groupId, chrossId
    });
  }
  getHierarchies(companyId: number, excludeInactive = false): Observable<GroupCategoriesHierarchy[]> {
    return this._http.get<GroupCategoriesHierarchy[]>(`${this._config.apiUrl}/api/hierarchies?companyId=${companyId}&excludeInactive=${excludeInactive.toString()}`);
  }
  getHierarchiesSimple(companyId: number, excludeInactive = false): Observable<GroupCategoriesHierarchySimple[]> {
    return this._http.get<GroupCategoriesHierarchySimple[]>(`${this._config.apiUrl}/api/hierarchies/simple?companyId=${companyId}&excludeInactive=${excludeInactive.toString()}`);
  }
  getHierarchyForEdit(hierarchyId: number): Observable<EditHierarchyModel> {
    return this._http.get<EditHierarchyModel>(`${this._config.apiUrl}/api/hierarchies/${hierarchyId}/edit`);
  }
  getHierarchiesForEdit(companyId: number): Observable<EditHierarchy[]> {
    return this._http.get<EditHierarchy[]>(`${this._config.apiUrl}/api/hierarchies/${companyId}/edits`);
  }
  updateHierarchy(id: number, hierarchy: EditHierarchy): Observable<HttpResponse<string>> {
    return this._http
      .put(`${this._config.apiUrl}/api/hierarchies?Id=${id}`, hierarchy, { observe: 'response', responseType: 'text' });
  }
  createHierarchy(companyId: number, hierarchy: GroupCategoriesHierarchy): Observable<number> {
    return this._http.post<number>(`${this._config.apiUrl}/api/hierarchies?companyId=${companyId}`, hierarchy);
  }
  getOrganizationTree(companyId: number, hierarchyId: number, includeParents: boolean): Observable<Group[]> {
    return this._http.get<Group[]>(`${this._config.apiUrl}/api/trees/organization`, {
      params: {
        companyId: companyId.toString(),
        hierarchyId: hierarchyId.toString(),
        includeParents: includeParents.toString()
      }
    });
  }
  getSurveyResponsesTree(companyId: number, hierarchyId: number, surveyId: number): Observable<SurveyResponseTree[]> {
    return this._http.get<SurveyResponseTree[]>(`${this._config.apiUrl}/api/trees/survey-responses`, {
      params: {
        companyId: companyId.toString(),
        hierarchyId: hierarchyId.toString(),
        surveyId: surveyId.toString()
      }
    });
  }
  getByHierarchy(companyId: number, hierarchyId: number): Observable<Group[]> {
    return this._http.get<Group[]>(`${this._config.apiUrl}/api/groups/by-hierarchy`, {
      params: {
        companyId: companyId.toString(),
        hierarchyId: hierarchyId?.toString()
      }
    });
  }
  getFullPathGroupById(groupId: number): Observable<FullPathGroup> {
    return this._http.get<FullPathGroup>(`${this._config.apiUrl}/api/groups/${groupId}/path`);
  }
  validate(companyId: number, model: GroupImport): Observable<GroupImportValidationResult> {
    return this._http.post<GroupImportValidationResult>(`${this._config.apiUrl}/api/companies/${companyId}/groups/validate`, model);
  }
  validateWithRelations(companyId: number, model: RelationImport): Observable<RelationImportValidationResult> {
    return this._http.post<RelationImportValidationResult>(`${this._config.apiUrl}/api/companies/${companyId}/grouprelations/validate`, model);
  }
  save(companyId: number, model: GroupImportSaveRequestDTO): Observable<ImportSaveResultDTO> {
    return this._http.post<ImportSaveResultDTO>(`${this._config.apiUrl}/api/companies/${companyId}/groups/save`, model);
  }
  saveWithRelations(companyId: number, model: RelationImportSaveRequestDTO): Observable<ImportSaveResultDTO> {
    return this._http.post<ImportSaveResultDTO>(`${this._config.apiUrl}/api/companies/${companyId}/grouprelations/save`, model);
  }
  deleteHierarchy(hierarchyId: number): Observable<void> {
    return this._http.delete<void>(`${this._config.apiUrl}/api/hierarchies/${hierarchyId}`);
  }
  clearUserState(groupId: number): Observable<void> {
    return this._http
      .delete<void>(`${this._config.apiUrl}/api/groups/${groupId}/state`);
  }
  getByCompanyIdDeleted(companyId: number): Observable<Group[]> {
    return this._http.get<Group[]>(`${this._config.apiUrl}/api/companies/${companyId}/groups/deleted`);
  }
  getByCompanyIdOrphans(companyId: number): Observable<Group[]> {
    return this._http.get<Group[]>(`${this._config.apiUrl}/api/companies/${companyId}/groups/orphans`);
  }
  resetDeletedGroup(groupId: number): Observable<void> {
    return this._http.put<void>(`${this._config.apiUrl}/api/groups/${groupId}/reset-deleted-group`,
      { observe: 'response', responseType: 'text' as 'json' });
  }
  eventLog(id: number): Observable<EventLog> {
    return this._http.get<EventLog>(`${this._config.apiUrl}/api/groups/${id}/log`);
  }
}
