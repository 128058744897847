import { HttpClient  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigurationService } from 'app/shared/services/configuration.service';
import { BenchmarkFilter, BenchmarkFromChross, BenchmarkTypeAndNames } from '../models/benchmark.model';

@Injectable({
  providedIn: 'root'
})
export class DefaultEntitiesService {
  constructor(private _http: HttpClient, private _config: ConfigurationService) {
  }
  createIndexes(): Observable<void> {
    return this._http.put<void>(`${this._config.apiUrl}/api/defaultentities/indexes`, {});
  }
  createRecommendations(): Observable<void> {
    return this._http.put<void>(`${this._config.apiUrl}/api/defaultentities/recommendations`, {});
  }
  createAttributeDefinition(): Observable<void> {
    return this._http.put<void>(`${this._config.apiUrl}/api/defaultentities/attributeDefinition`, {});
  }
  createMeta(): Observable<void> {
    return this._http.put<void>(`${this._config.apiUrl}/api/defaultentities/meta`, {});
  }
  createGroupCategories(): Observable<void> {
    return this._http.put<void>(`${this._config.apiUrl}/api/defaultentities/groupcategories`, {});
  }
  createCategories(): Observable<void> {
    return this._http.put<void>(`${this._config.apiUrl}/api/defaultentities/categories`, {});
  }

  createEmails(): Observable<void> {
    return this._http.put<void>(`${this._config.apiUrl}/api/defaultentities/emails`, {});
  }
  createProductDefinitions(): Observable<void> {
    return this._http.put<void>(`${this._config.apiUrl}/api/defaultentities/productdefinitions`, {});
  }
  createLibrary(): Observable<void> {
    return this._http.put<void>(`${this._config.apiUrl}/api/defaultentities/library`, {});
  }
  createQuestionFields(): Observable<void> {
    return this._http.put<void>(`${this._config.apiUrl}/api/defaultentities/questionfields`, {});
  }
  listBenchmarkVariables(): Observable<BenchmarkTypeAndNames[]> {
    return this._http.get<BenchmarkTypeAndNames[]>(`${this._config.configuration.ChrossApiUrl}/api/Benchmark/Variables`);
  }
  listBenchmarks(filter: BenchmarkFilter): Observable<BenchmarkFromChross[]> {
    return this._http.get<BenchmarkFromChross[]>(`${this._config.configuration.ChrossApiUrl}/api/Benchmark/List`, { params: { ...filter } });
  }
  createBenchmarks(filter?: BenchmarkFilter): Observable<number> {
    return this._http.put<number>(`${this._config.apiUrl}/api/defaultentities/benchmarks`, filter);
  }
  createDataModels(): Observable<void> {
    return this._http.put<void>(`${this._config.apiUrl}/api/defaultentities/datamodels`, {});
  }
  createQuestions(questionsJson: unknown): Observable<void> {
    return this._http.post<void>(`${this._config.apiUrl}/api/defaultentities/questions`, questionsJson);
  }
  createVariables(): Observable<void> {
    return this._http.put<void>(`${this._config.apiUrl}/api/defaultentities/variables`, {});
  }
  createSms(): Observable<void> {
    return this._http.put<void>(`${this._config.apiUrl}/api/defaultentities/sms`, {});
  }
  createModuleCategories(): Observable<void> {
    return this._http.put<void>(`${this._config.apiUrl}/api/defaultentities/modulecategories`, {});
  }

}

