import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResultPreview } from 'app/library/library-questionnaire/library-fields/edit-field/edit-field.component';
import {
  AvailableProducts,
  EditQuestion,
  EditQuestionContent,
  LibraryEditQuestion,
  ModulePreviewResult,
  QuestionCategory,
  QuestionDataExportModel,
  QuestionEditField,
  QuestionFieldForEdit,
  QuestionFieldListData,
  QuestionFieldPreview,
  QuestionFieldPreviewResult,
  QuestionFieldRequest,
  QuestionFieldResponse,
  QuestionHelpText,
  QuestionList,
  QuestionListData,
  UpdateQuestionFieldRequest,
} from 'app/shared/models';
import { ConfigurationService } from 'app/shared/services/configuration.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {
  constructor(private _http: HttpClient, private _config: ConfigurationService) {
  }
  get(id: number): Observable<EditQuestion> {
    return this._http.get<EditQuestion>(`${this._config.apiUrl}/api/questions/${id}/edit`);
  }

  listByCoreAndCompany(companyId: number): Observable<QuestionList[]> {
    return this._http.get<QuestionList[]>(`${this._config.apiUrl}/api/questions/${companyId}`);
  }
  getQuestionHelpText(companyId: number): Observable<QuestionHelpText[]> {
    return this._http.get<QuestionHelpText[]>(`${this._config.apiUrl}/api/questions/companies/${companyId}/questionHelpText`);
  }
  getByCompany(companyId: number): Observable<QuestionList[]> {
    return this._http.get<QuestionList[]>(`${this._config.apiUrl}/api/companies/${companyId}/questions`);
  }
  getQuestionsByCompany(): Observable<QuestionListData> {
    return this._http.get<QuestionListData>(`${this._config.apiUrl}/api/questions/getByCompany`);
  }
  getQuestionsForExport(companyId: number, questionIds: number[] = null): Observable<QuestionDataExportModel> {
    const body = {
      companyId,
      questionIds
    };
    return this._http.post<QuestionDataExportModel>(`${this._config.apiUrl}/api/companies/questions/export`, body);
  }
  getByModule(companyId: number, moduleId: number) {
    return this._http.get<QuestionList[]>(`${this._config.apiUrl}/api/companies/${companyId}/modules/${moduleId}/questions`);
  }
  getQuestionFieldsOld(companyId: number): Observable<QuestionEditField[]> {
    return this._http.get<QuestionEditField[]>(`${this._config.apiUrl}/api/questions/questionFields`, {
      params: {
        companyId: companyId.toString()
      }
    });
  }
  getQuestionFieldsByProduct(companyId: number, product: AvailableProducts): Observable<QuestionEditField[]> {
    return this._http.get<QuestionEditField[]>(`${this._config.apiUrl}/api/questions/questionFieldsByProduct`, {
      params: {
        companyId: companyId.toString(),
        product: product,
        exceptAdmin: true
      }
    });
  }
  create(questions: EditQuestion): Observable<EditQuestion> {
    return this._http.post<EditQuestion>(`${this._config.apiUrl}/api/questions`, questions);
  }
  updateADMIN(questionId: number, questions: EditQuestion): Observable<void> {
    return this._http.put<void>(`${this._config.apiUrl}/api/questions/${questionId}/update`, questions);
  }
  copy(questionId: number, toCompanyId: number): Observable<EditQuestion> {
    return this._http.post<EditQuestion>(`${this._config.apiUrl}/api/questions/${questionId}/copy/${toCompanyId}`, null);
  }
  copyWithNewContent(questionId: number, toCompanyId: number, model: EditQuestionContent): Observable<EditQuestionContent> {
    return this._http.post<EditQuestionContent>(`${this._config.apiUrl}/api/questions/${questionId}/copy-with-new-content/${toCompanyId}`, model);
  }
  getQuestionCategories(): Observable<QuestionCategory[]> {
    return this._http.get<QuestionCategory[]>(`${this._config.apiUrl}/api/questions/categories`);
  }
  getQuestionCategoriesByProduct(product: AvailableProducts): Observable<QuestionCategory[]> {
    return this._http.get<QuestionCategory[]>(`${this._config.apiUrl}/api/questions/categoriesByProduct`, {
      params: {
        product: product
      }
    });
  }
  getByCompanyFiltered(companyId: number): Observable<QuestionList[]> {
    return this._http.get<QuestionList[]>(`${this._config.apiUrl}/api/companies/${companyId}/questions/filtered`);
  }
  setSelectableFlag(id: number, selectable: boolean, companyId: number): Observable<HttpResponse<Object>> {
    return this._http
      .put(`${this._config.apiUrl}/api/questions/${id}/selectable`, { selectable: selectable, companyId: companyId },
        { observe: 'response', responseType: 'text' });
  }
  setEnabledFlag(id: number, enabled: boolean, companyId: number): Observable<HttpResponse<Object>> {
    return this._http
      .put(`${this._config.apiUrl}/api/questions/${id}/enabled`, { enabled: enabled, companyId: companyId },
        { observe: 'response', responseType: 'text' });
  }

  /* API v2 */

  getForEdit(id: number): Observable<LibraryEditQuestion> {
    return this._http.get<LibraryEditQuestion>(`${this._config.apiUrl}/api/question/${id}/edit`);
  }

  upsert(question: LibraryEditQuestion): Observable<{ id: number } | void> {
    return question.id ? this.update(question) : this.insert(question);
  }

  update(question: LibraryEditQuestion): Observable<void> {
    return this._http.put<void>(`${this._config.apiUrl}/api/question/${question.id}`, question);
  }

  insert(question: LibraryEditQuestion): Observable<{ id: number }> {
    return this._http.post<{ id: number }>(`${this._config.apiUrl}/api/question/`, question);
  }

  delete(id: number): Observable<void> {
    return this._http.delete<void>(`${this._config.apiUrl}/api/question/${id}`);
  }

  getQuestionFields(): Observable<QuestionFieldListData> {
    return this._http.get<QuestionFieldListData>(`${this._config.apiUrl}/api/questionfields`);
  }

  getQuestionField(fieldId: number): Observable<QuestionFieldForEdit> {
    return this._http.get<QuestionFieldForEdit>(`${this._config.apiUrl}/api/questionfields/${fieldId}`)
  }

  createQuestionFields(request: QuestionFieldRequest): Observable<QuestionFieldResponse> {
    return this._http.post<QuestionFieldResponse>(`${this._config.apiUrl}/api/questionfields`, request);
  }

  updateQuestionFields(request: UpdateQuestionFieldRequest): Observable<QuestionFieldResponse> {
    return this._http.put<QuestionFieldResponse>(`${this._config.apiUrl}/api/questionfields/${request.id}`, request);
  }

  previewQuestionField(model: QuestionFieldPreview): Observable<ModulePreviewResult> {
    return this._http
      .put<ModulePreviewResult>(`${this._config.apiUrl}/api/questionfields/${model.questionFieldId}/preview`, model);
  }
  previewQuestionFieldResult(model: QuestionFieldPreviewResult): Observable<ResultPreview> {
    return this._http
      .put<ResultPreview>(`${this._config.apiUrl}/api/questionfields/${model.questionFieldId}/result-preview`, model);
  }

}
