import { EditContentItem, Condition } from 'app/shared/models';
export interface EditContent {
  id?: number;
  companyId: number;
  contentItems: EditContentItem[];
  key?: string;
  questionContentType?: QuestionContentType;
  contentTypeId?: number;
  renderOptions?: string;
  libraryId?: number;
  condition?: Condition;
}

export enum AvailableProducts {
  EX = 1 << 0,
  CX = 1 << 1
}

export interface ExportableEntities {
  emails: ExportableEntity[];
  sms: ExportableEntity[];
  modules: ExportableEntity[];
  indexes: ExportableEntity[];
  recommendations: ExportableEntity[];
  categories: ExportableEntity[];
  moduleCategories: ExportableEntity[];
  attributeDefinitions: ExportableEntity[];
}

export interface ExportableEntity {
  id: number;
  key: string;
  name: string;
  note?: string;
}


export interface ExportContentSettings {
  emails: number[];
  sms: number[];
  modules: number[];
  indexes: number[];
  recommendations: number[];
  categories: number[];
  moduleCategories: number[];
  attributeDefinitions: number[];
}

export interface ContentExport {
  id: string;
  text: string;
  note: string;
}

export interface ImportContentModel {
  companyId: number;
  data: ImportContent[];
}
export interface ImportContent {
  language: string;
  content: ExportableEntity[];
}

export interface ImportContentStatistics {
  id: number;
  created: Date;
  totalContentCount: number;
  totalItemCount: number;
  updatedContentCount: number;
  updatedItemCount: number;
  languageCount: number;
}
export enum QuestionContentType
{
  QuestionText= 'QuestionText',
  QuestionHelpText = 'QuestionHelpText'
}


