import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateProductDefinition, ProductDefinition, ProductDefinitionJson, UpdateProductdefinition, ProductDefinitionJsonStep } from 'app/shared/models';
import { ConfigurationService } from 'app/shared/services/configuration.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductDefinitionService {
  constructor(private _http: HttpClient, private _config: ConfigurationService) {
  }
  getForEdit(id: number): Observable<UpdateProductdefinition> {
    return this._http
      .get<UpdateProductdefinition>(`${this._config.apiUrl}/api/productdefinitions/${id}/edit`);
  }
  list(isActive?: boolean): Observable<ProductDefinition[]> {
    return this._http
      .get<ProductDefinition[]>(`${this._config.apiUrl}/api/productdefinitions/list/${isActive}`);
  }
  update(definitionId: number, definition: UpdateProductdefinition): Observable<number> {
    return this._http.put<number>(`${this._config.apiUrl}/api/productdefinitions/update/${definitionId}`, definition);
  }

  create(definition: CreateProductDefinition): Observable<ProductDefinition> {
    return this._http.post<ProductDefinition>(`${this._config.apiUrl}/api/productdefinitions`, definition);
  }
  delete(definitionId: number): Observable<void> {
    return this._http.delete<void>(`${this._config.apiUrl}/api/productdefinitions/delete/${definitionId}`);
  }
  findStep(definition: ProductDefinitionJson, stepId: string) {
    if (!definition.steps) { return null; }
    const step = definition.steps.find(s => s.stepId === stepId);
    return step;
  }
  getSetting<T extends ProductDefinitionJson>(
    definition: T,
    setting: string,
    defaultValue: T[keyof T] = null
  ): T[keyof T] {
    for (const path of setting.split(',')) {
      let currSetting: T | ProductDefinitionJsonStep = definition;
      const pathArray = path.split('.');
      for (let i = 0; i < pathArray.length; i++) {
        const s = pathArray[i];
        if (currSetting[s]) {
          if (i === (pathArray.length - 1)) {
            return currSetting[s];
          }
          currSetting = currSetting[s];
        } else if (s.startsWith('step:')) {
          // TODO: currSetting really is 'any' as in ProductDefinitionJson or any part of it
          currSetting = this.findStep(definition, s.substring(5));
        } else {
          return defaultValue;
        }
      }
    }
    return defaultValue;
  }

  getDataSource(settings: Record<string, unknown>): string {
    return 'dataSource' in settings ? (settings.dataSource as string).trim() : null;
  }
}

