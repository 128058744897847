import { FollowupAction } from '..';
import { GroupResultInfo } from '.';
import { Recommendation } from '../product-wizard.model';


export interface ActionPlan {
  title: string;
  productId: number;
  type: string;
  status: ProductStatus;
  actions: FollowupAction[];
  nows: string[];
  goals: string[];
  recommendations: Recommendation[];
  groupResultInfo: GroupResultInfo;
  groupId: number;
  createTime: Date;
  startDate: Date;
  endDate: Date;

}

export enum ProductStatus {
  archived = 'Archived',
  draft = 'Draft',
  published = 'Published',
  deleted = 'Deleted',
  nonexisting = 'NonExisting'
}

