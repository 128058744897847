<section>

  <div class="content">
    <div class="logo" style="width:100px; height: 100px;">
      <img class="logo" src="assets/images/Brilliant_Symbol_White_RGB.png">
    </div>

    <div class="text">
      <h1>{{'INSIGHT.GLOBAL.HI' | transloco}}</h1>
      <p>{{'INSIGHT.GLOBAL.ENTERPIN' | transloco}}</p>
    </div>

    <form (ngSubmit)="pinCodeLogon()" autocomplete="off" class="d-flex flex-column login-form">
      <div class="wrapper">
        <input [placeholder]="'INSIGHT.ADMIN.PINCODE' | transloco" name="pinCode" maxlength="6"
          [formControl]="pinCodeFormControl">
      </div>
      <uikit-error *ngIf="pinCodeFormControl.hasError('invalid')" class="pin-error">
        {{'INSIGHT.GLOBAL.INVALIDPIN' | transloco}}</uikit-error>
      <uikit-error *ngIf="pinCodeFormControl.hasError('expired')" class="pin-error">
        {{'INSIGHT.GLOBAL.EXPIREDSESSION' | transloco}}</uikit-error>
      <button id="UITestLinkToPinCodeButton" uikit-button inverted min-width type="submit"
        [disabled]="!pinCodeFormControl.value || submitting" [loading]="submitting"
        [buttonActionSuccess]="!errorLoggingIn">
        {{'INSIGHT.GLOBAL.STARTSURVEY' | transloco }}</button>
    </form>

  </div>

  <div class="powered">
    <span class="powered-by">Powered by</span>
    <uikit-icon block="true" icon="insight-logo"></uikit-icon>
  </div>

</section>
