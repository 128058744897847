import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ActionArea,
  ConditionRecommendation,
  EditProduct,
  EditProductDraft,
  FollowupActionAdd,
  FollowupActionEdit,
  Product,
  ProductDraft,
  Recommendation,
  RecommendationCategory
} from 'app/shared/models';
import { ConfigurationService } from 'app/shared/services/configuration.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  constructor(private _http: HttpClient, private _config: ConfigurationService) { }

  create(companyId: number, companyProductDefinitionId: number, draft: string, groupResultId?: number, groupId?: number): Observable<Product> {
    const model = {
      companyId: companyId,
      companyProductDefinitionId: companyProductDefinitionId,
      groupResultId: groupResultId,
      groupId: groupId,
      draft: draft
    };
    return this._http.post<Product>(`${this._config.apiUrl}/api/products`, model);
  }

  update(productId: number, model: EditProduct): Observable<boolean> {
    return this._http.put<boolean>(`${this._config.apiUrl}/api/products/${productId}`, model);
  }

  updateDraft(productId: number, draft: EditProductDraft): Observable<HttpResponse<string>> {
    return this._http.put(`${this._config.apiUrl}/api/products/updatedraft/${productId}/`, draft, { observe: 'response', responseType: 'text' });
  }

  getById(productId: number): Observable<Product> {
    return this._http.get<Product>(`${this._config.apiUrl}/api/products/${productId}`);
  }

  getVersion(productId: number): Observable<Product> {
    return this._http.get<Product>(`${this._config.apiUrl}/api/products/${productId}/version`);
  }

  getDraftProduct(companyProductDefinitionId: number, groupResultId: number): Observable<Product> {
    return this._http.get<Product>(`${this._config.apiUrl}/api/products/${companyProductDefinitionId}/draft/${groupResultId}`);
  }

  getDraftListByCompany(companyId: number): Observable<ProductDraft[]> {
    return this._http
      .get<ProductDraft[]>(`${this._config.apiUrl}/api/products/${companyId}/listdrafts`);
  }

  delete(productId: number): Observable<boolean> {
    return this._http.delete<boolean>(`${this._config.apiUrl}/api/products/${productId}`);
  }

  createFollowup(companyId: number, followup: any): Observable<any> {
    return this._http
      .post<any>(`${this._config.apiUrl}/api/products/${companyId}/followup`, followup);
  }

  getActionAreas(): Observable<ActionArea[]> {
    return this._http.get<ActionArea[]>(`${this._config.apiUrl}/api/actions/areas`);
  }
  getAllRecommendations(): Observable<Recommendation[]> {
    return this._http.get<Recommendation[]>(`${this._config.apiUrl}/api/recommendations/all`);
  }

  getAllRecommendationsForConditions(): Observable<ConditionRecommendation[]> {
    return this._http.get<ConditionRecommendation[]>(`${this._config.apiUrl}/api/recommendations/all/condition`);
  }

  addProductAction(action: FollowupActionAdd): Observable<number> {
    return this._http.post<any>(`${this._config.apiUrl}/api/actions`, action);
  }

  updateProductAction(actionId: number, action: FollowupActionEdit) {
    return this._http.put<void>(`${this._config.apiUrl}/api/actions/${actionId}/`, action);
  }

  deleteAction(actionId: number): Observable<boolean> {
    return this._http.delete<boolean>(`${this._config.apiUrl}/api/actions/${actionId}`);
  }

  getAllCategories(): Observable<RecommendationCategory[]> {
    return this._http.get<RecommendationCategory[]>(`${this._config.apiUrl}/api/recommendations/categories`);
  }
}
