import { GroupSessionCreate, UserAttribute } from 'app/shared/models';

export enum UserType {
    Standard = 'standard',
    Pincode = 'pincode'
}

export enum UserStatus {
    Deleted = -1,
    Deactivated = 0,
    Active = 1
}

export interface CreateUser {
    id: number;
    companyId: number;
    hierarchyId: number;
    groupId: number;
    externalId?: string;
    phoneNumber?: string;
    email?: string;
    language?: string;
    languageId?: number;
    firstName?: string;
    lastName?: string;
    userAttributes: UserAttribute[];
    status: UserStatus;
    userType: UserType;
    manuallyEdited?: boolean;
    excludedFromSurveys?: boolean;
    sessionsToCreate?: GroupSessionCreate[];
    sessionsToRemove?: number[];
}

export interface AnonymousUserSettings {
    count: number;
    addToSurvey: boolean;
}

export interface CreateAnonymousUser {
    companyId: number;
    groupId: number;
    hierarchyId: number;
    count: number;
}

export interface MoveUserDto {
    companyId: number;
    hierarchyId: number;
    newGroupId: number;
    userIds: number[];
}

export interface DeleteUserDto {
    companyId: number;
    hierarchyId: number;
    userIds: number[];
}

export interface CreateUserConnection {
    id: number;
    userId: number;
    relationId: number;
    targetUserId: number;
}