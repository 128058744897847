import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Company, CompanyLogo, CompanyWithAccount, EditCompany } from 'app/shared/models';
import { ConfigurationService } from 'app/shared/services/configuration.service';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private _http: HttpClient, private _config: ConfigurationService) {
  }

  get(id: number | string): Observable<Company> {
    return this._http
      .get<Company>(`${this._config.apiUrl}/api/companies/${id}`, { headers: { 'init': 'true' } });
  }

  getByToken(id: number, token: string): Observable<Company> {
    return this._http
      .get<Company>(`${this._config.apiUrl}/api/companies/${id}/byToken/${token}`);
  }

  getLogo(id: number): Observable<CompanyLogo> {
    return this._http
      .get<CompanyLogo>(`${this._config.apiUrl}/api/companies/${id}/logo`);
  }

  uploadLogo(companyId: number, file: File): Observable<void> {
    const formData = new FormData();
    formData.append('files', file, file.name);
    return this._http.post<void>(`${this._config.apiUrl}/api/companies/${companyId}/logo`, formData);
  }

  removeLogo(companyId: number): Observable<void> {
    return this._http.delete<void>(`${this._config.apiUrl}/api/companies/${companyId}/logo`);
  }

  list(): Observable<Company[]> {
    return this._http
      .get<Company[]>(`${this._config.apiUrl}/api/companies`);
  }

  getForEdit(id: number): Observable<EditCompany> {
    return this._http
      .get<EditCompany>(`${this._config.apiUrl}/api/companies/${id}/edit`);
  }

  update(company: Company): Observable<Company> {
    return this._http
      .put<Company>(`${this._config.apiUrl}/api/companies/${company.id}`, company);
  }

  create(company: Company): Observable<Company> {
    return this._http
      .post<Company>(`${this._config.apiUrl}/api/companies`, company);
  }

  delete(company: Company): Observable<void> {
    return this._http
      .delete<void>(`${this._config.apiUrl}/api/companies/${company.id}`);
  }

  listWithoutAccount(): Observable<CompanyWithAccount[]> {
    return this._http
      .get<{ companies: CompanyWithAccount[] }>(`${this._config.apiUrl}/api/companies/with-account`).pipe(map(r => r.companies));
  }
}
