import { Directive, Input, OnChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/state/app.state';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';
import { NeedsToUnsubscribe } from './needs-to-unsubscribe';

@Directive({ selector: '[hasPermission]' })
export class HasPermissionDirective extends NeedsToUnsubscribe implements OnChanges {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private store: Store<AppState>) { super(); }

  @Input() hasPermission: string;
  @Input() hasPermissionGroup?: number;
  @Input() hasPermissionToAll?: number[];
  @Input() hasPermissionToAny?: number[];
  @Input() hasPermissionElse?: TemplateRef<unknown>;

  ngOnChanges(): void {
    this.store.select(state => state.companyContext).pipe(
      takeUntil(this.unsubscribe$),
      withLatestFrom(this.store.select(state => state.userInfo)),
      filter(([companyContext, userInfo]) => companyContext.id !== null && userInfo !== null)
    ).subscribe(([companyContext, userInfo]) => {
      const permissions = this.hasPermission?.split(';');
      let renderElement = false;
      if (permissions == null) {
        renderElement = true;
      } else {
        renderElement = permissions.some(permission => {
          const canForGroup = (groupId: number) => userInfo.userPermission.can(permission.trim(), companyContext.id, groupId);

          if (this.hasPermissionToAll?.length > 0) {
            return this.hasPermissionToAll.every(groupId => canForGroup(groupId));
          }
          else if (this.hasPermissionToAny?.length > 0) {
            return this.hasPermissionToAny.some(groupId => canForGroup(groupId));
          }
          else if (this.hasPermission == null) {
            return true;
          }
          else if (canForGroup(this.hasPermissionGroup)) {
            return true;
          }
          return false;
        });
      }

      if (this.viewContainer?.length) {
        this.viewContainer.clear();
      }
      if (renderElement) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else if(this.hasPermissionElse != null) {
        this.viewContainer.createEmbeddedView(this.hasPermissionElse);
      }
    });
  }
}
