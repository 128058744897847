import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { default as config } from '../../../appsettings.json';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class ApplicationInsightService {
  appInsights: ApplicationInsights;
  constructor(private router: Router) {
    const angularPlugin = new AngularPlugin();
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: config.ApplicationInsights.InstrumentationKey,
        samplingPercentage: 50,
        extensions: [angularPlugin],
        extensionConfig: {
          [angularPlugin.identifier]: { router: this.router }
        }
      }
    });
    this.appInsights.loadAppInsights();
  }

  trackPageView() {

    this.appInsights.trackPageView();
  }

  logError(error: Error) {
    this.appInsights.trackException({ exception: error });
  }
}
