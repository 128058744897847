import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

import { ConfigurationService } from 'app/shared/services/configuration.service';
import { InternalAdmin, PermissionName } from 'app/shared/models';
@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  private _permissions: Observable<PermissionName[]>;
  constructor(private _http: HttpClient, private _config: ConfigurationService) {
  }
  list(companyId: number): Observable<PermissionName[]> {
    if (!this._permissions) {
      this._permissions = this._http
        .get<PermissionName[]>(`${this._config.apiUrl}/api/permissions/${companyId}`).pipe(
          publishReplay(1),
          refCount(),);
    }
    return this._permissions;
  }

  getInternalAdmins(): Observable<InternalAdmin[]> {
    return this._http
      .get<InternalAdmin[]>(`${this._config.apiUrl}/api/permissions/internal-admins`);
  }
  addInternalAdmin(internalAdmin: InternalAdmin): Observable<void> {
    return this._http
      .post<void>(`${this._config.apiUrl}/api/permissions/internal-admins`, internalAdmin);
  }
  removeInternalAdmin(internalAdmin: InternalAdmin): Observable<void> {
    return this._http
      .delete<void>(`${this._config.apiUrl}/api/permissions/internal-admins`, {
        params: {
          userId: internalAdmin.userId,
          roleId: internalAdmin.roleId
        }
      });
  }
}
