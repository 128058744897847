import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserRelation,
EditUserRelation,
UserRelationType,
UserRelationImport,
UserRelationImportValidationResult,
UserRelationValidationSummary,
ValidatedUserRelation,
} from 'app/shared/models';
import { Observable } from 'rxjs';

import { ConfigurationService } from 'app/shared';

@Injectable({
    providedIn: 'root'
})
export class UserRelationService {

    constructor(private _http: HttpClient, private _config: ConfigurationService) {
    }
    getByCompany(companyId: number): Observable<UserRelation[]> {
        return this._http.get<UserRelation[]>(`${this._config.apiUrl}/api/companies/${companyId}/userrelations`);
    }
    create(model: EditUserRelation): Observable<number> {
        return this._http.post<number>(`${this._config.apiUrl}/api/userrelations`, model);
    }
    update(model: EditUserRelation): Observable<void> {
        return this._http.put<void>(`${this._config.apiUrl}/api/userrelations/${model.id}`,
            model);
    }
    save(companyId: number,
        model: { mappingId: number, validatedUserRelations: ValidatedUserRelation[]; userConnectionIdsToRemove: number[]; fileName: string; importSummary: UserRelationValidationSummary }
      ): Observable<unknown> {
        return this._http.post<unknown>(`${this._config.apiUrl}/api/companies/${companyId}/userrelations/save`, model);
      }
    delete(id: number): Observable<void> {
        return this._http.delete<void>(`${this._config.apiUrl}/api/userrelations/${id}`);
    }
    getUserRelationTypes(): Observable<ReadonlyArray<UserRelationType>> {
      return this._http.get<ReadonlyArray<UserRelationType>>(`${this._config.apiUrl}/api/userrelations/relationTypes`);
    }
    validateImportUserRelations(companyId: number, model: UserRelationImport): Observable<UserRelationImportValidationResult> {
        return this._http.post<UserRelationImportValidationResult>(`${this._config.apiUrl}/api/companies/${companyId}/userrelations/validate`, model);
      }
}
