import { CompanyType } from '../company';

export interface UserInfo {
  languageCode: string;
  languageId: number;
  rtl: boolean;
  companyId: number;
  companyName: string;
  status: number;
  userId: number;
  firstName: string;
  lastName: string;
  userName: string;
  userPermission: UserPermission;
  displayLanguageCode: string;
  displayLanguageId: number;
  email?: string;
  availableAccounts: AccountContext[];
}

export interface AccountContext {
  name: string;
  companies: CompanySwitch[];
}

export interface CompanySwitch {
  name: string;
  label: string;
  id: number;
  type: CompanyType;
  userId?: number;
  active?: boolean;
}


export interface UserPermission {
  isAdmin: boolean;
  companyId: number;
  groupPermissions: GroupPermission[];
  can(this: UserPermission, permission: string, companyId: number, groupId?: number): boolean;
}

export function can(this: UserPermission, permission: string, companyId: number, groupId?: number): boolean {
  function checkPermission(p: string[]): boolean {
    return p.some(x => x.startsWith(permission));
  }
  if (this.isAdmin) { return true; }
  if (this.companyId !== companyId) { return false; }
  if (groupId) {
    return this.groupPermissions
      .filter(x => groupId < 0 || x.groupId === groupId)
      .some(gp => checkPermission(gp.permissions));
  }
  return false;
}
export interface GroupPermission {
  groupId: number;
  groupName: string;
  relation: string;
  permissions: string[];
  hasChildren?: boolean;
  children?: GroupPermission[];

}

export enum Action {
  Create = 'Create',
  Read = 'Read',
  Update = 'Update',
  Delete = 'Delete'
}
