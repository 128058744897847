import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from 'app/shared/services/configuration.service';
import { Observable } from 'rxjs';
import { Variable, EditVariable } from '../models';

@Injectable({
    providedIn: 'root'
})
export class VariableService {
    constructor(private _http: HttpClient, private _config: ConfigurationService) {
    }

    getByCompany(companyId: number): Observable<Variable[]> {
        return this._http.get<Variable[]>(`${this._config.apiUrl}/api/companies/${companyId}/variables`);
    }

    getForBranding(companyId: number): Observable<Variable[]> {
        return this._http.get<Variable[]>(`${this._config.apiUrl}/api/companies/${companyId}/branding/variables`);
    }

    updateCompanyVariable(companyId: number, mappedAttributes: EditVariable[]): Observable<any> {
        return this._http.put(`${this._config.apiUrl}/api/variables/companies/${companyId}`, mappedAttributes);
    }

    getVariableDefinitions(): Observable<Variable[]> {
        return this._http.get<Variable[]>(`${this._config.apiUrl}/api/variables`);
    }
}
