import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ConfigurationService } from './configuration.service';
import { shareReplay } from 'rxjs/operators';
import { Index, IndexQuestions, IndexVerbose } from '../models';

@Injectable({
  providedIn: 'root'
})
export class CompanyIndexService {
  public responseCache = new Map();
  constructor(private _http: HttpClient, private _config: ConfigurationService) { }

  public list(companyId: number): Observable<Index[]> {
    return this._http.get<Index[]>(`${this._config.apiUrl}/api/companyindexes/companies/${companyId}`);
  }
  
  public indexKeysWithQuestions(): Observable<IndexQuestions[]> {
    return this._http.get<IndexQuestions[]>(`${this._config.apiUrl}/api/companyindexes/indexKeysWithQuestions`);
  }
  
  public listVerbose(companyId: number): Observable<IndexVerbose[]> {
    const url = `${this._config.apiUrl}/api/companyindexes/companies/${companyId}/verbose`;
    const indexesFromCache = this.responseCache.get(url);
    if (indexesFromCache) {
      return of(indexesFromCache);
    }
    const response = this._http.get<IndexVerbose[]>(url).pipe(
      shareReplay(1),
    );
    response.subscribe(indices => this.responseCache.set(url, indices));
    return response;
  }

  public update(companyId: number, indexIds: number[]): Observable<HttpResponse<any>> {
    return this._http.put<any>(`${this._config.apiUrl}/api/companyindexes/companies/${companyId}`,
      indexIds);
  }

  public setCompanyIndex(companyId: number, indexId: number, set: boolean): Observable<boolean> {
    return this._http.put<boolean>(`${this._config.apiUrl}/api/companyindexes/companies/${companyId}/index/${indexId}`, set);
  }
}
