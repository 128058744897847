export const DEFAULT_COLOR = '#CCCCCC';

export const COLORS = {
  'nps-promoters': '#16AA4E',
  'nps-passive': '#C4C4C4',
  'nps-detractors': '#FB091F',
  '3grade-high': '#00A139',
  '3grade-medium': '#FFD800',
  '3grade-low': '#F42737',
  '5grade-highest': '#00A139',
  '5grade-high': '#A0DAB3',
  '5grade-medium': '#FFD800',
  '5grade-low': '#FF6A13',
  '5grade-lowest': '#F42737',
  'warning': '#F42737',

  'tonality-positive': '#A0DAB3',
  'tonality-neutral': '#C4C4C4',
  'tonality-negative': '#FF6A13',

  // TODO: Remove
  'ei-index-bar': '#8FE5A1',
  'ei-darkgreen': '#16AA4E',
  'ei-green': '#8FE5A1',
  'ei-blue': '#1F4AE9',
  'ei-yellow': '#FFD800',
  'ei-red': '#FB091F',
  'scaled-index-darkgreen': '#16AA4E',
  'scaled-index-green': '#8FE5A1',
  'scaled-index-yellow': '#FFD800',
  'scaled-index-red': '#FB091F',
  'transparent': 'rgba(0, 0, 0, 0)'
};


export function getColor(c: string): string {
  if (!c) { return DEFAULT_COLOR; }
  if (c.startsWith('#')) { return c; }
  return (COLORS[c] ?? DEFAULT_COLOR) as string;
}

export enum ColorLevel {
  Darkest = 0,
  Dark,
  Medium,
  Light,
  Ligthest
}

export enum GraphColor {
  Blue = 'blue',
  Orange = 'orange',
  Purple = 'purple',
  Green = 'green',
  Yellow = 'yellow',
  Red = 'red'
}

const BLUE = {
  name: 'blue',
  values: ['#65c1f8', '#459FD3', '#296F98', '#094A71', '#002e48']
};
const ORANGE = {
  name: 'orange',
  values: ['#ffbc58', '#f0a444', '#d9752a', '#be531a', '#993a1c']
};
const PURPLE = {
  name: 'purple',
  values: ['#ff81d5', '#d45ead', '#b64590', '#742576', '#560857']
};
const GREEN = {
  name: 'green',
  values: ['#54d7bf', '#3aaf8c', '#298f65', '#005b40', '#073522']
};
const YELLOW = {
  name: 'yellow',
  values: ['#ffd855', '#edc22b', '#dcac02', '#c29800', '#a98400']
};
const RED = {
  name: 'red',
  values: ['#ff6770', '#d9454d', '#c32e37', '#950e21', '#740011']
};

export class GraphColors {
  static colors = [
    BLUE, ORANGE, PURPLE, GREEN, YELLOW, RED
  ];

  static getColorOfSet(index: number, level?: number, numberOfSets?: number) {

    if (numberOfSets > Object.keys(ColorLevel).length / 2) {
      return GraphColors.getColorStatic(level, index);
    }
    return GraphColors.getColorStatic(index, level);
  }

  static getColorStatic(param: string | number, level: undefined | ColorLevel = 0): string {
    if (typeof param === 'string') {
      const item = this.colors.find(c => c.name === param);
      return item ? item.values[+level] : DEFAULT_COLOR;
    } else {
      const levelLength = (Object.keys(ColorLevel).length / 2);
      const item = this.colors[param % this.colors.length];
      if (!!level) {
        return item ? item.values[+level % levelLength] : DEFAULT_COLOR;
      }
      return item ? item.values[Math.floor(param / levelLength % (levelLength))] : DEFAULT_COLOR;
    }
  }
}
