export interface EventLog {
  id?: number;
  event: string;
  actor: string;
  occurredOn: Date;
  data: string;
}

export interface CreateEventLog {
  data: string;
  type: EventLogCompanySettingType;
}

export interface UpdateEventLog {
  id: number;
  data: string;
}

export enum EventLogCompanySettingType {
  RoleComment = 'RoleComment'
}