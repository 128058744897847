import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Interface } from 'app/helpers/interface';
import {CompanyType, Configuration} from 'app/shared/models';
import { AppState } from 'app/state/app.state';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  public configuration: Configuration;
  configuration$ = this.store.select(s => s.configuration);

  public get apiUrl(): string { return this.configuration.ApiUrl; }
  public get WPApiUrl(): string { return this.configuration.WPApiUrl; }
  public get benchmarkApiUrl(): string { return this.configuration.BenchmarkApiUrl; }

  languageCode$ = this.store.select(s => s.userInfo.displayLanguageCode);

  brilliantHomePageUrl$ = combineLatest([
    this.languageCode$,
    this.store.select(s => s.configuration.BrilliantHomePageBaseUrl)]).pipe(
    map(([languageCode, baseUrl]) => this.generateBrilliantHomePageUrl(languageCode, baseUrl))
  );

  //Help centers differ between CX and EX
  helpcenterBaseUrl$ = combineLatest([
    this.store.select(s => s.companyContext),
    this.store.select(s => s.configuration.ExHelpCenterBaseUrl),
    this.store.select(s => s.configuration.CxHelpCenterBaseUrl)])
    .pipe(
      filter(([companyContext, exUrl, cxUrl]) => !!companyContext?.name && !!exUrl && !!cxUrl),
      map(([companyContext, exUrl, cxUrl]) => {
        return companyContext.type == CompanyType.CX ? cxUrl : exUrl;
      })
    );

  helpcenterUrl$ = combineLatest([
    this.languageCode$,
    this.helpcenterBaseUrl$]).pipe(
    map(([languageCode, baseUrl]) => this.generateHelpcenterUrl(languageCode, baseUrl))
  );

  //Articles are only fetched from EX as of now
  helpcenterArticleUrl$ = combineLatest([
    this.languageCode$,
    this.store.select(s => s.configuration.ExHelpCenterBaseUrl)]).pipe(
    map(([languageCode, baseUrl]) => this.generateHelpcenterUrl(languageCode, baseUrl))
  );

  constructor(private store: Store<AppState>) {
    this.configuration$.pipe(
      filter(configuration => configuration !== null),
      take(1)
    ).subscribe(configuration => {
      this.configuration = configuration;
    });
  }

  private generateHelpcenterUrl(languageCode: string, baseUrl: string): string {
    const code = languageCode === 'sv' ? 'sv' : 'en-150';
    return `${baseUrl}/${code}`;
  }

  private generateBrilliantHomePageUrl(languageCode: string, baseUrl: string): string {
    const code = languageCode === 'sv' ? '' : 'en';
    return `${baseUrl}/${code}`;
  }
}

export class MockConfigurationService implements Interface<ConfigurationService> {
  public configuration: Configuration = {
    ApiUrl: '',
    AuthUrl: '',
    LocalUrl: '',
    ChrossApiUrl: '',
    BenchmarkApiUrl: '',
    InstrumentationKey: '',
    BuildVersion: '',
    BuildBranch: '',
    BuildName: '',
    Environment: '',
    WPApiUrl: '',
    ExHelpCenterBaseUrl: '',
    CxHelpCenterBaseUrl: '',
    FeatureFlags: {},
    CxPendoAPIKey: '',
    ExPendoAPIKey: '',
    BrilliantHomePageBaseUrl: ''
  };

  public get apiUrl(): string { return this.configuration?.ApiUrl; }
  public get WPApiUrl(): string { return this.configuration?.WPApiUrl; }
  public get benchmarkApiUrl(): string { return this.configuration.BenchmarkApiUrl; }

  configuration$: Observable<Configuration>;
  languageCode$;
  helpcenterUrl$;
  helpcenterBaseUrl$;
  helpcenterArticleUrl$;
  brilliantHomePageUrl$;
}
