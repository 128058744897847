export enum InputType
{
    Select = 'Select',
    Input = 'Input',
    AutoComplete = 'AutoComplete',
    DatePicker = 'DatePicker'
}

export interface OptionData {
  name: string;
  key: string;
  id: string | number;
  selectable: boolean;
  selections: SelectionData[];
}

export interface SelectionData {
  options: OptionData[];
  labelKey: string;
  controlName: string;
  translateSelectionOptions: boolean;
  inputType: string;
}

export interface OnSelection {
  selection: SelectionData;
}