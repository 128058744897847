import { ComponentStep } from './../product-wizard/product-wizard-step-factory.service';
import { Content, ContentDetailed, EditContent } from 'app/shared/models/content';

export interface ProductDefinition {
  id: number;
  key: string;
  name: string;
  name2: Content;
  title: string;
  title2: Content;
  description: string;
  description2: Content;
  highlight: string;
  highlight2: Content;
  icon?: string;
  baseName: string;
  definition: string;
  category: ProductCategory;
  productType: ProductType;
  checked: boolean;
  definitionObject: ProductDefinitionJson;
  deletable: boolean;
  isActive: boolean;
}

export interface CreateProductDefinition {
  key: string;
  name: ContentDetailed;
  title: ContentDetailed;
  description: ContentDetailed;
  highlight: ContentDetailed;
  definition: string;
  category: ProductCategory;
  productType: ProductType;
  isActive: boolean;
}

export interface UpdateProductdefinition extends CreateProductDefinition {
  id: number;
}

export interface UpdateCompanyProductDefinition {
  id: number;
  name: EditContent;
  nameText?: string;
  baseProductNameText?: string;
  title: EditContent;
  description: EditContent;
  highlight: EditContent;
  definition: string;
  category: ProductCategory;
  productType: ProductType;
  checked: boolean;
  definitionObject: ProductDefinitionJson;
  deletable: boolean;
  isActive: boolean;
}

export interface CompanyProductDefinition {
  id: number;
  name: string;
  description: string;
  baseName: string;
  definition: string;
  definitionObject: ProductDefinitionJson;
  category: ProductCategory;
  productType: ProductType;
  checked: boolean;
  highlight: string;
  totalQuestions: number;
}

export interface ProductDefinitionPrerequisites
{
  entityType: string;
  template: any;
}

export interface ProductDefinitionJson {
  productName: string;
  description: string;
  images?: ProductImageSettings;
  config: ProductDefinitionConfig[];
  summary: string;
  steps: ProductDefinitionJsonStep[] | SurveyProductDefinitionJsonStep[];
  prerequisites: ProductDefinitionPrerequisites[];
  survey?: SurveyProductSettings;
  followup?: FollowupProductSettings;
  order: number;
}

export type OrgHierarchyType = 'Organisation' | 'Matrix'
  | 'ThreeSixty' | 'Managers' | 'Background' | 'Segments';

export enum CommunicationMethod { Email = 'Email', Sms = 'Sms' }

export type MultipleSessionsType = 'NotAllowed' | 'ByGroup' | 'BySurveyRun';

export interface SurveyProductSettings {
  anonymity?: any;
  availableIndexTags?: string[];
  requiredIndexKeys?: string[];
  requiredIndexIds?: number[];
  allowedHierarchyTypes?: OrgHierarchyType[];
  multipleSessions?: MultipleSessionsType;
  ignoreExcludedFlag?: boolean;
  showPerspectives?: boolean;
  validateIndexes?: boolean;
  communicationMethods?: CommunicationMethod[];
  autoReportSettings?: AutoReportSettings;
  followupPulse?: FollowupPulse;
}

export interface ProductImageSettings {
  background: string;
  icon: string;
}
export interface FollowupProductSettings {
  metaTypes?: string[];
}

export interface FollowupPulse {
  minQuestions?: number;
  maxQuestions?: number;
}

export interface ProductDefinitionJsonStep {
  stepId: ComponentStep;
  show: boolean;
  preselected?: string[];
  settings?: any
}
export interface ProductDefinitionConfig {
  key: string;
  value: string;
}

export interface SurveyProductDefinitionJsonStep extends ProductDefinitionJsonStep {
  availableModuleTags?: string[];
  availableModuleKeys?: string[];
  preselected?: string[];
  main?: string;
  canAddReminders?: boolean;
  scheduleSelection?: 'once' | 'weekly' | 'monthly' | 'quarterly';
  activationTimeSelection?: boolean;
}

export interface DropdownModel<T = string> {
  key: T | undefined;
  name: string;
}

export interface ProductDefinitionSurveyJson extends ProductDefinitionJson {
  steps: SurveyProductDefinitionJsonStep[];
}

export enum ProductCategory {
  EX = 'EX',
  CX = 'CX'
}

export enum ProductType {
  Survey = 'Survey',
  Followup = 'Followup',
  Report = 'Report',
  CompanySetup = 'CompanySetup'
}

export interface EditCompanyProductDefinition {
  companyId: number;
  definitionId?: number;
  definition: string;
  name: EditContent;
  title: EditContent;
  highlight: EditContent;
  description: EditContent;
  isActive: boolean;
}

export interface AutoReportSettings {
  mode: AutoReportMode
  groupCategory?: string;
}

export enum AutoReportMode {
  GroupManagers = 'GroupManagers',
  GroupCategory = 'GroupCategory'
}
