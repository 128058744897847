import { PathGroup } from "../group/group.model";

export interface UserSession {
    id: number;
    sessionType: string;
    companyId: number;
    companyName: string;
    roleId: number;
    roleName: string;
    userId: number;
    userFullName: string;
    groupId?: number;
    groupName?: string;
    relation?: string;
    includeChildren?: boolean;
    managerName?: string;
    fullPathGroup?: PathGroup[];
    leaf?: boolean;
    categoryName?: string;
}

export enum SessionType {
    Company = 'Company',
    Group = 'Group'
}

export enum RoleName {
  Administrator = 'Administrator',
  Manager = 'Manager'
}