import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  Attribute,
  AttributeUsage,
  CompanyAttribute,
  CreateAttribute,
  CreateCustomBackgroundAttribute,
  EditAttribute,
  EditAttributeModel,
  FilterableAttribute,
  SelectableAttribute,
  VisibleAttribute
} from 'app/shared/models';
import { ConfigurationService } from 'app/shared/services/configuration.service';
import { AppState } from 'app/state/app.state';
import { companyAttributesSelect, companyIdSelect } from 'app/state/selectors/company-context.selectors';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { combineLatestWith, filter, map, shareReplay, switchMap } from 'rxjs/operators';

const MAX_ORDER_VALUE = 9999;

@Injectable({
  providedIn: 'root'
})
export class AttributeService {
  private companyContext$ = this.store.select(s => s.companyContext.id).pipe(filter(cId => !!cId));
  filterableAttributes$: Observable<FilterableAttribute[]> = this.companyContext$.pipe(
    switchMap(c => this.getFilterableAttributes(c)),
    shareReplay()
  );

  constructor(private _http: HttpClient, private _config: ConfigurationService, private store: Store<AppState>) {
  }

  listAll(companyId?: number, includeUserStatistics = false): Observable<SelectableAttribute[]> {
    let params = {};
    if (companyId) {
      params = {
        companyId: companyId,
        includeUserStatistics: includeUserStatistics
      };
    }
    return this._http.get<SelectableAttribute[]>(`${this._config.apiUrl}/api/attributes/list`, {
      params: params
    });
  }

  create(model: CreateAttribute): Observable<Attribute> {
    return this._http.post<Attribute>(`${this._config.apiUrl}/api/attributes`, model);
  }

  createCustomBackgroundAttribute(model: CreateCustomBackgroundAttribute): Observable<Attribute> {
    return this._http.post<Attribute>(`${this._config.apiUrl}/api/attributes/background`, model);
  }

  deleteCustomBackgroundAttribute(attributeDefinitionId: number): Observable<Attribute> {
    return this._http.delete<Attribute>(`${this._config.apiUrl}/api/attributes/background/${attributeDefinitionId}`);
  }

  getByCompany(companyId: number): Observable<Attribute[]> {
    return this._http.get<Attribute[]>(`${this._config.apiUrl}/api/companies/${companyId}/attributes`);
  }

  getVisibleAttributes(companyId: number): Observable<VisibleAttribute[]> {
    return this._http.get<VisibleAttribute[]>(`${this._config.apiUrl}/api/companies/${companyId}/visibleAttributes`);
  }

  getFilterableAttributes(companyId: number): Observable<FilterableAttribute[]> {
    return this._http.get<FilterableAttribute[]>(`${this._config.apiUrl}/api/companies/${companyId}/filterableAttributes`);
  }

  getCompanyMapped(companyId: number): Observable<EditAttributeModel[]> {
    return this._http.get<EditAttributeModel[]>(`${this._config.apiUrl}/api/attributes/companyMapped/${companyId}`);
  }

  updateCompanyMapped(companyId: number, mappedAttributes: EditAttribute[]): Observable<CompanyAttribute[]> {
    return this._http.put<CompanyAttribute[]>(`${this._config.apiUrl}/api/attributes/companyMapped/${companyId}`, mappedAttributes);
  }

  getForEdit(id: number): Observable<CreateAttribute> {
    return this._http.get<CreateAttribute>(`${this._config.apiUrl}/api/attributes/${id}/edit`);
  }

  update(model: CreateAttribute): Observable<void> {
    return this._http.put<void>(`${this._config.apiUrl}/api/attributes/${model.id}`, model);
  }

  getAttributeUsage(companyId: number, attributeId: number): Observable<AttributeUsage> {
    return this._http.get<AttributeUsage>(`${this._config.apiUrl}/api/attributes/${attributeId}/company/${companyId}/usage`);
  }

  updatedAttribute$ = new BehaviorSubject<SelectableAttribute[]>(null);
  companyAttributes$: Observable<SelectableAttribute[]> =
    combineLatest([
      this.store.select(companyAttributesSelect),
      this.store.select(s => s.userInfo.displayLanguageCode),
      this.store.select(companyIdSelect)
    ]).pipe(
      filter(([attr, lang, id]) => !!attr && !!lang && !!id),
      switchMap(([companyAttributes, lang, companyId]) => {
        const allAttributes = this.listAll(companyId, true);
        return combineLatest([of(companyAttributes), of(lang), allAttributes])
      }),
      shareReplay(1),
      map(([mappedAttributes, lang, allAttributes]): SelectableAttribute[] => {
        return allAttributes.map((a): SelectableAttribute => {
          const match = mappedAttributes.find(ma => ma.attributeId === a.id);
          return {
            ...a,
            id: match?.attributeId ?? a.id,
            checked: !!match,
            editable: !!(match?.editable),
            filterable: !!(match?.filterable),
            visible: match?.visible ?? a.visible,
            customContent: match?.customContent ?? null,
            customContentRendered: match?.customContent?.contentItems?.find(ci => ci.language === lang)?.text ?? null,
            order: match?.order ?? MAX_ORDER_VALUE,
            attributeTypeName: match?.attributeType?.name ?? a.attributeType.name,
            settings: match?.settings ?? a.settings
          }
        }).sort((a, b) => +a?.order - +b?.order);
      }),
      combineLatestWith(this.updatedAttribute$),
      map(([attributes, updated]) => {
        if (!!updated && updated.length > 0) {
          for (const u of updated) {
            const idx = attributes.findIndex(a => a.id === u.id);
            attributes[idx] = u;
          }
        }
        return attributes;
      })
    );

}
