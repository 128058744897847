import { MappingDefinition } from './organization';

export interface CompanyIntegration {
  id: number;
  integrationType: string;
  lastRun?: Date;
  frequency: string;
  isActive: boolean;
}

export interface CreateIntegration {
  integrationType: string;
  frequency: number;
  isActive: boolean;
  config: string;
  companyId: number;
  mappingDefinitions: MappingDefinition[];
}

export interface EditIntegration extends CreateIntegration {
  id: number;
}

export interface RunIntegrationModel {
  companyId: number;
  integrationId: number;
}

export interface IntegrationFields {
  providerFields: ProviderFields[]
}

export interface ProviderFields {
  name: string;
  type: string;
  fields: string[]
}


export enum IntegrationTypes {
  AlexisHr = 'AlexisHr',
  MergeDev = 'MergeDev',
  AzureAd = 'AzureAd',
  HaileyHr = 'HaileyHr',
  Twine = 'Twine'
}

export interface IntegrationValidationResult {
  isValid: boolean;
  message: string;
}
