import { CreateContentDto, EditContent } from 'app/shared/models';

export interface GroupCategoriesHierarchy {
  name: CreateContentDto;
  nameId: number;
  id: number;
  companyId: number;
  isActive: boolean;
  isDefault: boolean;
  responseSource: string;
  containsInheritAttributes: boolean;
  hierarchyLevels: HierarchyLevel[];
  visibility: HierarchyVisibilities[];
  groupAccess: string;
  roleBasedAccessRoles: number[];
  companyContexts: Context[];
  contentMappings: ContentMapping[];
  type: HierarchyTypeProduct;
}

export type GroupCategoriesHierarchySimple = Pick<GroupCategoriesHierarchy, 'id' | 'isActive' | 'isDefault'> & { name: string };

export interface EditHierarchyModel {
  hierarchy: EditHierarchy;
  filterableAttributes: { attributeDefinitionId: number, name: string, key: string, companyId: number }[];
  filterableGroupCategories: { name: string, key: string }[];
}

export interface EditHierarchy {
  name: EditContent;
  nameId: number;
  id: number;
  companyId: string;
  isActive: boolean;
  isDefault: boolean;
  responseSource: string;
  containsInheritAttributes: boolean;
  hierarchyLevels: HierarchyLevel[];
  visibility: HierarchyVisibilities[];
  groupAccess: string;
  roleBasedAccessRoles: number[];
  companyContexts: Context[];
  contentMappings: ContentMapping[];
  type: HierarchyTypeProduct;
  settings: HierarchySettings;
}

export interface HierarchySettings {
  filterResults?: FilterResults;
}

export interface FilterResults {
  groupCategoryIds: number[];
  minGroupUserCount: number;
}

export interface HierarchyLevel {
  name: string;
  key: string;
  parentKey: string;
  optional: boolean;
}

export enum HierarchyVisibilities {
  OrganisationView = 'OrganisationView',
  GroupSelector = 'GroupSelector',
  ReportGroupSelector = 'ReportGroupSelector',
  BackgroundFilter = 'BackgroundFilter',
  SurveySelection = 'SurveySelection',
  InternalUsers = 'InternalUsers',
  Segments = 'Segments'
}

export const HierarchyVisiblityTexts: { [key in keyof typeof HierarchyVisibilities]: string } = {
  OrganisationView: 'Organisation View',
  GroupSelector: 'Group Selector',
  ReportGroupSelector: 'Report Group Selector',
  BackgroundFilter: 'Background Filter',
  SurveySelection: 'Survey Selection',
  InternalUsers: 'Internal Users',
  Segments: 'Segments'
};

export interface Context {
  contextType: ContextType;
  contextSource?: ContextSource;
  groupCategoryId?: number;
  userRelationId?: number;
}

export enum ContextType {
  Group = 'Group',
  Organisation = 'Organisation'
}

export enum ContextSource {
  GroupCategory = 'GroupCategory',
  // UserRelation = 'UserRelation', // Not used yet
  SessionGroup = 'SessionGroup'
}

export interface ContentMapping {
  id?: number;
  moduleType: string;
  fromContentId: number;
  fromContentKey: string;
  toContentId: number;
  toContentKey: string;
}

export enum HierarchyTypeProduct {
  Organisation = 'Organisation',
  Matrix = 'Matrix',
  ThreeSixty = 'ThreeSixty',
  Managers = 'Managers',
  Background = 'Background',
  Segments = 'Segments'
}
