import { Direction } from '@angular/cdk/bidi';
import { SurveyPage, Answer, SurveyRule, SurveyPageGroup, SurveyRuleDependency } from 'app/shared/models';
import { ModulePageGroup } from './module-page-group.model';

export enum SurveyUITheme {
  CompanyBranded = 'CompanyBranded',
  Default = 'Default',
}

export interface SurveyUIState {
  sessionId: number;
  languageCode: string;
  languageId: number;
  hierarchyId: number;
  groupId: number;
  languageDir: Direction;
  loaded: boolean;
  loading: boolean;
  chapterPagesTotalCount: number;
  pageCurrent: number;
  chapterTotalCount: number;
  chapterCurrent: number;
  progressChapterPagesTotalCount: number;
  progressChaptersCount: number;
  progressPageCurrent: number;
  progressChapterCurrent: number;
  pageGroups: SurveyPageGroup[];
  page: SurveyPage;
  answers: Answer[];
  showFastForwardButton: boolean;
  fastForwardChapter: number;
  fastForwardPage: number;
  nextDisabled: boolean;
  previousDisabled: boolean;
  previewMode: boolean;
  animationDirectionNext: boolean;
  noSurvey: { noSurvey: boolean, expiredSurvey: boolean };
  rules: SurveyRule[];
  ruleDependencies: SurveyRuleDependency[];
  showSkipModal: boolean;
  singlePageQuestionSkipped: boolean;
  isSubmitted: boolean;
  isSubmitting: boolean;
  submitFailed: boolean;
  theme: SurveyUITheme;
  // Preview stuff
  moduleId: number;
  modulePageGroups: ModulePageGroup[];
}
