import { CreateUser, CreateUserConnection } from '../user/create-user.model';
import { GroupViewModel } from '../group/edit-group.model';
import { User } from '../user';
import { UpdatedAttribute, UpdatedUserProperty } from '..';

export interface EditMappingDefinition {
  companyId: number;
  name: string;
  userImportMap: UserImportMap;
  groupImportMap: GroupImportMap;
  relationImportMap: RelationImportMap;
  userRelationImportMap?: UserRelationImportMap;
  type: ImportType;
  partialImportSupport: boolean;
}
export interface MappingDefinition {
  id: number;
  name: string;
  companyId: number;
  description: string;
  type: ImportType;
  source: ImportSource;
  createTime: string;
  isActive: boolean;
  dateFormat: string;
  userImportMap: UserImportMap;
  groupImportMap: GroupImportMap;
  relationImportMap: RelationImportMap;
  userRelationImportMap?: UserRelationImportMap;
  partialImportSupport: boolean;
}

export interface MappingDefinitionAttribute {
  attributeKey: string;
  default: boolean;
  optional: boolean;
  customNameTranslations: string[];
  nameTranslations: string[];
  descriptionTranslations: string[];
}

export interface RelationImportMap {
  externalId: AttributeFieldMap;
  name: AttributeFieldMap;
  parentId: AttributeFieldMap;
  category: string;
  leafCategory: string;
  leafAttributes: AttributeFieldMap[];
  userAttributes: AttributeFieldMap[];
  managerKey: string;
  managerField: string;
  expectedCount: AttributeFieldMap;
  isPin: AttributeFieldMap;
  language: AttributeFieldMap;
  chrossImport: boolean;
  settings: ImportSettings;
}

export interface UserImportMap {
  key: UserKey;
  firstName: UserFieldMap;
  lastName: UserFieldMap;
  email: UserFieldMap;
  externalId: UserFieldMap;
  language: UserFieldMap;
  attributes: AttributeFieldMap[];
  phoneNumber: UserFieldMap;
  settings: ImportSettings;
}
export interface AnswerImportMap {
  key: UserKey;
  keyField: UserFieldMap;
  answers: AnswerFieldMap[];
}

export interface UserRelationImportMap {
  key: UserKey;
  user: UserFieldMap;
  userRelation: UserFieldMap;
  targetUser: UserFieldMap;
  settings: ImportSettings;
}

export interface GroupImportMap {
  id: UserFieldMap;
  name: UserFieldMap;
  externalId: UserFieldMap;
  expectedCount: UserFieldMap;
  languageId: UserFieldMap;
  pinCode: boolean;
  category: string;
  groupAttributes: AttributeFieldMap[];
  groupMeta: MetaFieldMap[];
  userAttributes: AttributeFieldMap[];
}

export interface ImportSettings {
  duplicatedRows?: DuplicatedRows;
  delete?: Delete;
  modifications?: Modification[];
  filters?: ImportFilter[];
  validationGroupId?: number;
}

export interface Delete {
  missingUsers: boolean;
  includingManuallyEditedUsers: boolean;
}

export const defaultImportSettings: ImportSettings = {
  duplicatedRows: {
    allowed: false,
    action: 'UseFirst'
  },
  delete: { missingUsers: false, includingManuallyEditedUsers: false },
  modifications: [],
  filters: [],
  validationGroupId: null
}

export interface DuplicatedRows {
  allowed: boolean;
  action: string;
  filter?: ImportFilter;
}

export interface Modification {
  type: string;
  columnName: string;
  sourceType?: string;
  sourceKey?: string;
  attributeKey?: string;
}

export interface ImportFilter {
  type: string;
  leftSource: string;
  leftValue: string;
  operator: string;
  rightSource: string;
  rightValue: string;
}

export enum UserImportBasicFields {
  firstName = 'FirstName',
  lastName = 'LastName',
  email = 'Email',
  externalId = 'ExternalId',
  language = 'Language',
  phoneNumber = 'PhoneNumber'
}

export enum GroupImportBasicFields {
  name = 'Group name',
  externalId = 'ExternalId',
  expectedCount = 'expectedCount',
  isPin = 'isPin',
  language = 'language',
}
export enum UserRelationBasicFields {
  user = 'User',
  userRelation = 'User Relation',
  targetUser = 'Target User'
}

export enum GroupPinFieldsImportAttributes {
  expectedCount = 'expectedCount',
  languageId = 'languageId',
}

export enum GroupRelationImportAttributes {
  parentId = 'parentId'
}

export enum ImportType {
  User = 'User',
  Group = 'Group',
  PincodeGroup = 'Pincode Group',
  GroupRelation = 'GroupRelation',
  UserRelation = 'UserRelation'
}

export enum ImportSource {
  Excel = 'Excel',
  Integration = 'Integration',
  BatchImport = 'BatchImport'
}

export enum UserKey {
  ExternalId = 'ExternalId',
  Email = 'Email',
  PhoneNumber = 'PhoneNumber'
}

export enum AttributeSource {
  Excel = 'Excel',
  Group = 'GroupAttribute'
}

export interface UserFieldMap {
  optional: boolean;
  column: string;
}

export interface AnswerFieldMap {
  questionKey: string;
  column: string;
}
export interface AttributeFieldMap {
  attributeKey: string;
  column: string;
  optional: boolean;
  attributeSource: AttributeSource;
  sourceKey: string;
}

export interface MetaFieldMap {
  metaKey: string;
  column: string;
  optional: boolean;
}

export interface UserImport {
  validationGroupId: number;
  dateFormat: string;
  importMap: UserImportMap;
  importSource: ImportSource;
  userRows: unknown[];
}

export interface GroupImport {
  dateFormat: string;
  importMap: GroupImportMap;
  groupRows: unknown[];
  validationGroupId: number;
}

export interface RelationImport {
  dateFormat: string;
  importMap: RelationImportMap;
  groupRows: unknown[];
}

export interface UserRelationImport {
  validationGroupId: number;
  dateFormat: string;
  importMap: UserRelationImportMap;
  userRows: unknown[];
}

export interface ImportSummary {
  userValidation?: ValidationSummary;
  groupValidation?: GroupValidationSummary;
  relationValidation?: RelationValidationSummary;
  userRelationValidation?: UserRelationValidationSummary;
  file: FileInfo;
  companyId: number;
  taskId?: number;
}

export interface FileInfo {
  name: string;
  size: string;
}

export interface HumanFriendlyFile extends File {
  humanFriendlySize: string;
}

export interface TranslationFile extends File {
  language: string;
  languageValid: boolean;
  data?: string;
}

export interface UserImportValidationResult {
  summary: ValidationSummary;
  conflictUsers: ValidatedUser[];
  validatedUsers: ValidatedUser[];
  unhandledUsers: User[];
}

export interface GroupImportValidationResult {
  summary: GroupValidationSummary;
  validatedGroups: ValidatedGroup[];
  unhandledGroups: GroupViewModel[];
}

export interface RelationImportValidationResult {
  summary: RelationValidationSummary;
  validatedGroups: ValidatedGroup[];
  validatedRelations: ValidatedRelation[];
  unhandledGroups: GroupViewModel[];
}

export interface GroupImportSaveRequestDTO {
  mappingId: number;
  validatedGroups: ValidatedGroup[];
  fileName: string;
  importSummary: GroupValidationSummary;
}

export interface RelationImportSaveRequestDTO {
  mappingId: number;
  validatedGroups: ValidatedGroup[];
  fileName: string;
  importSummary: RelationValidationSummary;
  validatedRelations: ValidatedRelation[];
  groupsToDelete: number[];
}

export interface ImportSaveResultDTO {
  users: User[];
  taskId?: number;
}

export interface UserRelationImportValidationResult {
  summary: UserRelationValidationSummary;
  addedRelations: ValidatedUserRelation[];
  updateRelations: ValidatedUserRelation[];
  userConnectionIdsToRemove: number[];
  validatedUserRelations: ValidatedUserRelation[];
}

export interface RelationValidationSummary {
  nrOfRows: number;
  nrOfAffectedUsers: number;
  nrOfAddedGroups: number;
  nrOfUpdatedGroups: number;
  nrOfAddedRelationships: number;
  nrOfUpdatedRelationships: number;
  nrOfAddedManagers: number;
  nrOfRemovedManagers: number;
  nrOfKeptManagers: number;
  nrOfDeletedGroups: number;
}

export interface GroupValidationSummary {
  nrOfRows: number;
  nrOfColumns: number;
  nrOfAffectedUsers: number;
  nrOfAddedGroups: number;
  nrOfUpdatedGroups: number;
  nrOfDeletedGroups: number;
  nrOfUpdatedAttributes: number;
}

export interface ValidationSummary {
  nrOfRows: number;
  nrOfColumns: number;
  nrOfUsers: number;
  nrOfAddedUsers: number;
  nrOfUpdatedUsers: number;
  nrOfDeletedUsers: number;
  nrOfUpdatedAttributes: number;
  nrOfAffectedUsers: number;
  nrOfUsersNotInValidationGroup: number;
  nrOfPossibleOrphans: number;
  nrOfUnmatchedUsers: number;
}

export interface UserRelationValidationSummary {
  nrOfRows: number;
  nrOfUsers: number;
  nrOfAddedRelationships: number;
  nrOfUpdatedRelationships: number;
  nrOfRemovedRelationships: number;
}

export interface ValidatedUser {
  id?: number;
  row?: number;
  userDto: CreateUser;
  updatedAttributes?: UpdatedAttribute[];
  updatedUserProperties?: UpdatedUserProperty[];
  validationErrors: FieldValidationError[];
}

export interface ValidatedUpdate {
  id: number;
  updatedAttributes: UpdatedAttribute[];
  updatedUserProperties?: UpdatedUserProperty[];
  languageMap?: LanguageMap;
}

export interface ValidatedItem {
  validationErrors: ValidationError[];
  validationErrorFields: FieldValidationError[];
}
export interface ValidatedGroup extends ValidatedItem {
  id?: number;
  groupDto: GroupViewModel;
  users: ValidatedUser[];
}

export interface ValidatedRelation extends ValidatedItem {
  row?: number;
  parentExternalId: string;
  childExternalId: string;
  isNew: boolean;
}

export interface ValidatedUserRelation {
  row?: number;
  userIdentifier: string;
  userRelationName: string;
  targetUserIdentifier: string;
  existing: boolean;
  updated: boolean;
  validationErrors: FieldValidationError[];
  createUserConnectionDto: CreateUserConnection;
}

export interface ValidationError {
  reason: string;
  field: string;
  exceptionType: string;
  status: ValidationStatus;
}

export interface FieldValidationError {
  type: ValidationErrorType;
  column: string;
  oldValue?: string;
  value: string;
  status: ValidationStatus;
  languageMap?: LanguageMap;
  row: string;
}

export interface DisplayConflictRow {
  id: number | string;
  name: string;
  attributeKey: string;
  oldValue: string;
  newValue: string;
  resolvedValue: string;
  languageMap?: LanguageMap;
}

export interface SaveValidatedUsersResult {
  users: User[];
  taskId: number;
}

export interface LanguageMap {
  [key: string]: any;
}

export interface DuplicateValueValidationError {
  row: number;
}

export interface NonUniqueValueValidationError {
  userId: number;
}

export type ValidationErrorType =
  | 'INVALID_VALUE'
  | 'DUPLICATE_VALUE'
  | 'REQUIRED_VALUE'
  | 'INVALID_LANGUAGE'
  | 'CONFLICT'
  | 'CONFLICT_USERS'
  | 'NON_UNIQUE_VALUE';

export type ValidationGroupInfoType =
  'UNHANDLED_GROUPS'
  | 'NEW_GROUPS'
  | 'UPDATED_GROUPS'
  | 'ADDED_RELATIONSHIPS'
  | 'UPDATED_RELATIONSHIPS'
  | 'DELETED_GROUPS';

export type ValidationUserInfoType =
  'NEW_USERS'
  | 'UPDATED_USERS'
  | 'UNCHANGED'
  | 'DEACTIVATED'
  | 'CONFLICT_USERS'
  | 'UNHANDLED_USERS';


  export type ValidationUserRelationInfoType =
  'NEW_RELATIONS'
  | 'UPDATED_RELATIONS'
  | 'UNCHANGED_RELATIONS';

export type ValidationErrorInfo = ValidationErrorType
  | ValidationGroupInfoType
  | ValidationUserInfoType
  | ValidationUserRelationInfoType;

export enum ValidationStatus {
  ActionRequired = 'ActionRequired',
  Error = 'Error',
  Warning = 'Warning',
  Info = 'Info',
  Ok = 'Ok'
}

export interface FailedRow {
  data: any;
  reason: string;
  exceptionType: string;
}
