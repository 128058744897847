import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Event, NavigationError, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { of } from 'rxjs';

import { UserInfo } from 'app/shared/models';
import { take } from 'rxjs/operators';

// https://medium.com/@aleixsuau/error-handling-angular-859d529fa53a

@Injectable({
    providedIn: 'root'
})
export class ErrorsService {
    private userInfo: UserInfo;
    constructor(
        private injector: Injector,
        private router: Router,
        private snackBar: MatSnackBar,
        private translations: TranslocoService) {
            this.router
            .events
            .subscribe((event: Event) => {
              if (event instanceof NavigationError) {
                  // Redirect to the ErrorComponent
                const errorWithContext = this.log(event.error);
                // this.router.navigate(['/error'], { queryParams: errorWithContext });
              }
            });
    }
    log(error) {
        // Log the error to the console
        console.error(error);
        // Send error to server
        const errorToSend = this.addContextInfo(error);
        // return fakeHttpService.post(errorToSend);
        return of(<any>errorToSend);
      }
      addContextInfo(error) {
        // You can include context details here (usually coming from other services: UserService...)
        const name = error.name || null;
        const appId = 'Insight';
        const user = this.userInfo ? this.userInfo.userName : '';
        const time = new Date().getTime();
        const id = `${appId}-${user}-${time}`;
        const location = this.injector.get(LocationStrategy);
        const url = location instanceof PathLocationStrategy ? location.path() : '';
        const status = error.status || null;
        const message = error.message || error.toString();
        const errorWithContext = { name, appId, user, time, id, url, status, message };
        return errorWithContext;
      }
    public openErrorPopup(message: string | HttpErrorResponse, duration: number = 5000) {
        const msg = message instanceof HttpErrorResponse ?
            this.getMessage(message ) :
            message ;
        this.translations.selectTranslate('INSIGHT.GLOBAL.DISMISS')
          .pipe(take(1))
          .subscribe(dismiss => {
            const popup = this.snackBar.open(msg, dismiss, { duration: duration,
                politeness: 'assertive',
                panelClass: 'assertive',
                verticalPosition: 'bottom',
                horizontalPosition: 'center' });
            popup.onAction().subscribe(() => {
                popup.dismiss();
            });
        });
    }

    private getMessage(res: HttpErrorResponse): string {
        let msg = 'The application has encountered an unknown error';
        if (res.status === 422) {
            if (typeof res.error === 'string') {
                const err = JSON.parse(res.error);
                msg = err.error;
            } else if (res.error.errors) {
                msg = res.error.errors[0].message + ': ' + res.error.errors[0].field;
            }
        } else if (res.error == null && res.statusText) {
            msg = res.statusText;
        } else if (typeof res.error === 'object' && res.error.error) {
            msg = res.error.error;
        } else if (typeof res.error === 'string') {
            msg = res.error;
        }
        return msg;
    }
}
