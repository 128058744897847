import { CompanyAttribute } from '../attribute';
import { Language } from '../language.model';
import { ProductDefinition} from 'app/shared/models';
import { CompanyComment } from './company-comment-model';

export interface Company {
  id: number;
  name: string;
  shortName: string;
  internalName: string;
  accountName: string;
  branch: string;
  detailedBranch: string;
  companyLanguages: Language[];
  companyAttributes: CompanyAttribute[];
  companyComments: CompanyComment[];
  restrictedActivationCommunication: boolean;
  smsEnabled: boolean;
  pinEnabled: boolean;
  mySurveysEnabled: boolean;
  isActive: boolean;
  userExternalIdIsMandatory: boolean;
  orgNumber?: string;
  productDefinition?: ProductDefinition;
  branding: CompanyBranding
  type: CompanyType;
  segmentsHierarchyId?: number;
}
export interface CompanyLogo {
  name: string;
  url: string;
  size: number;
}

export interface OptOut {
  logo: CompanyLogo;
  primaryColor: string;
  primaryContrastColor: string;
  languageId: number;
  languageCode: string;
  rtl: boolean;
  excludedFromSurveys: boolean;
}

export interface CompanyBranding {
  primaryColor: string;
  primaryContrastColor: string;
}

export enum CompanyType {
  'EX' = 'EX',
  'CX' = 'CX'
}

export type CompanyWithAccount = Pick<Company, 'name' | 'id'> & { accountId?: number };
