import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConfigurationService } from 'app/shared';

@Injectable({
  providedIn: 'root'
})
export class PinCodeService {
  constructor(private _http: HttpClient, private _config: ConfigurationService) {
  }

  pinCodeLogon(pinCode: string): Observable<string> {
    return this._http
      .post<string>(`${this._config.apiUrl}/api/sessions/${pinCode}/logon`, {}, { headers: { 'init': 'true' } });
  }
}
