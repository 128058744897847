import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ValidateManuallyUser } from 'app/organization/organization/user/user-profile/user-profile.component';
import {
  BatchUpdateUsers,
  CreateUser,
  DeleteUserDto,
  EventLog,
  FieldValidationError,
  InternalUsers,
  Language,
  MoveUserDto,
  SaveValidatedUsersResult,
  SelectableUser,
  SwitchUserResponse,
  User,
  UserImport,
  UserImportValidationResult,
  UserInfo,
  UserStatusInfo,
  UserWithRelatedUsers,
  ValidatedUser,
  ValidationSummary
} from 'app/shared/models';
import { ConfigurationService } from 'app/shared/services/configuration.service';
import { Observable, shareReplay, take } from 'rxjs';
import { SurveysStatuses } from '../models/survey/survey-status';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private perspectiveGroups: string;
  private usersWithRelatedUsers$: Observable<UserWithRelatedUsers[]>;

  constructor(
    private _http: HttpClient,
    private _config: ConfigurationService) {
  }

  get(id: number): Observable<User> {
    return this._http
      .get<User>(`${this._config.apiUrl}/api/users/${id}`);
  }

  getByGroupId(groupId: number): Observable<User[]> {
    return this._http.get<User[]>(`${this._config.apiUrl}/api/users/groups/${groupId}`);
  }

  getUsersAndManagers(groupId: number): Observable<User[]> {
    return this._http.get<User[]>(`${this._config.apiUrl}/api/users/group-users-and-managers/${groupId}`);
  }

  getGroupUsers(companyId: number, groupId: number): Observable<User[]> {
    return this._http.get<User[]>(`${this._config.apiUrl}/api/users/group-users?companyId=${companyId}&groupId=${groupId}`);
  }

  getByCompanyId(companyId: number): Observable<User[]> {
    return this._http
      .get<User[]>(`${this._config.apiUrl}/api/companies/${companyId}/users`);
  }

  getSelectableUsers(companyId: number): Observable<SelectableUser[]> {
    return this._http
      .get<SelectableUser[]>(`${this._config.apiUrl}/api/companies/${companyId}/users/selectable`)
      .pipe(shareReplay(1));
  }

  getInternalUsers(companyId: number): Observable<InternalUsers> {
    return this._http.get<InternalUsers>(`${this._config.apiUrl}/api/companies/${companyId}/users/internal`);
  }

  getByCompanyIdWithAttributes(companyId: number): Observable<User[]> {
    return this._http
      .get<User[]>(`${this._config.apiUrl}/api/companies/${companyId}/users/verbose`);
  }

  getCompanyGroupsUsers(companyId: number, groupIds: number[], ignoreExcludedFlag: boolean): Observable<User[]> {
    return this._http.post<User[]>(`${this._config.apiUrl}/api/companies/${companyId}/groups/verbose`, groupIds, {
      params: {
        ignoreExcludedFlag: ignoreExcludedFlag
      }
    });
  }

  getCompanyGroupsUsersPerspectives(companyId: number, hierarchyId: number, groupIds: number[]): Observable<UserWithRelatedUsers[]> {
    if (JSON.stringify(groupIds) !== this.perspectiveGroups) {
      this.perspectiveGroups = JSON.stringify(groupIds);
      this.usersWithRelatedUsers$ = this._http.post<UserWithRelatedUsers[]>(`${this._config.apiUrl}/api/companies/${companyId}/${hierarchyId}/360groupusersandrelations`, groupIds).pipe(take(1), shareReplay(1));
    }
    return this.usersWithRelatedUsers$;
  }

  listNotInSurveyByCompany(companyId: number, surveyId: number): Observable<User[]> {
    return this._http
      .get<User[]>(`${this._config.apiUrl}/api/companies/${companyId}/usersnotinsurvey/${surveyId}`);
  }

  getBySurvey(surveyId: number): Observable<User[]> {
    return this._http
      .get<User[]>(`${this._config.apiUrl}/api/surveys/${surveyId}/usersinsurvey`);
  }

  listNotInSurveyByGroup(groupId: number, surveyId: number): Observable<User[]> {
    return this._http
      .get<User[]>(`${this._config.apiUrl}/api/group/${groupId}/groupusersnotinsurvey/${surveyId}`);
  }

  validateManuallyEditedUser(model: ValidateManuallyUser): Observable<FieldValidationError[]> {
    return this._http
      .post<FieldValidationError[]>(`${this._config.apiUrl}/api/users/validate-manually`, model);
  }

  create(model: CreateUser): Observable<User> {
    return this._http
      .post<User>(`${this._config.apiUrl}/api/users`, model);
  }

  createInternal(model: CreateUser): Observable<User> {
    return this._http
      .post<User>(`${this._config.apiUrl}/api/users/internal`, model);
  }

  createPinCodeUser(model: CreateUser, userCount: number, addToSurvey: boolean): Observable<User[]> {
    model['userCount'] = userCount;
    model['addToSurvey'] = addToSurvey;
    return this._http
      .post<User[]>(`${this._config.apiUrl}/api/users/pin-code`, model);
  }

  update(userId: number, model: CreateUser): Observable<User> {
    return this._http
      .put<User>(`${this._config.apiUrl}/api/users/${userId}`, model);
  }

  batchUpdateUsers(model: BatchUpdateUsers): Observable<User[]> {
    return this._http.put<User[]>(`${this._config.apiUrl}/api/users/batch`, model);
  }

  batchUpdateDeleteUserAttribute(model: BatchUpdateUsers): Observable<User[]> {
    return this._http.put<User[]>(`${this._config.apiUrl}/api/users/batchdelete`, model);
  }

  userStatuses(): Observable<UserStatusInfo[]> {
    return this._http
      .get<UserStatusInfo[]>(`${this._config.apiUrl}/api/users/statuses`);
  }

  userInfo(companyId?: number): Observable<UserInfo> {
    let url = `${this._config.apiUrl}/api/users/current`;
    if (companyId) {
      url += `/${companyId}`;
    }
    return this._http
      .get<UserInfo>(url, { headers: { 'init': 'true' } });
  }

  setUserLanguage(language: Language): Observable<UserInfo> {
    return this._http.put<UserInfo>(`${this._config.apiUrl}/api/users/language`, { 'LanguageId': language.id });
  }

  setUserLanguageFromToken(token: string, languageId: number): Observable<UserInfo> {
    return this._http.put<UserInfo>(`${this._config.apiUrl}/api/users/${token}/language`, { 'LanguageId': languageId });
  }

  setUserExcludeFromSurveys(companyId: number, userId: number, excludeFromSurveys: boolean): Observable<User> {
    return this._http.post<User>(`${this._config.apiUrl}/api/users/${userId}/exclude-from-surveys`, { 'CompanyId': companyId, 'ExcludeFromSurveys': excludeFromSurveys });
  }

  validateImportUsers(companyId: number, model: UserImport): Observable<UserImportValidationResult> {
    return this._http.post<UserImportValidationResult>(`${this._config.apiUrl}/api/companies/${companyId}/users/validate`, model);
  }

  deactivateUsers(companyId: number, ids: Array<number>): Observable<number> {
    return this._http.post<number>(`${this._config.apiUrl}/api/companies/${companyId}/users/deactivate`, ids);
  }

  GDPRDeleteUsers(companyId: number, model: DeleteUserDto): Observable<HttpResponse<number[]>> {
    return this._http.post<number[]>(`${this._config.apiUrl}/api/companies/${companyId}/users/gdpr-delete`, model,
      { observe: 'response', responseType: 'json' });
  }

  save(companyId: number,
    model: { mappingId: number, validatedUsers: ValidatedUser[]; fileName: string; importSummary: ValidationSummary }
  ): Observable<SaveValidatedUsersResult> {
    return this._http.post<SaveValidatedUsersResult>(`${this._config.apiUrl}/api/companies/${companyId}/users/save`, model);
  }

  clearUserState(userId: number): Observable<void> {
    return this._http
      .delete<void>(`${this._config.apiUrl}/api/users/${userId}/state`);
  }

  getImpersonateLink(userId: number): Observable<string> {
    return this._http
      .get<string>(`${this._config.apiUrl}/api/users/${userId}/impersonate`);
  }

  move(model: MoveUserDto): Observable<HttpResponse<void>> {
    return this._http
      .put<void>(`${this._config.apiUrl}/api/users/move`, model, { observe: 'response', responseType: 'text' as 'json' });
  }

  eventLog(id: number): Observable<EventLog> {
    return this._http.get<EventLog>(`${this._config.apiUrl}/api/users/${id}/log`);
  }

  switchUser(userId: number): Observable<SwitchUserResponse> {
    return this._http.post<SwitchUserResponse>(`${this._config.apiUrl}/api/users/switch`, { userId: userId });
  }

  getSurveysStatusesByUser(userId: number): Observable<SurveysStatuses> {
    return this._http.get<SurveysStatuses>(`${this._config.apiUrl}/api/users/${userId}/surveys/statuses`);
  }
}

