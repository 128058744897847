import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Directive()
export class NeedsToUnsubscribe implements OnDestroy {
  protected unsubscribe$: Subject<void> = new Subject<void>();
  ngOnDestroy(): void {
    this.manualUnsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  // Can be used to perform manual unsubscribe
  manualUnsubscribe(): void {

  }
}
