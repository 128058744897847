import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ConfigurationService } from 'app/shared';
import { AppState } from 'app/state/app.state';
import { Observable, combineLatest } from 'rxjs';
import { exhaustMap, filter, take } from 'rxjs/operators';

@Injectable()
export class ContextHeaderInterceptor implements HttpInterceptor {

  constructor(private store: Store<AppState>, private configService: ConfigurationService) { }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (req.headers.has('init')) return next.handle(req);

    return combineLatest([
      this.store.select(s => s.companyContext),
      this.store.select(s => s.userInfo),
      this.store.select(s => s.configuration),
    ]).pipe(
      filter(([a, b, c]) => !!a?.id && !!b?.userId && !!c?.ApiUrl),
      take(1),
      exhaustMap(([companyContext, userInfo, configuration]) => {
        if (configuration?.ApiUrl && companyContext?.id && req.url.includes(configuration.ApiUrl)) {
          req = req.clone({
            setHeaders: {
              'Selected-Company-Context': companyContext.id.toString()
            }
          })
        }
        if (configuration?.ApiUrl && companyContext?.id && req.url.includes(configuration.ApiUrl)) {
          req = req.clone({
            setHeaders: {
              'Selected-User-Context': userInfo.userId.toString()
            }
          })
        }
        return next.handle(req);
      })
    );
  }
}
