import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ConfigurationService } from 'app/shared/services/configuration.service';
import { AppState } from 'app/state/app.state';
import { BehaviorSubject, iif, Observable, of } from 'rxjs';
import { filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { ComparisonBackgroundFilterTreeModel, ComparisonGroupModel, flattenTreeModel, HierarchyDto, OrganisationGroupModel, ReportGroupModel, SurveyGroupModel, SurveySetupGroupModel } from '../models';

@Injectable({
  providedIn: 'root'
})
export class TreeService {

  private _backgroundFiltering$ = new BehaviorSubject<boolean>(false);
  public useBackgroundFiltering(arg: boolean) {
    this._backgroundFiltering$.next(arg)
  }

  private companyContext$ = this.store.select(s => s.companyContext.id)
    .pipe(filter(cId => !!cId), take(1));

  excludeParents: boolean = false;

  comparisonTree$: Observable<ComparisonBackgroundFilterTreeModel> = this.companyContext$.pipe(
    switchMap(c => this.getComparisonTree(c, this.excludeParents)),
    shareReplay(1)
  );

  reportTree$ = this.companyContext$.pipe(
    switchMap(c => this.getReportTree(c)),
    shareReplay(1)
  );

  backgroundFilters$ = this._backgroundFiltering$.pipe(
    switchMap(
      (bgFilteringEnabled): Observable<HierarchyDto<ComparisonGroupModel>> =>
        iif(() => bgFilteringEnabled,
          this.comparisonTree$.pipe(map(tree => tree.backgroundFilters)),
          of({
            tree: [],
            name: undefined,
            id: undefined,
            isDefault: undefined
          })
        )
    ),
    shareReplay(1)
  )
  comparisonGroups$ = this.comparisonTree$.pipe(map(tree => tree.comparisonGroups), shareReplay(1));

  constructor(private _http: HttpClient, private _config: ConfigurationService, private store: Store<AppState>) {
  }

  getOrganisationTree(companyId: number): Observable<HierarchyDto<OrganisationGroupModel>[]> {
    return this._http.get<HierarchyDto<OrganisationGroupModel>[]>(`${this._config.apiUrl}/api/trees/organisation`, {
      params: {
        companyId: companyId.toString()
      }
    }).pipe(
      tap(hierarchies => {
        hierarchies.forEach(h => flattenTreeModel(h));
      })
    );
  }

  getReportTree(companyId: number): Observable<HierarchyDto<ReportGroupModel>[]> {
    return this._http.get<HierarchyDto<ReportGroupModel>[]>(`${this._config.apiUrl}/api/trees/report`, {
      params: {
        companyId: companyId.toString()
      }
    }).pipe(
      tap(hierarchies => {
        hierarchies.forEach(h => flattenTreeModel(h));
      })
    );
  }

  getComparisonTree(companyId: number, excludeParents: boolean): Observable<ComparisonBackgroundFilterTreeModel> {
    return this._http.get<ComparisonBackgroundFilterTreeModel>(`${this._config.apiUrl}/api/trees/comparison`, {
      params: {
        companyId: companyId.toString(),
        excludeParents
      }
    }).pipe(
      tap(data => {
        data.comparisonGroups.forEach(h => flattenTreeModel(h));
      })
    );
  }

  getSurveyTree(companyId: number, surveyId: number): Observable<HierarchyDto<SurveyGroupModel>[]> {
    return this._http.get<HierarchyDto<SurveyGroupModel>[]>(`${this._config.apiUrl}/api/trees/survey`, {
      params: {
        companyId: companyId.toString(),
        surveyId: surveyId.toString()
      }
    }).pipe(
      tap(hierarchies => {
        hierarchies.forEach(h => flattenTreeModel(h));
      })
    );
  }

  getSurveySetupTree(companyId: number, companyProductDefinitionId: number): Observable<HierarchyDto<SurveySetupGroupModel>[]> {
    return this._http.get<HierarchyDto<SurveySetupGroupModel>[]>(`${this._config.apiUrl}/api/trees/survey-setup`, {
      params: {
        companyId: companyId.toString(),
        companyProductDefinitionId: companyProductDefinitionId?.toString() ?? '0'
      }
    }).pipe(
      tap(hierarchies => {
        hierarchies.forEach(h => flattenTreeModel(h));
      })
    );
  }
}
