import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'app/shared/services';

@Injectable()
export class AdminGuard  {
    constructor(private authService: AuthenticationService, private router: Router) { }

    canActivate() {
        if (this.authService.currentUser.profile.role.indexOf('admin') > -1) {
            return true;
        }
        this.router.navigate(['/']);
        return false;
    }
}
