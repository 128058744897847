import {
  ContentItem,
  DisplayType, ReportPageDto,
  ReportPageItemType
} from 'app/shared/models';

export function toReportPageIndexKey(indexKey: string) {
  return `index_${indexKey.toLowerCase()}` as const;
}

export function indexReportPage(
  indexKey: string,
  names: ContentItem[]
): ReportPageDto {
  const indexKeyLower = indexKey.toLowerCase();
  return {
    key: toReportPageIndexKey(indexKeyLower),
    name: names.map(name => ({
      language: name.language,
      text: name.text,
      name: undefined,
      visible: undefined
    })),
    settings: null,
    displayType: DisplayType.CardSelector,
    condition: {
      operator: 'eq',
      leftDataSourceName: 'Current results',
      leftSource: 'Index',
      leftValue: indexKey,
      leftField: 'Any',
      rightSource: 'Const',
      rightValue: 'True',
      conditions: []
    },
    meta: [
      {
        key: 'index',
        value: indexKey,
      },
      {
        key: 'icon',
        value: 'bar3',
      },
    ],
    reportPageItems: [
      {
        name: `${indexKey} index`,
        enabled: true,
        reportPageItemType: ReportPageItemType.IndexWithComparison,
        dataSourceNames: ['Current results', 'Group comparisons'],
        helpTextContentNames: [null],
        settings: {
          indexKey: indexKey,
        },
        order: 0
      },
      {
        name: `${indexKey} index group distribution`,
        enabled: true,
        reportPageItemType: ReportPageItemType.IndexGroupDistributions,
        dataSourceNames: ['Current results'],
        helpTextContentNames: [],
        settings: {
          indexKey: indexKey,
        },
        order: 1,
        condition: {
          operator: 'neq',
          leftSource: 'Parameter',
          leftField: 'currentGroupCategory',
          rightSource: 'Const',
          rightValue: 'team',
          conditions: []
        },
      },
      {
        name: `${indexKey} Questions`,
        enabled: true,
        reportPageItemType: ReportPageItemType.QuestionResults,
        dataSourceNames: ['Current results', 'Group comparisons'],
        helpTextContentNames: [],
        settings: {
          indexes: [indexKey],
          optionGrade: '3Grade',
          expandAllCombinations: false,
          excludedQuestionKeys: [],
        },
        order: 2
      },
    ],
  };
}
