import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Configuration } from 'app/shared/models';
import { AppState } from 'app/state/app.state';
import { Log, User, UserManager, UserManagerSettings } from 'oidc-client';
import { from, Observable, of } from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';

Log.logger = console;
Log.level = Log.WARN;

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  config$: Observable<Configuration>;
  authConfig$: Observable<UserManagerSettings>;
  authConfig: UserManagerSettings = null;
  mgr$: Observable<UserManager>;
  userLoadededEvent: EventEmitter<User> = new EventEmitter<User>();
  currentUser: User;
  loggedIn = false;
  apiUrl: string;
  authApiUrl: string;
  bmApiUrl: string;

  constructor(private router: Router, store: Store<AppState>, private _http: HttpClient) {
    this.config$ = store.select(state => state.configuration)
      .pipe(filter(c => c != null), shareReplay(1));
    this.authConfig$ = this.config$.pipe(
      filter(c => c != null),
      map(c => ({
        authority: c.AuthUrl,
        client_id: 'insightWeb',
        redirect_uri: `${c.LocalUrl}/signin-callback`,
        post_logout_redirect_uri: `${c.LocalUrl}/signout-callback`,
        response_type: 'id_token token',
        scope: 'openid profile insightAPI IdentityServerApi',
        // silent_redirect_uri: `${c.LocalUrl}/silent-renew`,
        silent_redirect_uri: `${c.LocalUrl}/assets/static/silent.html`,
        automaticSilentRenew: true,
        accessTokenExpiringNotificationTime: 4,
        // silentRequestTimeout:10000,
        filterProtocolClaims: true,
        loadUserInfo: true,
        monitorSession: false,
      })),
      shareReplay(1)
    );
    this.config$.subscribe(config => {
      this.apiUrl = config.ApiUrl;
      this.authApiUrl = `${config.AuthUrl}/api`;
      this.bmApiUrl = config.BenchmarkApiUrl;
    });
    this.authConfig$.subscribe(authConfig => this.authConfig = authConfig);
    this.mgr$ = this.authConfig$.pipe(map(authConfig => new UserManager(authConfig)), shareReplay(1));
    this.mgr$.subscribe(mgr => {
      mgr.getUser()
        .then((user) => {
          this.handleLoggedIn(user);
        })
        .catch(() => {
          this.loggedIn = false;
        });

      mgr.events.addUserLoaded((user) => {
        this.handleLoggedIn(user);
      });
      mgr.events.addUserUnloaded(() => {
        this.loggedIn = false;
      });
      mgr.events.addSilentRenewError(e => {
        console.error('Silent renew failed: ', e);
        this.startSignoutMainWindow();
      });

      mgr.events.addAccessTokenExpiring(() => {
        console.info('Access token expiring...')
      })
    });
  }

  private handleLoggedIn(user: User) {
    if (user) {
      this.loggedIn = true;
      this.currentUser = user;
      this.userLoadededEvent.emit(user);
    } else {
      this.loggedIn = false;
    }
  }
  refreshToken(): Observable<User> {
    return this.mgr$.pipe(switchMap(mgr => {
      mgr.startSilentRenew();
      return from(mgr.signinSilentCallback());
    }));
  }

  isLoggedInObs(): Observable<boolean> {
    return this.mgr$.pipe(switchMap(mgr => {
      return from(mgr.getUser()).pipe(map<User, boolean>((user) => {
        this.handleLoggedIn(user);
        return this.loggedIn;
      }));
    }));
  }

  clearState() {
    this.mgr$.subscribe(mgr => {
      void mgr.clearStaleState();
    });
  }

  getUser() {
    this.mgr$.subscribe(mgr => {
      mgr.getUser().then((user) => {
        this.handleLoggedIn(user);
      }).catch(err => {
        console.log(err);
      });
    });
  }

  removeUser() {
    this.mgr$.subscribe(mgr => {
      mgr.removeUser().then(() => {
        this.handleLoggedIn(null);
      }).catch(err => {
        console.log(err);
      });
    });
  }

  endSilentRenew() {
    this.mgr$.subscribe(mgr => void mgr.signinSilentCallback());
  }

  startSigninToken(encryptedToken: string) {
    const token = encodeURIComponent(encryptedToken);
    const simpleArgs = { state: { returnUrl: `/survey/${token}`, token: token } };
    this.mgr$.subscribe(mgr => {
      mgr.createSigninRequest(simpleArgs).then(req => {
        const url = encodeURIComponent(req.url.replace(this.authConfig.authority, ''));
        window.location.href = `${this.authConfig.authority}/account/loginwithlink?token=${token}&returnUrl=${url}`;
      });
    });
  }

  startSigninImpersonate(encryptedInfo: string) {
    const info = encodeURIComponent(encryptedInfo);
    const simpleArgs = { state: { returnUrl: '', info: info } };
    this.mgr$.subscribe(mgr => {
      void mgr.createSigninRequest(simpleArgs).then(req => {
        const url = encodeURIComponent(req.url.replace(this.authConfig.authority, ''));
        window.location.href = `${this.authConfig.authority}/account/impersonate?info=${info}&returnUrl=${url}`;
      });
    });
  }
  startChangePassword(encryptedToken: string) {
    const simpleArgs = { state: { returnUrl: '/' } };
    this.mgr$.subscribe(mgr => {
      void mgr.createSigninRequest(simpleArgs).then(req => {
        const url = encodeURIComponent(req.url.replace(this.authConfig.authority, ''));
        const token = encodeURIComponent(encryptedToken);
        window.location.href = `${this.authConfig.authority}/account/changepassword?token=${token}&returnUrl=${url}`;
      });
    });
  }
  startSigninMainWindow(url: string) {
    this.mgr$.subscribe(mgr => {
      mgr.signinRedirect({ state: { returnUrl: url } })
        .then()
        .catch(err => {
          console.log(err);
        });
    });
  }

  endSigninMainWindow() {
    try {
      this.mgr$.subscribe(mgr => {
        mgr.signinRedirectCallback().then((user) => {
          this.handleLoggedIn(user);
          this.trackUserVisit(user);
          if (user.state?.returnUrl && user.state?.returnUrl !== '/') {
            const urlAndHash = user.state.returnUrl.split('#');
            if (user.state.token) {
              window.localStorage.setItem('token', user.state.token);
            } else {
              window.localStorage.removeItem('token');
            }
            if (urlAndHash.length === 2) {
              this.router.navigate([urlAndHash[0]], { fragment: urlAndHash[1] });
            } else {
              this.router.navigate([user.state.returnUrl]);
            }
          } else {
            if (user.profile['role'].indexOf('admin') > -1) {
              this.router.navigate(['/admin']);
            } else {
              this.router.navigate(['']);
            }
          }
        }).catch(err => {
          console.log(err);
          this.router.navigate(['/']);
        });
      });
    } catch {
      this.router.navigate(['/']);
    }
  }
  startSignoutMainWindow() {
    this.mgr$.subscribe(mgr => {
      mgr.signoutRedirect({ id_token_hint: this.currentUser?.id_token }).then((resp) => {
        sessionStorage.clear();
        localStorage.removeItem('currentCompanyIdAndUserId');
      }).catch(err => {
        console.log(err);
      });
    });
  }
  endSignoutMainWindow() {
    const m = this;
    this.mgr$.subscribe(mgr => {
      mgr.signoutRedirectCallback().then((resp) => {
        m.startSigninMainWindow('/');
      }).catch(err => {
        console.log(err);
      });
    });
  }
  trackUserVisit(user: User) {
    if (user.profile.role === 'admin') { return; }
    let path = '';
    if (user.state && user.state.returnUrl) {
      if (user.state.token) {
        path = user.state.returnUrl.indexOf(user.state.token) > -1 ?
          user.state.returnUrl.replace(user.state.token, '') :
          user.state.returnUrl;
      } else {
        path = user.state.returnUrl;
      }
    }
    this._http.post(`${this.apiUrl}/api/users/visit`, { path: path, type: 'Login' }).subscribe();
  }
}
