import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Interface } from 'app/helpers/interface';
import { ConfigurationService } from 'app/shared/services/configuration.service';
import { Observable, of as observableOf } from 'rxjs';
import {
  CompanyIntegration,
  CreateIntegration,
  EditIntegration,
  RunIntegrationModel,
  IntegrationFields,
  IntegrationValidationResult
} from 'app/shared/models';
import { parameters } from '../../admin-tpl/company-overview/reports/templates/default';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsService {
  constructor(private _http: HttpClient, private _config: ConfigurationService) {
  }

  getByCompany(companyId: number): Observable<CompanyIntegration[]> {
    return this._http
      .get<CompanyIntegration[]>(`${this._config.apiUrl}/api/integrations`, {
        params: {
          companyId: companyId
        }
      });
  }
  getForEdit(id: number) : Observable<EditIntegration> {
    return this._http
      .get<EditIntegration>(`${this._config.apiUrl}/api/integrations/${id}`);
  }
  update(model: EditIntegration) : Observable<void> {
    return this._http
      .put<void>(`${this._config.apiUrl}/api/integrations/${model.id}`, model);
  }
  create(model: CreateIntegration) : Observable<void> {
    return this._http
      .post<void>(`${this._config.apiUrl}/api/integrations`, model);
  }
  deleteIntegration(id: number): Observable<void> {
    return this._http.delete<void>(
      `${this._config.apiUrl}/api/integrations/${id}`);
  }
  getFields(companyId: number): Observable<IntegrationFields> {
    return this._http
      .get<IntegrationFields>(`${this._config.apiUrl}/api/integrations/fields`, {
        params: {
          companyId: companyId
        }
      });
  }
  runIntegration(model: RunIntegrationModel): Observable<any> {
    return this._http.post<any>(
      `${this._config.apiUrl}/api/integrations/tasks/run`,model);
  }

  testConfig(model: CreateIntegration): Observable<IntegrationValidationResult> {
    return this._http
      .post<IntegrationValidationResult>(`${this._config.apiUrl}/api/integrations/test`, model);
  }
}

export class MockIntegrationsService implements Interface<IntegrationsService> {
  getByCompany(companyId: number): Observable<CompanyIntegration[]> {
    return observableOf([]);
  }
  getForEdit(id: number) : Observable<EditIntegration> {
    return observableOf();
  }
  update(model: EditIntegration) : Observable<void> {
    return observableOf();
  }
  create(model: CreateIntegration) : Observable<void> {
    return observableOf();
  }
  deleteIntegration(id: number): Observable<void> {
    return observableOf();
  }
  getFields(): Observable<IntegrationFields> {
    return observableOf();
  }
  runIntegration(model: RunIntegrationModel): Observable<any> {
    return observableOf();
  }
  testConfig(model: CreateIntegration): Observable<IntegrationValidationResult> {
    return observableOf();
  }
}
