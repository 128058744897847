import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'removePtag' })
export class RemovePtagPipe implements PipeTransform {
  transform(input: string): any {
    if (!input) {
      return input;
    }
    if (input != null) {
      input = input.trim();
      if (input.startsWith('<p>')) {
        input = input.slice(3);
      }
      if (input.endsWith('</p>')) {
        input = input.slice(0, input.length - 4);
      }
    }
    return input;
  }
}
