import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';

import { ConfigurationService } from 'app/shared/services/configuration.service';
import { Interface } from 'app/helpers/interface';

@Injectable({
  providedIn: 'root'
})
export class AuthCredentialsService {
  constructor(private _http: HttpClient, private _config: ConfigurationService) {
  }

  find(email: string): Observable<Credential> {
    return this._http.get<Credential>(`${this._config.configuration.AuthUrl}/api/credentials/find`, { params: { email: email } });
  }

  update(credential: Credential): Observable<void> {
    return this._http.put<void>(`${this._config.configuration.AuthUrl}/api/credentials`, credential);
  }


}

export class MockAuthCredentialsService implements Interface<AuthCredentialsService> {
  find(email: string): Observable<Credential> {
    return observableOf();
  }
  update(credential: Credential): Observable<void> {
    return observableOf();
  }
}

export interface Credential {
  id: string;
  email: string;
  companyAccounts: CompanyAccount[];
}

export interface CompanyAccount {
  insightUserId: string;
  isAdmin: boolean;
  companyId: number;
  companyName: string;
}
