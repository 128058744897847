import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ParameterDto, ParameterItem, UserState } from 'app/shared/models';
import { Observable, of } from 'rxjs';
import { ConfigurationService } from './configuration.service';

export interface IParameterService {
  list(companyId: number): Observable<ParameterDto[]>;

  create(companyId: number, model: ParameterDto[]): Observable<number>;

  loadUserState(companyId: number): Observable<UserState>;

  updateUserState(companyId: number, parameter: string, value: string): Observable<UserState>;

  updateUserStateMultiple(companyId: number, parameters: ParameterItem[]): Observable<UserState>;
}

@Injectable({
  providedIn: 'root'
})
export class ParameterService {
  constructor(private _http: HttpClient, private _config: ConfigurationService) {

  }

  list(companyId: number): Observable<ParameterDto[]> {
    return this._http
      .get<ParameterDto[]>(`${this._config.apiUrl}/api/companies/${companyId}/parameters`);
  }

  create(companyId: number, model: ParameterDto[]) {
    return this._http
      .post<number>(`${this._config.apiUrl}/api/companies/${companyId}/parameters`, model);
  }

  loadUserState(companyId: number): Observable<UserState> {
    return this._http
      .get<UserState>(`${this._config.apiUrl}/api/companies/${companyId}/user/parameters`);
  }

  updateUserState(companyId: number, parameter: string, value: string): Observable<UserState> {
    return this._http
      .put<UserState>(`${this._config.apiUrl}/api/companies/${companyId}/user/parameters`, {
        parameterKey: parameter,
        parameterValue: value
      });
  }

  updateUserStateMultiple(companyId: number, parameters: ParameterItem[]): Observable<UserState> {
    return this._http
      .put<UserState>(`${this._config.apiUrl}/api/companies/${companyId}/user/parameters/multiple`, { parameters: parameters });
  }
}

export class MockParameterService {

  list(companyId: number): Observable<ParameterDto[]> {
    return of(
      [{
        key: 'test',
        initialization: 'explicit',
        entityType: 'Group',
        expression: '0',
        allowedValues: '1',
        readonly: false,
        expiration: '1.00:00:00',
        description: 'parameter for testing purposes'
      }]
    );
  }

  create(companyId: number, model: ParameterDto[]): Observable<number> {
    return of(0);
  }

  loadUserState(companyId: number): Observable<UserState> {
    return of();
  }

  updateUserState(companyId: number, parameter: string, value: string): Observable<UserState> {
    return of();
  }

  updateUserStateMultiple(companyId: number, parameters: ParameterItem[]): Observable<UserState> {
    return of();
  }
}
