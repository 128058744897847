{
  "API_URL": "https://dev.insight.local:5500",
  "AUTH_URL": "https://dev.insight.local:44391",
  "LOCAL_URL": "https://dev.insight.local:4200",
  "BENCHMARK_API_URL": "https://dev.insight.local:5051",
  "CHROSS_API_URL_test": "http://localhost:5512",
  "CHROSS_API_URL": "https://tools.brilliantsurvey.se/ChrossWebAPI",
  "WP_API_URL": "https://brilliantfuture.se/wp-json/wp/v2",
  "BRILLIANTFUTURE_HP_BASE_URL": "https://brilliantfuture.se",
  "EX_HELPCENTER_BASE_URL": "https://help.brilliantinsights.se/hc",
  "CX_HELPCENTER_BASE_URL": "https://brilliantcx.zendesk.com/hc",
  "ApplicationInsights": {
    "InstrumentationKey": "644c5541-46e5-4df2-b247-568fab0b3811"
  },
  "EX_PENDO_API_KEY": "68f59d49-de49-4ad3-57ee-766a3f1489e0",
  "CX_PENDO_API_KEY": "0e142e30-6f13-4081-6480-97ed31e5b394",

  "BUILD_VERSION": "",
  "BUILD_BRANCH": "",
  "BUILD_NAME": "Local",
  "Environment": "local",
  "FeatureFlags": {
    "TestFeature": true,
    "TestFeatureDisabled": false,
    "24409-SurveyCopy": true
  }
}
