import { Injectable } from '@angular/core';
import { Interface } from 'app/helpers/interface';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

    constructor() {}
    newGuid(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          // eslint-disable-next-line no-bitwise
          const r = Math.random() * 16 | 0,
            // eslint-disable-next-line no-bitwise
            v = c === 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
      }

}

export class MockUtilsService implements Interface<UtilsService> {
    newGuid(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
    }
}
