import { Directive, Input } from '@angular/core';

let nextUniqueId = 0;

@Directive({
    selector: 'uikit-error',
    host: {
        'class': 'uikit-error',
        'role': 'alert',
        '[attr.id]': 'id',
    }
})
export class UIKitError {
    @Input() id = `uikit-error-${nextUniqueId++}`;
}
