import { CompanyContext } from 'app/shared/models/company';
import { Route } from '@angular/router';

export class RouteData {
  name?: string | ((companyContext: CompanyContext) => string);
  key?: string;
  translateKey?: string | ((companyContext: CompanyContext) => string);
  menuItem?: boolean | ((companyContext: CompanyContext) => boolean);
  adminLink?: boolean;
  permission?: string;
  icon?: string;
  childRoutes?: CustomRoute[];
  pendoId?: string | ((companyContext: CompanyContext) => string);
  readonly?: boolean;
  route?: string;
  breadcrumb?: boolean;
  extraData?: any;
}
export interface CustomRoute extends Route {
  children?: CustomRoute[];
  data?: RouteData;
}


export function getValue<T>(property: T | ((companyContext: CompanyContext) => T), companyContext: CompanyContext): T {
  if (property === undefined) return undefined;
  if (typeof property === 'function') {
    return (property as ((companyContext: CompanyContext) => T))(companyContext);
  }
  return property;
}
