export interface OrganizationRule {
    id: number;
    name: string;
    ruleType: string;
    parameters: string;
    baseGroupId: number;
    userCondition: string;
    active: boolean;
    order: number;
}

export interface EditOrganizationRule extends OrganizationRule {
    companyId: number;
}

export interface EditOrganizationRuleOrder {
    id: number;
    order: number;
}

export interface RunOrganizationRules {
    companyId: number;
    userIds: number[];
    ruleIds: number[];
}

export interface OrgRuleTask {
    id: number;
    status: TaskStatus;
    rules: string;
    createTime: Date;
    message: string;
}

export enum TaskStatus {
  notStarted = 'NotStarted',
  running = 'Running',
  completed = 'Completed',
  failed = 'Failed'
}
