import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigurationService } from 'app/shared';
import { AppState } from 'app/state/app.state';
import { Store } from '@ngrx/store';
import { DataSourceDto, GroupResultInfo } from 'app/shared/models';


export interface IDataSourceService {
  get(id: number): Observable<DataSourceDto>;
  list(companyId: number): Observable<DataSourceDto[]>;
  create(companyId: number, model: DataSourceDto[]): Observable<number>;
  getValueByName(companyId: number, dataSourceName: string): Observable<GroupResultInfo[]>;
}

@Injectable({
  providedIn: 'root'
})
export class DataSourceService {
  constructor(private _http: HttpClient, private _config: ConfigurationService, private _store: Store<AppState>) {

  }
  get(id: number): Observable<DataSourceDto> {
    return this._http
      .get<DataSourceDto>(`${this._config.apiUrl}/api/datasources/${id}`);
  }
  list(companyId: number): Observable<DataSourceDto[]> {
    return this._http
      .get<DataSourceDto[]>(`${this._config.apiUrl}/api/datasources/${companyId}/list`);
  }
  create(companyId: number, model: DataSourceDto[]) {
    return this._http.post<number>(`${this._config.apiUrl}/api/datasources/${companyId}/create`, model);
  }
  getValueByName(companyId: number, dataSourceName: string): Observable<GroupResultInfo[]> {
    return this._http.get<GroupResultInfo[]>(`${this._config.apiUrl}/api/datasources/${companyId}/get-value/${dataSourceName}`);
  }
}
