import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {ContentItemDetailed, Language} from 'app/shared/models';
import { ConfigurationService } from 'app/shared/services/configuration.service';
import { Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

export const AVAILABLE_PORTAL_ADMIN_LANGUAGES: Language[] = [{ id: 1, code: 'en' }, { id: 2, code: 'sv' }];

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private _languages: Observable<Language[]>;

  constructor(
    private _http: HttpClient,
    private _config: ConfigurationService
  ) { }

  getLanguages(): Observable<Language[]> {
    return this._http
      .get<Language[]>(`${this._config.apiUrl}/api/languages`);
  }

  getAll(): Observable<Language[]> {
    if (!this._languages) {
      this._languages = this.getLanguages()
        .pipe(shareReplay(1));
    }
    return this._languages;
  }
  getCompanyLanguages(companyId: number) {
    return this._http
      .get<Language[]>(
        `${this._config.apiUrl}/api/languages/company/${companyId}`)
      .pipe(shareReplay(1));
  }

  getPortalLanguages(companyId: number) {
    return this._http
      .get<Language[]>(
        `${this._config.apiUrl}/api/languages/company/${companyId}/portaltranslations`)
      .pipe(shareReplay(1));
  }

  updateCompanyLanguages(
    companyId: number,
    languageIds: number[]
  ): Observable<HttpResponse<string>> {
    return this._http.put(
      `${this._config.apiUrl}/api/languages/company/${companyId}`,
      { languageIds },
      { observe: 'response', responseType: 'text' }
    );
  }

  getCompanyDefaultLanguage(companyId: number): Observable<Language> {
    return this._http.get<Language>(
      `${this._config.apiUrl}/api/companies/${companyId}/language`
    ).pipe(shareReplay(1));
  }

  getPortalAdminDefaultLanguageFromUserLanguage(userLanguageId: number, companyId: number): Observable<Language> {
    const match = AVAILABLE_PORTAL_ADMIN_LANGUAGES.find(language => language.id === userLanguageId);
    return !!match
      ? of(match)
      : this.getCompanyDefaultLanguage(companyId).pipe(
        map(companyDefaultLanguage => AVAILABLE_PORTAL_ADMIN_LANGUAGES.find(lang => lang.id === companyDefaultLanguage.id) ?? AVAILABLE_PORTAL_ADMIN_LANGUAGES[0])
      );
  }

  updateCompanyDefaultLanguage(
    companyId: number,
    languageId: number
  ): Observable<HttpResponse<string>> {
    return this._http.put(
      `${this._config.apiUrl}/api/companies/${companyId}/language`,
      { languageId },
      { observe: 'response', responseType: 'text' }
    );
  }

  translateText(companyId: number, languageId: number, text: string): Observable<ContentItemDetailed[]>  {
    return this._http.post<ContentItemDetailed[]>(
      `${this._config.apiUrl}/api/languages/companies/${companyId}/translate`, {
        languageId: languageId,
        text: text
      });
  }
}
