import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { ErrorsService } from 'app/shared/services';
import { ApplicationInsightService } from './application-insights.service';


// https://medium.com/@aleixsuau/error-handling-angular-859d529fa53a
@Injectable()
export class ErrorsHandler implements ErrorHandler {
  constructor(
    private injector: Injector,
    private zone: NgZone,
    private applicationInsights: ApplicationInsightService
  ) { }
  handleError(error: Error | HttpErrorResponse) {
    if (error instanceof HttpErrorResponse) {
      const errorsService = this.injector.get(ErrorsService);
      // Server or connection error happened
      if (!navigator.onLine) {
        // Handle offline error
        this.zone.run(() => { // https://github.com/angular/material2/issues/9875
          errorsService.openErrorPopup('No Internet Connection');
        });
        return;
      } else {
        // Http Error
        // Send the error to the server
        errorsService.log(error).subscribe();
        // Show notification to the user
        this.zone.run(() => { // https://github.com/angular/material2/issues/9875
          errorsService.openErrorPopup(error);
        });
        return;
      }
    } else {
      this.applicationInsights.logError(error);
    }
    console.error('It happens: ', error);
  }
}
