import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'app/shared/services';

@Component({
    selector: 'app-signin-callback',
    templateUrl: './signin-callback.component.html'
})
export class SigninCallbackComponent implements OnInit {
    constructor(private authService: AuthenticationService) {
        authService.endSigninMainWindow();
    }

    ngOnInit() {

    }
}
