import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CompanyParameters } from 'app/shared/models';
import { DataSourceService } from 'app/shared/services/data-source.service';
import { AppState } from 'app/state/app.state';
import { Observable } from 'rxjs';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserParameterService {
  constructor(
    private store: Store<AppState>,
    private dataSourceService: DataSourceService) { }

  getValueFromDataSource(dataSource: string): Observable<any[]> {
    return this.store
      .select((state) => state.companyContext.id)
      .pipe(
        take(1),
        switchMap((companyId) =>
          this.dataSourceService.getValueByName(companyId, dataSource)
        ), shareReplay()
      );
  }

  getValueFromParameter(parameter: string): Observable<string[]> {
    return this.store.select((state) => state.userState)
      .pipe(
        map((userState) => {
          if (userState?.companyParameters) {
            const params = userState.companyParameters.find(cp => cp.companyId === userState.currentCompanyId);
            return this.getParameterValue(parameter, params);
          } else {
            return [];
          }
        })
      );
  }

  private getParameterValue(parameterName: string, companyParameters: CompanyParameters): string[] {
    const param = companyParameters.parameters.find((x) => x.name === parameterName);
    return param != null && param.value?.length ? param.value.split(',') : [];
  }

}
