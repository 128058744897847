import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIKitIcon } from './icon.component';


@NgModule({
    declarations: [UIKitIcon],
    imports: [CommonModule],
    exports: [UIKitIcon]
})
export class UIKitIconModule { }
