import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OrganizationRule, EditOrganizationRule, EditOrganizationRuleOrder, RunOrganizationRules, OrgRuleTask } from 'app/shared/models';
import { Observable } from 'rxjs';

import { ConfigurationService } from 'app/shared';

@Injectable({
    providedIn: 'root'
})
export class OrganizationRuleService {

    constructor(private _http: HttpClient, private _config: ConfigurationService) {
    }
    getByCompany(companyId: number): Observable<OrganizationRule[]> {
        return this._http.get<OrganizationRule[]>(`${this._config.apiUrl}/api/companies/${companyId}/organizationrules`);
    }
    getTaskStatus(taskId: number): Observable<string> {
        return this._http.get<string>(`${this._config.apiUrl}/api/tasks/${taskId}/status`);
    }
    create(model: EditOrganizationRule): Observable<number> {
        return this._http.post<number>(`${this._config.apiUrl}/api/organizationrules`, model);
    }
    update(model: EditOrganizationRule): Observable<void> {
        return this._http.put<void>(`${this._config.apiUrl}/api/organizationrules/${model.id}`,
            model);
    }
    delete(id: number): Observable<void> {
        return this._http.delete<void>(`${this._config.apiUrl}/api/organizationrules/${id}`);
    }
    updateOrder(model: EditOrganizationRuleOrder[]): Observable<void> {
        return this._http.put<void>(`${this._config.apiUrl}/api/organizationrules/order`, model);
    }
    runRules(model: RunOrganizationRules): Observable<void> {
        return this._http.post<void>(`${this._config.apiUrl}/api/organizationrules/run`, model);
    }
    getLatestTasks(companyId: number): Observable<OrgRuleTask[]> {
        return this._http.get<OrgRuleTask[]>(`${this._config.apiUrl}/api/companies/${companyId}/ruletasks`);
    }
    generateAttributeBasedRule(companyId: number, groupCategoryId: number, attributeDefinitionIds: number[]): Observable<number> {
      return this._http.post<number>(`${this._config.apiUrl}/api/organizationrules/attributebased`, {
          companyId,
          groupCategoryId,
          attributeDefinitionIds: attributeDefinitionIds
      });
    }
    generateCreateBackgroundGroupRule(companyId: number, groupCategoryId: number): Observable<number> {
      return this._http.post<number>(`${this._config.apiUrl}/api/organizationrules/createbackgroundgroups`, {
          companyId,
          groupCategoryId
      });
    }
}
