import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

import { ConfigurationService } from 'app/shared/services/configuration.service';
import { AttributeType } from 'app/shared/models';

@Injectable({
    providedIn: 'root'
})
export class AttributeTypeService {
    private _attributeTypes: Observable<AttributeType[]>;
    constructor(private _http: HttpClient, private _config: ConfigurationService) {
    }
    list(): Observable<AttributeType[]> {
        if (!this._attributeTypes) {
            this._attributeTypes = this._http
                .get<AttributeType[]>(`${this._config.apiUrl}/api/attributetypes`).pipe(
                publishReplay(1),
                refCount());
        }
        return this._attributeTypes;
    }
}
