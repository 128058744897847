import { Injectable } from '@angular/core';
import { LocalAccountContext, LocalCompany } from 'app/admin-tpl/company-list/local-company.model';
import { AccountContext } from '../models';
@Injectable({
  providedIn: 'root'
})
export class FavoriteCompanyService {
  private storageKey = 'favorite-companies-v2';

  getFavorites(): LocalCompany[] {
    return localStorage.getItem(this.storageKey)
      ? JSON.parse(localStorage.getItem(this.storageKey)) as LocalCompany[]
      : [];
  }

  upsertFavorites(company: LocalCompany, favorite: boolean): LocalCompany[] {
    const favorites: LocalCompany[] = localStorage.getItem(this.storageKey)
      ? JSON.parse(localStorage.getItem(this.storageKey)) as LocalCompany[]
      : [];

    favorite ? favorites.push(company) : favorites.removeEvery(f => f.id === company.id);
    localStorage.setItem(this.storageKey, JSON.stringify(favorites));
    return favorites;
  }

  updateFavorites(companies: LocalCompany[]): LocalCompany[] {
    localStorage.setItem(this.storageKey, JSON.stringify(companies));
    return companies;
  }

  sortAccountsByFavourites(accounts: AccountContext[]): LocalAccountContext[] {
    const favorites = this.getFavorites();
    const favIds = favorites.map((f: LocalCompany) => f.id);
    const localCompanies = accounts.map((item) => ({
      ...item,
      favourite: favIds.some(f => item.companies.some(c => c.id === f))
    }));
    localCompanies.sort(this.accountSortFunction);
    return localCompanies;
  }

  accountSortFunction = (a: LocalAccountContext, b: LocalAccountContext) => (+b.favourite) - (+a.favourite) || a.name.localeCompare(b.name);

}
