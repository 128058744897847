import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Calculate,
  MigrateSettings,
  MigrateStats,
  GroupUserMatch,
  GenerateAnswerSettings,
  GroupWithSnapshot,
  ValidateImportAnswers,
  ImportAnswers,
  SurveyRawDataModel, ExportGeneratedAnswersModel
} from 'app/shared/models';
import { Observable } from 'rxjs';
import { ResultCalculationTask, ResultCalculationTaskMeta } from 'app/shared/models/result-pages/result-calculation-task.model';
import { ConfigurationService } from 'app/shared';
import { map } from 'rxjs/operators';
import { ResultMigrationTask } from 'app/shared/models/result-pages/result-migration-task.model';
import { CalculateResultModel } from 'app/shared/models/calculate-results.model';

@Injectable({
  providedIn: 'root'
})
export class ResultService {
  constructor(
    private _http: HttpClient,
    private _config: ConfigurationService
  ) { }

  listGroupsWithSnapshots(companyId: number, categoryFilter: string): Observable<GroupWithSnapshot[]> {
    const params = {
      companyId: companyId.toString(),
      'category': categoryFilter
    };
    return this._http.get<GroupWithSnapshot[]>(`${this._config.apiUrl}/api/results/groups`, { params });
  }

  calculateResults(calc: Calculate): Observable<void> {
    return this._http.post<void>(
      `${this._config.apiUrl}/api/results/tasks/calculate`,
      calc
    );
  }

  getCalculateResultsModel(surveyId?: number, snapshotId?: number): Observable<CalculateResultModel> {
    const params = {};
    surveyId
      ? params['surveyId'] = surveyId
      : params['snapshotId'] = snapshotId;

    return this._http.get<CalculateResultModel>(
      `${this._config.apiUrl}/api/results/calculate`, { params });
  }

  migrateData(settings: MigrateSettings): Observable<MigrateStats> {
    return this._http.post<MigrateStats>(
      `${this._config.apiUrl}/api/results/migrate`,
      settings
    );
  }

  matchByUsers(companyId: number, surveyId: number): Observable<GroupUserMatch[]> {
    return this._http
      .get<GroupUserMatch[]>(`${this._config.apiUrl}/api/companies/${companyId}/usermatch/${surveyId}`);
  }

  migrateDataTask(settings: MigrateSettings): Observable<ResultMigrationTask> {
    return this._http.post<ResultMigrationTask>(
      `${this._config.apiUrl}/api/results/tasks/migrate`,
      settings
    );
  }

  getCalculationTasks(companyId: number, surveyId?: number, snapshotId?: number): Observable<ResultCalculationTask[]> {
    const params = { taskType: 'calculation' };
    surveyId
      ? params['surveyId'] = surveyId
      : params['snapshotId'] = snapshotId;

    return this._http
      .get<ResultCalculationTask[]>(`${this._config.apiUrl}/api/companies/${companyId}/tasks`, { params })
      .pipe(
        map(tasks =>
          tasks.map(task => ({ ...task, meta: JSON.parse(task.meta as string) as ResultCalculationTaskMeta }))
        )
      );
  }
  getActivationTasks(companyId: number, surveyId?: number): Observable<ResultCalculationTask[]> {
    const params = { taskType: 'activation' };
    if (surveyId != null) {
      params['surveyId'] = surveyId.toString();
    }
    return this._http
      .get<ResultCalculationTask[]>(`${this._config.apiUrl}/api/companies/${companyId}/tasks`, { params })
      .pipe(
        map(tasks =>
          tasks.map(task => ({ ...task, meta: JSON.parse(task.meta as string) as ResultCalculationTaskMeta }))
        )
      );
  }
  getMigrationTasks(companyId: number): Observable<ResultMigrationTask[]> {
    return this._http
      .get<ResultMigrationTask[]>(
        `${this._config.apiUrl}/api/companies/${companyId}/tasks`,
        { params: { taskType: 'migration' } }
      )
      .pipe(
        map(tasks =>
          tasks.map(task => ({ ...task, meta: JSON.parse(task.meta) }))
        )
      );
  }

  getTasks(companyId: number): Observable<ResultCalculationTask[]> {
    return this._http
      .get<ResultCalculationTask[]>(
        `${this._config.apiUrl}/api/companies/${companyId}/tasks`,
        { params: { taskType: 'migration' } }
      )
      .pipe(
        map(tasks =>
          tasks.map(task => ({ ...task, meta: JSON.parse(task.meta as string) as ResultCalculationTaskMeta }))
        )
      );
  }
  requeueTask(companyId: number, taskId: number): Observable<void> {
    return this._http.put<void>(`${this._config.apiUrl}/api/companies/${companyId}/tasks/${taskId}`, null);
  }
  clearAnswer(surveyId: number, userIds: number[]): Observable<void> {
    return this._http
      .post<void>(`${this._config.apiUrl}/api/results/surveys/${surveyId}/reset`, userIds);
  }

  generateTestAnswers(surveyId: number, model: GenerateAnswerSettings): Observable<void | ExportGeneratedAnswersModel> {
    return this._http
      .post<void | ExportGeneratedAnswersModel>(`${this._config.apiUrl}/api/results/surveys/${surveyId}/answers`, model);
  }

  getRawdata(surveyId: number): Observable<SurveyRawDataModel> {
    return this._http
      .get<SurveyRawDataModel>(`${this._config.apiUrl}/api/surveys/${surveyId}/rawdata`);
  }

  getRawdataForGroupResult(snapshotId: number, groupId: number): Observable<SurveyRawDataModel> {
    return this._http
      .get<SurveyRawDataModel>(`${this._config.apiUrl}/api/result/${snapshotId}/${groupId}/rawdata`);
  }

  connectHistory(toGroupId: number, fromGroupId: number) {
    return this._http.
      put<void>(`${this._config.apiUrl}/api/results/groups/${toGroupId}/connectHistory`, fromGroupId);
  }

  validateAnswerImport(model: ValidateImportAnswers): Observable<ImportAnswers> {
    return this._http.
      post<ImportAnswers>(`${this._config.apiUrl}/api/surveys/${model.surveyId}/answers/validate`, model);
  }

  importAnswers(model: ImportAnswers): Observable<void> {
    return this._http.
      post<void>(`${this._config.apiUrl}/api/surveys/${model.surveyId}/answers`, model);
  }
}
