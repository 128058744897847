import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateSnapshot, EditSnapshot, Snapshot } from 'app/shared/models';
import { Observable } from 'rxjs';
import { ConfigurationService } from 'app/shared';

@Injectable({
  providedIn: 'root'
})
export class SnapshotService {
  constructor(private _http: HttpClient, private _config: ConfigurationService) { }

  getByCompanyAndSurvey(companyId: number, surveyId: number): Observable<Snapshot[]> {
    return this._http
      .get<Snapshot[]>(`${this._config.apiUrl}/api/snapshots/companies/${companyId}/surveys/${surveyId}`);
  }

  getByCompany(companyId: number): Observable<Snapshot[]> {
    return this._http
      .get<Snapshot[]>(`${this._config.apiUrl}/api/snapshots/companies/${companyId}`);
  }
  getByCompanyAndAllowedGroups(companyId: number, groupsParam: string, includeLiveData = false): Observable<Snapshot[]> {
    return this._http
      .get<Snapshot[]>(`${this._config.apiUrl}/api/snapshots/companies/${companyId}/${groupsParam}/allowed`, {
        params: {
          includeLiveData: includeLiveData
        }
      });
  }

  getForEdit(companyId: number, snapshotId: number): Observable<EditSnapshot> {
    return this._http
      .get<EditSnapshot>(`${this._config.apiUrl}/api/snapshots/${snapshotId}/edit/companies/${companyId}`);
  }
  update(model: EditSnapshot): Observable<void> {
    return this._http.put<void>(`${this._config.apiUrl}/api/snapshots/${model.id}`, model);
  }
  create(model: CreateSnapshot): Observable<Snapshot> {
    return this._http.post<Snapshot>(`${this._config.apiUrl}/api/snapshots/${model.companyId}/create`, model);
  }
  getVisibilityTranslationKey(visibility: string): string {
    switch (visibility) {
      case 'Hidden':
        return 'INSIGHT.ADMIN.HIDDEN';
      case 'Visible':
        return 'INSIGHT.ADMIN.VISIBLE';
      case 'Preview':
        return 'INSIGHT.ADMIN.PRODUCTWIZARD.COMMUNICATION_PREVIEW';
      case 'EarlyAccess':
        return 'INSIGHT.ADMIN.EARLY_ACCESS';
      case 'LateAccess':
        return 'INSIGHT.ADMIN.LATE_ACCESS';

      default:
        return visibility;
    }
  }
}

