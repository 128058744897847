import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { NeedsToUnsubscribe } from 'app/shared/core';

@Component({
    selector: 'app-link-logon',
    templateUrl: './link-logon.component.html'
})
export class LinkLogonComponent extends NeedsToUnsubscribe implements OnInit {
    constructor(private route: ActivatedRoute) { super(); }

    ngOnInit() {
        this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe(params => {
            const token: string = encodeURIComponent(params['token']);
            window.location.href = `/survey/${token}`;
        });
    }
}

