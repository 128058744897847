[
  {
    "name": "My groups",
    "description": "All groups for the current user",
    "take": 1,
    "items": [
      {
        "parameterKeys": ["myGroups"],
        "type": "Group"
      }
    ]
  },
  {
    "name": "Current results",
    "description": "Latest results for the selected group",
    "take": 5,
    "items": [
      {
        "parameterKeys": ["currentGroup", "allCurrentSnapshots"],
        "type": "GroupResult"
      }
    ]
  },
  {
    "name": "Selected results",
    "description": "Selected results for the selected group",
    "take": 5,
    "items": [
      {
        "parameterKeys": ["currentGroup", "latestXSnapshots"],
        "type": "GroupResult"
      }
    ]
  },
  {
    "name": "Select result",
    "description": "Selected result for the selected group",
    "take": 1,
    "items": [
      {
        "parameterKeys": [ "currentGroup", "singleSelectedSnapshot" ],
        "type": "GroupResult"
      }
    ]
  },
  {
    "name": "Group comparisons",
    "description": "Results for the current group's parentgroups. Normally we only take 1 meaning that we only display the latest snapshot",
    "take": 1,
    "items": [
      {
        "parameterKeys": ["currentParentGroups", "currentSnapshotForGroup"],
        "type": "GroupResult"
      },
      {
        "parameterKeys": ["benchmark"],
        "type": "GroupResult"
      }
    ]
  },
  {
    "name": "Selected group comparisons",
    "description": "Automatic result comparisons for the selected group in the selected snapshot",
    "take": 1,
    "items": [
      {
        "parameterKeys": ["currentParentGroups", "latestXSnapshots"],
        "type": "GroupResult"
      },
      {
        "parameterKeys": ["benchmark"],
        "type": "GroupResult"
      }
    ]
  },
  {
    "name": "All my groups",
    "description": "All available comparison groups for the current user",
    "take": 1,
    "items": [
      {
        "type": "Group",
        "parameterKeys": ["comparisonGroups"]
      }
    ]
  },
  {
    "name": "Selected comparison results",
    "description": "Results for the selected groups in the selected snapshots. Selectable by the user.",
    "take": 10,
    "items": [
      {
        "type": "GroupResult",
        "parameterKeys": ["selectedComparisonGroups", "selectedSnapshots"]
      }
    ]
  },
  {
    "name": "Selected actionplan groups",
    "description": "Currently selected groups in the actionplan explore views",
    "take": 1,
    "items": [
      {
        "type": "Group",
        "parameterKeys": ["selectedActionplanGroups"]
      }
    ]
  },
  {
    "name": "Current summation group children",
    "description": "Current summation group and its children",
    "take": 1,
    "items": [
      {
        "type": "Group",
        "parameterKeys": ["currentSummationGroupChildren"]
      }
    ]
  },
  {
    "name": "Selected actionplans",
    "description": "Contains actionplans for all selected groups and snapshots within the timeframe in the actionplan explore view",
    "take": 1,
    "items": [
      {
        "type": "Product",
        "parameterKeys": ["selectedActionplanGroups", "selectedActionplanSnapshots", "actionplanExploreTimeFrame"]
      }
    ]
  },
  {
    "name": "Current group products",
    "description": "Products for the current group",
    "take": 1,
    "items": [
      {
        "type": "Product",
        "parameterKeys": ["currentGroup"]
      }
    ]
  },
  {
    "name": "Current summation group children products",
    "description": "Products for the current summation group's children within the time frame in the actionplan overview",
    "take": 1,
    "items": [
      {
        "type": "Product",
        "parameterKeys": ["currentSummationGroupChildren", "actionplanOverviewTimeFrame"]
      }
    ]
  },
  {
    "name": "All Benchmarks",
    "description": "Results for all becnhmark groups defined for the company (via the allBenchmark parameter)",
    "take": 10,
    "items": [
      {
        "parameterKeys": ["allBenchmarks"],
        "type": "GroupResult"
      }
    ]
  },
  {
    "name": "Selected benchmarks",
    "description": "Results for selected benchmark groups",
    "take": 10,
    "items": [
      {
        "parameterKeys": ["selectedBenchmarks"],
        "type": "GroupResult"
      }
    ]
  },
  {
    "name": "Index list comparison groups",
    "description": "Results for index list (uses all snapshots now)",
    "take": 10,
    "items": [
      {
        "parameterKeys": ["selectedComparisonGroups", "allCurrentSnapshots"],
        "type": "GroupResult"
      }
    ]
  },
  {
    "name": "Customer indexes",
    "description": "List of customer specific indexes",
    "take": 10,
    "items": [
      {
        "type": "Index",
        "parameterKeys": ["currentCompany"]
      }
    ]
  },
  {
    "name": "Current summation group children result",
    "description": "Results for selected summation group's children",
    "take": 1,
    "items": [
      {
        "parameterKeys": ["currentSummationGroupChildren", "actionplanOverviewTimeFrame"],
        "type": "GroupResult"
      }
    ]
  }
]
