export interface RuleTarget {
  id: number;
  targetType: string;
  targetTypeId: number;
  targetId: number;
  targetKey: string;
}
export enum RuleTargetType {
  Page = 'Page',
  Question = 'Question',
  QuestionField = 'QuestionField',
  QuestionOption = 'QuestionOption',
  Content = 'Content',
  PageGroup = 'PageGroup'
}
