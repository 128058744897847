import {Location} from '@angular/common';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {AuthenticationService} from 'app/shared/services';
import {empty as observableEmpty, Observable, Subject, throwError as observableThrowError} from 'rxjs';
import {catchError, switchMap, tap} from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private authService: AuthenticationService;
    refreshTokenInProgress = false;

    tokenRefreshedSource = new Subject();
    tokenRefreshed$ = this.tokenRefreshedSource.asObservable();
    constructor(private location: Location, private injector: Injector) {
    }
    addAuthHeader(request: HttpRequest<any>) {
        const authHeader = this.getToken();
        if (authHeader && 
            (request.url.includes(this.authService.apiUrl) || 
            request.url.includes(this.authService.authApiUrl) ||
            (request.url.includes(this.authService.bmApiUrl) && !!this.authService.bmApiUrl))
            ) {
            return request.clone({
                setHeaders: {
                    'Authorization': authHeader,
                    'Cache-Control': 'no-cache', // for IE not to cache
                    'Pragma': 'no-cache'
                }
            });
        }
        return request;
    }
    // https://stackoverflow.com/questions/45202208/angular-4-interceptor-retry-requests-after-token-refresh
    refreshToken() {
        if (this.refreshTokenInProgress) {
            return new Observable(observer => {
                this.tokenRefreshed$.subscribe(() => {
                    observer.next();
                    observer.complete();
                });
            });
        } else {
            this.refreshTokenInProgress = true;
            return this.authService.refreshToken().pipe(
               tap(() => {
                    this.refreshTokenInProgress = false;
                    this.tokenRefreshedSource.next(null);
                }));
        }
    }
    logout() {
        this.authService.startSignoutMainWindow();
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.toLowerCase().endsWith('appsettings.json')) {
            return next.handle(request);
        }

        if (this.authService == null) {
            // Resolve this.authService from injector
            this.authService = this.injector.get(AuthenticationService);
        }
        request = this.addAuthHeader(request);

        return next.handle(request).pipe(catchError(error => {

            if (error.status === 401) {
                return this.refreshToken().pipe(
                    switchMap(() => {
                        request = this.addAuthHeader(request);
                        return next.handle(request);
                    }),
                    catchError(() => {
                        this.logout();
                        return observableEmpty();
                    }));
            }

            return observableThrowError(error);
        }));
    }
    getToken() {
        return this.authService.loggedIn ? `${this.authService.currentUser.token_type} ${this.authService.currentUser.access_token}` : '';
    }
}
