import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Interface } from 'app/helpers/interface';
import { Observable, of } from 'rxjs';
import { ConfigurationService } from './configuration.service';
import { ActComment, Acted, CreateAct, CreateActComment, UpdateAct, UpdateActComment } from '../models/result-pages/act.model';

@Injectable({
  providedIn: 'root'
})
export class ActService {

  constructor(private _http: HttpClient, private _config: ConfigurationService) {
  }

  getAllComments(surveyId: number, userId: number): Observable<ActComment[]> {
    return this._http.post<ActComment[]>(`${this._config.apiUrl}/api/act/${userId}/comments`, surveyId);
  }

  createNewComment(model: CreateActComment): Observable<ActComment> {
    return this._http.post<ActComment>(`${this._config.apiUrl}/api/act/${model.surveyId}/${model.userId}/comments`, model);
  }

  updateComment(model: UpdateActComment): Observable<ActComment> {
    return this._http.put<ActComment>(`${this._config.apiUrl}/api/act/${model.surveyId}/${model.userId}/comments`, model);
  }

  deleteComment(surveyId: number, userId: number, id: number): Observable<ActComment> {
    return this._http.delete<ActComment>(`${this._config.apiUrl}/api/act/${surveyId}/${userId}/comments/${id}`);
  }

  createAct(model: CreateAct): Observable<Acted> {
    return this._http.post<Acted>(`${this._config.apiUrl}/api/act/${model.surveyId}/${model.userId}/acts`, model);
  }

  updateAct(model: UpdateAct): Observable<Acted> {
    return this._http.put<Acted>(`${this._config.apiUrl}/api/act/${model.surveyId}/${model.userId}/acts`, model);
  }

  getAct(surveyId: number, userId: number, id: number): Observable<Acted> {
    return this._http.get<Acted>(`${this._config.apiUrl}/api/act/${surveyId}/${userId}/acts/${id}`);
  }
  
}

export class MockActService implements Interface<ActService> {

  getAllComments(surveyId: number, userId: number): Observable<ActComment[]> {
    return of();
  }

  createNewComment(model: CreateActComment): Observable<ActComment> {
    return of();
  }

  updateComment(model: UpdateActComment): Observable<ActComment> {
    return of();
  }

  deleteComment(surveyId: number, userId: number, id: number): Observable<ActComment> {
    return of();
  }

  createAct(model: CreateAct): Observable<Acted> {
    return of();
  }

  updateAct(model: UpdateAct): Observable<Acted> {
    return of();
  }

  getAct(surveyId: number, userId: number, id: number): Observable<Acted> {
    return of();
  }
}
