import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  MappingDefinition,
  EditMappingDefinition,
  CompanyContext,
  MappingDefinitionAttribute, ImportSettings
} from 'app/shared/models';
import { ConfigurationService } from 'app/shared';
import { Store } from '@ngrx/store';
import { AppState } from 'app/state/app.state';
import { filter, map, switchMap, take } from 'rxjs/operators';
export interface IMappingDefinitionService {
  get(): Observable<MappingDefinition[]>;
  create(model: EditMappingDefinition): Observable<number>;
  update(userId: number, model: EditMappingDefinition): Observable<void>;
  delete(mappingDefinitionId: number): Observable<void>;
  getCompanyAttributes(): Observable<MappingDefinitionAttribute[]>;
}

@Injectable({
  providedIn: 'root'
})
export class MappingDefinitionService {
  private currentCompany$: Observable<CompanyContext>;
  private currentCompanyId$: Observable<number>;
  private baseUrl: string;
  constructor(
    private _http: HttpClient,
    private _config: ConfigurationService,
    private store: Store<AppState>
  ) {
    this.currentCompany$ = this.store
      .select(s => s.companyContext)
      .pipe(filter(({ id: companyId }) => companyId !== null));
    this.currentCompanyId$ = this.currentCompany$.pipe(
      map(({ id: companyId }) => companyId)
    );
    this.baseUrl = `${this._config.apiUrl}/api/mappingdefinitions/`;
  }
  get(): Observable<MappingDefinition[]> {
    return this.currentCompanyId$.pipe(
      switchMap(companyId =>
        this._http.get<MappingDefinition[]>(
          `${this._config.apiUrl
          }/api/companies/${companyId}/users/import/mapping`
        )
      ),
      take(1)
    );
  }
  create(model: EditMappingDefinition): Observable<number> {
    return this.currentCompanyId$.pipe(
      take(1),
      map(
        companyId =>
        ({
          ...model,
          companyId
        } as EditMappingDefinition)
      ),
      switchMap(mappingDefinition => {
        return this._http.post<number>(`${this.baseUrl}`, mappingDefinition);
      })
    );
  }

  update(id: number, model: EditMappingDefinition): Observable<void> {
    return this._http.put<void>(`${this.baseUrl}${id}`, model);
  }

  delete(mappingDefinitionId: number) {
    return this._http.delete(`${this.baseUrl}${mappingDefinitionId}`);
  }

  copy(mappingDefinitionId: number) {
    return this._http.post(`${this.baseUrl}copy`, mappingDefinitionId);
  }
  getCompanyAttributes(): Observable<MappingDefinitionAttribute[]> {
    return this.currentCompanyId$.pipe(
      take(1),
      switchMap(companyId => this._http.get<MappingDefinitionAttribute[]>(`${this._config.apiUrl}/api/companies/${companyId}/mapping/attributes`))
    );
  }

  getSettings(mapping: MappingDefinition): ImportSettings {
    if (mapping.userImportMap) {
      return mapping.userImportMap.settings;
    } else if (mapping.relationImportMap) {
      return mapping.relationImportMap.settings;
    }
  }

  getAddedColumns(settings: ImportSettings) {
    return settings ? settings.modifications?.filter(m => m.type === 'AddColumn').map(m => m.columnName) : [];
  }
}
