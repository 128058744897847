import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateSurveySessionsResult, GroupSession, GroupSessionCreate, SessionCreate, UserSession, UserSessionCreate, } from 'app/shared/models';
import { ConfigurationService } from 'app/shared/services/configuration.service';
import { Observable } from 'rxjs';
import { SurveyUser } from 'app/shared/models/user/survey-user.model';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  constructor(private _http: HttpClient, private _config: ConfigurationService) {
  }

  createSurveySessions(companyId: number, surveyId: number, model: Pick<SessionCreate, 'groupIds'>):
    Observable<CreateSurveySessionsResult> {
    return this._http
      .post<CreateSurveySessionsResult>(`${this._config.apiUrl}/api/companies/${companyId}/surveys/${surveyId}/sessions`, model);
  }

  createGroupSessions(model: GroupSessionCreate[]): Observable<HttpResponse<any>> {
    return this._http
      .post(`${this._config.apiUrl}/api/sessions/group`, { sessions: model },
        { observe: 'response', responseType: 'text' });
  }

  createUserSessions(model: UserSessionCreate): Observable<HttpResponse<any>> {
    return this._http
      .post(`${this._config.apiUrl}/api/sessions/user`, model,
        { observe: 'response', responseType: 'text' });
  }

  removeSession(id: number): Observable<boolean> {
    return this._http
      .delete<boolean>(`${this._config.apiUrl}/api/sessions/${id}`);
  }

  getTokens(userId: number, surveyId?: number): Observable<string[]> {
    const s = surveyId != null ? surveyId.toString() : '';
    return this._http
      .get<string[]>(`${this._config.apiUrl}/api/users/${userId}/sessiontokens`, { params: { 'surveyId': s } });
  }

  getSurveyUsersToken(surveyId?: number): Observable<SurveyUser[]> {
    const s = surveyId != null ? surveyId.toString() : '';
    return this._http
      .get<SurveyUser[]>(`${this._config.apiUrl}/api/users/${surveyId}/usersinsurvey`, { params: { 'surveyId': s } });
  }

  getGroupSessions(groupId: number): Observable<GroupSession[]> {
    return this._http
      .get<GroupSession[]>(`${this._config.apiUrl}/api/sessions/group/${groupId}`);
  }

  getGroupSessionsIndirect(groupId: number): Observable<GroupSession[]> {
    return this._http
      .get<GroupSession[]>(`${this._config.apiUrl}/api/sessions/group/${groupId}/indirect`);
  }

  getUserSessions(userId: number): Observable<UserSession[]> {
    return this._http
      .get<UserSession[]>(`${this._config.apiUrl}/api/sessions/user/${userId}`);
  }

  getRoleSessions(roleId: number): Observable<GroupSession[]> {
    return this._http
      .get<GroupSession[]>(`${this._config.apiUrl}/api/sessions/role/${roleId}`);
  }

  getQuestions(companyId: number, surveyId: number): Observable<UserSession[]> {
    return this._http
      .get<UserSession[]>(`${this._config.apiUrl}/api/companies/${companyId}/surveys/${surveyId}/questions`);
  }
}
