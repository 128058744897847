import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from 'app/shared';
import { EditSms, Sms, SmsList, SmsLog } from 'app/shared/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SmsService {
  constructor(private _http: HttpClient, private _config: ConfigurationService) {
  }
  delete(smsId: number): Observable<HttpResponse<Object>> {
    return this._http.delete(`${this._config.apiUrl}/api/sms/${smsId}`, { observe: 'response' });
  }


  get(id: number): Observable<Sms> {
    return this._http
      .get<Sms>(`${this._config.apiUrl}/api/sms/${id}`);
  }

  update(smsId: number, sms: EditSms): Observable<HttpResponse<any>> {
    return this._http.put(`${this._config.apiUrl}/api/sms/${smsId}`,
      sms, { observe: 'response', responseType: 'text' });
  }

  create(sms: EditSms): Observable<number> {
    return this._http.post<number>(`${this._config.apiUrl}/api/sms`, sms);
  }

  send(smsId: number, userId: number, data?: { surveyId?: number }): Observable<boolean> {
    let model: { userId: number, smsId: number, surveyId?: number };
    model = { userId: userId, smsId: smsId };
    if (data) {
      model = { ...data, ...model };
    }
    return this._http
      .post<boolean>(`${this._config.apiUrl}/api/sms/send`, model);
  }

  listWithDefaultSms(companyId: number): Observable<SmsList[]> {
    return this._http
      .get<SmsList[]>(`${this._config.apiUrl}/api/companies/${companyId}/sms`);
  }

  setIsActiveFlag(id: number, isActive: boolean, companyId: number): Observable<HttpResponse<Object>> {
    return this._http
      .put(`${this._config.apiUrl}/api/sms/${id}/active`, { isActive: isActive, companyId: companyId }, { observe: 'response', responseType: 'text' });
  }

  getFailedSms(taskId: number, companyId: number): Observable<SmsLog[]> {
    return this._http
      .get<SmsLog[]>(`${this._config.apiUrl}/api/companies/${companyId}/sms/failed`, { params: { 'taskId': taskId } });
  }
}
