<html>
<head>
  <style>
    html,
    body {
      height: 100%;
      background-color: #f2f2f2;
    }

    .loading {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      height: 100vh;
      width: 100%;
      justify-content: center;
      align-items: center;
    }

    @keyframes fadein {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

  </style>
</head>

<body>
  <div class="loading">
    <img width="180" src="/assets/images/brilliant-animated.gif" />
  </div>
</body>
</html>
