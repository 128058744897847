interface WP_Content {
  rendered: string;
  protected: boolean;
}

export interface WPPostFetchSettings {
  'categories[]'?: WPPostCategories[];
  'tags[]'?: WPPostTags[];
  'event_category[]'?: WPEventCategories[],
  'customer_training_category[]'?: WPCustomerTrainingCategories[],
  'orderby'?: string,
  per_page?: number;
  _embed?: boolean
  'include[]'?: number[];
  lang?: string;
}

export enum WPPostCategories {
  Uncategorized = 1,
  CX = 6,
  EX = 7,
  EmployerBranding = 8,
  Engagement = 9,
  Leadership = 10,
  ChangeManagement = 11,
  CustomerLoyalty = 12,
  Other = 42
}

export enum WPPostTags {

}
export enum WPEventCategories {
  EmployeeJourney = 75
}
export enum WPCustomerTrainingCategories {
  EmployeeRelations = 142,
  CustomerService = 143,
  CustomerRelations = 144
}
export enum WPPostTypes {
  Blog = 'posts',
  WhitePaper = 'whitepaper',
  Event = 'event',
  Video = 'video',
  CustomerTraining = 'customer_training'
}

export type WP_Media_Sizes = 'thumbnail' | 'medium' | 'large' | 'full' | 'monitor_medium';

export interface WP_MediaSpec {
  width: number;
  height: number;
  source_url: string;
}

export interface WP_MediaDetails {
  width: number;
  height: number;
  sizes: { [key in WP_Media_Sizes]: WP_MediaSpec };
}

export interface WP_Media {
  id: number;
  date: Date;
  date_gmt: Date;
  media_details: WP_MediaDetails;
  source_url: string;
}

export interface WP_Post {
  id: number;
  title: WP_Content;
  content: WP_Content;
  excerpt: WP_Content;
  date: Date;
  date_gmt: Date;
  link?: string;
  thumbUrl?: string;
  imageUrl?: string;
  _embedded: WP_Embedded;
}

export interface WP_Event_Meta {
  date: string;
  time: string;
  location: string;
  datetime?: Date;
}
export interface WP_Event extends WP_Post {
  passed: boolean;
  event_meta: WP_Event_Meta;
}
export interface WP_Embedded {
  'wp:featuredmedia': WP_Media[]
}
