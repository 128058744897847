[
  {
    "name": [
      {
        "language": "sv",
        "text": "Startsida"
      },
      {
        "language": "en",
        "text": "Startpage"
      }
    ],
    "key": "startpage",
    "enabled": true,
    "allowCache": true,
    "reportGroupSelectorSettings": null,
    "layout": null,
    "settings": null,
    "pageType": "Standard",
    "displayType": "None",
    "meta": [],
    "reportPageItems": []
  },
  {
    "key": "results",
    "name": [
      {
        "language": "sv",
        "text": "Resultat"
      },
      {
        "language": "en",
        "text": "Results"
      },
      {
        "language": "da",
        "text": "Resultater"
      },
      {
        "language": "no",
        "text": "Resultater"
      },
      {
        "language": "fi",
        "text": "Tulokset"
      },
      {
        "language": "de",
        "text": "Ergebnisse"
      },
      {
        "language": "it",
        "text": "Risultati"
      },
      {
        "language": "fr",
        "text": "Résultats"
      },
      {
        "language": "nl",
        "text": "Resultaten"
      },
      {
        "language": "pl",
        "text": "Wyniki"
      },
      {
        "language": "zh-cn",
        "text": "我的结果"
      },
      {
        "language": "ja",
        "text": "マイ結果"
      },
      {
        "language": "es",
        "text": "Resultados"
      },
      {
        "language": "pt",
        "text": "Resultados"
      },
      {
        "language": "pt-br",
        "text": "Resultados"
      },
      {
        "language": "et",
        "text": "Tulemused"
      },
      {
        "language": "el",
        "text": "Τα αποτελέσματά μου"
      }
    ],
    "meta": [
      {
        "key": "bar1",
        "value": "document"
      }
    ],
    "groupName": "MyPages",
    "displayType": "Sidebar",
    "condition": null,
    "reportGroupSelectorSettings": {
      "priorityHierarchies": [
        "teams"
      ],
      "isVisible": true
    },
    "children": [
      {
        "key": "overview",
        "name": [
          {
            "language": "sv",
            "text": "Översikt"
          },
          {
            "language": "en",
            "text": "Overview"
          },
          {
            "language": "et",
            "text": "Ülevaade"
          },
          {
            "language": "pt-br",
            "text": "Visão geral"
          },
          {
            "language": "pl",
            "text": "Przegląd"
          },
          {
            "language": "nl",
            "text": "Overzicht"
          },
          {
            "language": "fr",
            "text": "Vue d’ensemble"
          },
          {
            "language": "it",
            "text": "Panoramica"
          },
          {
            "language": "de",
            "text": "Übersicht"
          },
          {
            "language": "fi",
            "text": "Yleiskuvaus"
          },
          {
            "language": "no",
            "text": "Oversikt"
          },
          {
            "language": "da",
            "text": "Oversigt"
          },
          {
            "language": "zh-cn",
            "text": "概览"
          },
          {
            "language": "ja",
            "text": "概要"
          },
          {
            "language": "es",
            "text": "Resumen"
          },
          {
            "language": "pt",
            "text": "Visão geral"
          },
          {
            "language": "el",
            "text": "Επισκόπηση"
          }
        ],
        "meta": [
          {
            "key": "icon",
            "value": "buildings"
          }
        ],
        "reportGroupSelectorSettings": null,
        "displayType": "Tab",
        "children": [],
        "reportPageItems": [
          {
            "key": "FilterBar",
            "enabled": true,
            "order": 0,
            "reportPageItemType": "FilterBar",
            "dataSourceNames": [],
            "helpTextContentNames": [],
            "settings": {
              "filters": [
                {
                  "filterType": "SnapshotFilter",
                  "parameter": "singleSelectedSnapshot",
                  "filterSettings": {
                    "dependsOnGroupParameter": "currentGroup",
                    "version": "2.0"
                  }
                }
              ]
            }
          },
          {
            "key": "SummaryHeader",
            "enabled": true,
            "order": 1,
            "reportPageItemType": "ReportSummaryHeader",
            "condition": {
              "operator": "Neq",
              "leftSource": "Parameter",
              "leftField": "currentSnapshotForGroup",
              "rightSource": "Const",
              "rightValue": "0"
            },
            "dataSourceNames": [
              "Select result"
            ],
            "helpTextContentNames": [],
            "settings": {
              "questionsPageKey": "questionresults",
              "selectedSnapshotsParam": "selectedSnapshots",
              "reportDefinitionKey": "groupIndexReport"
            }
          },
          {
            "key": "SurveyInsight",
            "enabled": true,
            "order": 2,
            "reportPageItemType": "SurveyInsight",
            "condition": {
              "operator": null,
              "leftSource": "None",
              "rightSource": "None",
              "logic": "And",
              "conditions": [
                {
                  "operator": "Gte",
                  "leftSource": "GroupResult",
                  "leftField": "AnswerCount",
                  "leftDataSourceName": "Selected results",
                  "rightSource": "Const",
                  "rightValue": "20"
                },
                {
                  "operator": "Neq",
                  "leftSource": "Parameter",
                  "leftField": "currentGroupIsLeafNode",
                  "rightSource": "Const",
                  "rightValue": "1"
                }
              ]
            },
            "dataSourceNames": [
              "Selected results",
              "Selected benchmarks"
            ],
            "helpTextContentNames": [
              "SurveyInsight"
            ],
            "settings": {
              "showMotivation": true,
              "promptSetting": "SurveyInsight_EX_eNPS_CorrEnergy"
            }
          },
          {
            "key": "SurveyInsightTeam",
            "enabled": true,
            "order": 3,
            "reportPageItemType": "SurveyInsight",
            "condition": {
              "operator": null,
              "leftSource": "None",
              "rightSource": "None",
              "logic": "And",
              "conditions": [
                {
                  "operator": "Gte",
                  "leftSource": "GroupResult",
                  "leftField": "AnswerCount",
                  "leftDataSourceName": "Selected results",
                  "rightSource": "Const",
                  "rightValue": "3"
                },
                {
                  "operator": "Eq",
                  "leftSource": "Parameter",
                  "leftField": "currentGroupCategory",
                  "rightSource": "Const",
                  "rightValue": "team"
                }
              ]
            },
            "dataSourceNames": [
              "Selected results",
              "Selected benchmarks"
            ],
            "helpTextContentNames": [
              "SurveyInsight"
            ],
            "settings": {
              "showMotivation": true,
              "backgroundColor": "#007A73",
              "iconColor": "#FFFFFF",
              "icon": "arrow-dot",
              "promptSetting": "EXSurveyInsightsTeam",
              "showActionsLink": true
            }
          },
          {
            "key": "Questions_without_index",
            "name": "Questions without index",
            "enabled": true,
            "order": 4,
            "reportPageItemType": "QuestionsWithoutIndex",
            "dataSourceNames": [
              "Select result"
            ],
            "helpTextContentNames": [],
            "settings": {
              "take": 4,
              "shuffleOrder": true,
              "excludedQuestionKeys": []
            }
          },
          {
            "key": "Recommendations",
            "name": "Recommendations",
            "enabled": true,
            "order": 5,
            "reportPageItemType": "Recommendations",
            "dataSourceNames": [
              "Select result"
            ],
            "helpTextContentNames": [
              "recommendations_desc"
            ],
            "settings": null
          },
          {
            "key": "Recommendations_Followup_Summations",
            "name": "Recommendations Followup Summations",
            "enabled": true,
            "order": 6,
            "reportPageItemType": "RecommendationsFollowupSummations",
            "condition": {
              "operator": "Nin",
              "leftSource": "Parameter",
              "leftField": "currentGroupCategory",
              "rightSource": "Parameter",
              "rightField": "allowedActionPlanCategories"
            },
            "dataSourceNames": [
              "Current results"
            ],
            "helpTextContentNames": [],
            "settings": null
          },
          {
            "key": "Latest_results",
            "name": "Latest results",
            "enabled": true,
            "order": 7,
            "reportPageItemType": "LatestSummary",
            "dataSourceNames": [
              "Selected results"
            ],
            "helpTextContentNames": [],
            "settings": {
              "indexes": [
                {
                  "Key": "EI",
                  "SubResultKey": "index_ei"
                },
                {
                  "Key": "TEI",
                  "SubResultKey": "index_tei"
                },
                {
                  "Key": "LSI",
                  "SubResultKey": "index_lsi"
                },
                {
                  "Key": "OSI",
                  "SubResultKey": "index_osi"
                },
                {
                  "Key": "eNPS",
                  "SubResultKey": "index_enps"
                },
                {
                  "Key": "MI",
                  "SubResultKey": "index_mi"
                },
                {
                  "Key": "dsadsa",
                  "SubResultKey": "index_dsadsa"
                },
                {
                  "Key": "IDI",
                  "SubResultKey": "index_idi"
                }
              ],
              "comparisonIndexResultValueParam": "comparisonIndexResultValue"
            }
          },
          {
            "key": "SummaryFreeText",
            "enabled": true,
            "order": 8,
            "reportPageItemType": "SummaryFreeText",
            "condition": {
              "operator": null,
              "leftSource": "None",
              "rightSource": "None",
              "logic": "And",
              "conditions": [
                {
                  "operator": "Gte",
                  "leftSource": "GroupResult",
                  "leftField": "AnswerCount",
                  "leftDataSourceName": "Select result",
                  "rightSource": "Const",
                  "rightValue": "20"
                },
                {
                  "operator": "Neq",
                  "leftSource": "Parameter",
                  "leftField": "currentGroupIsLeafNode",
                  "rightSource": "Const",
                  "rightValue": "1"
                },
                {
                  "operator": "Gt",
                  "leftSource": "GroupResult",
                  "leftField": "TonalityCount",
                  "leftDataSourceName": "Select result",
                  "leftValue": "eNPSFreetext",
                  "rightSource": "Const",
                  "rightValue": "0"
                }
              ]
            },
            "dataSourceNames": [
              "Select result"
            ],
            "helpTextContentNames": [
              "SummaryFreeText"
            ],
            "settings": {
              "promptSetting": "SummaryFreetext_EX_eNPS"
            }
          }
        ]
      },
      {
        "key": "indexes",
        "name": [
          {
            "language": "sv",
            "text": "Index"
          },
          {
            "language": "en",
            "text": "Index"
          },
          {
            "language": "da",
            "text": "Indekser"
          },
          {
            "language": "no",
            "text": "Indeks"
          },
          {
            "language": "fi",
            "text": "Indeksi"
          },
          {
            "language": "de",
            "text": "Index"
          },
          {
            "language": "it",
            "text": "Indice"
          },
          {
            "language": "fr",
            "text": "Index"
          },
          {
            "language": "nl",
            "text": "Index"
          },
          {
            "language": "pl",
            "text": "Wskaźnik"
          },
          {
            "language": "zh-cn",
            "text": "指数"
          },
          {
            "language": "ja",
            "text": "インデックス"
          },
          {
            "language": "es",
            "text": "Índice"
          },
          {
            "language": "pt",
            "text": "Índice"
          },
          {
            "language": "pt-br",
            "text": "Indexes"
          },
          {
            "language": "et",
            "text": "Indeks"
          },
          {
            "language": "el",
            "text": "Ευρετήριο"
          }
        ],
        "reportGroupSelectorSettings": null,
        "displayType": "Tab",
        "children": [
          {
            "key": "index_ei",
            "name": [
              {
                "language": "sv",
                "text": "Engagemang"
              },
              {
                "language": "en",
                "text": "Engagement"
              },
              {
                "language": "da",
                "text": "Engagement"
              },
              {
                "language": "no",
                "text": "Engasjement"
              },
              {
                "language": "fi",
                "text": "Sitoutuneisuus"
              },
              {
                "language": "de",
                "text": "Engagement"
              },
              {
                "language": "it",
                "text": "Partecipazione"
              },
              {
                "language": "fr",
                "text": "Engagement"
              },
              {
                "language": "nl",
                "text": "Betrokkenheid"
              },
              {
                "language": "pl",
                "text": "Zaangazowanie"
              },
              {
                "language": "zh-cn",
                "text": "参与度"
              },
              {
                "language": "ja",
                "text": "エンゲージメント"
              },
              {
                "language": "es",
                "text": "Compromiso"
              },
              {
                "language": "pt",
                "text": "Envolvimento"
              },
              {
                "language": "pt-br",
                "text": "Engajamento"
              },
              {
                "language": "et",
                "text": "Seotus"
              },
              {
                "language": "el",
                "text": "Αφοσίωση"
              }
            ],
            "meta": [
              {
                "key": "index",
                "value": "EI"
              },
              {
                "key": "icon",
                "value": "rocket"
              }
            ],
            "displayType": "CardSelector",
            "condition": {
              "operator": "eq",
              "leftDataSourceName": "Current results",
              "leftSource": "Index",
              "leftValue": "EI",
              "leftField": "Any",
              "rightSource": "Const",
              "rightValue": "True"
            },
            "children": [],
            "reportPageItems": [
              {
                "key": "EIDescription",
                "enabled": true,
                "condition": {
                  "operator": "in",
                  "leftSource": "Parameter",
                  "leftField": "currentGroupCategory",
                  "rightSource": "Parameter",
                  "rightField": "allowedActionPlanCategories"
                },
                "reportPageItemType": "IndexDescription",
                "dataSourceNames": [
                  "Current results"
                ],
                "helpTextContentNames": [],
                "settings": {
                  "indexKey": "EI"
                }
              },
              {
                "key": "EngagementMatrix",
                "enabled": true,
                "reportPageItemType": "EngagementMatrix",
                "dataSourceNames": [
                  "Current results",
                  "Group comparisons"
                ],
                "helpTextContentNames": [
                  "engagement_desc"
                ],
                "settings": null
              },
              {
                "key": "EngagementColorShare",
                "enabled": true,
                "reportPageItemType": "BarInsight",
                "dataSourceNames": [
                  "Current results"
                ],
                "helpTextContentNames": [],
                "settings": {
                  "dataType": "IndexInterval",
                  "indexKeys": [
                    "EI_DarkGreen",
                    "EI_Green",
                    "EI_Blue",
                    "EI_Yellow",
                    "EI_Red"
                  ],
                  "insightKey": "Insight_EIPosShare",
                  "indexKey": "EI"
                }
              },
              {
                "key": "EngagementQuestions",
                "enabled": true,
                "reportPageItemType": "QuestionResults",
                "dataSourceNames": [
                  "Current results",
                  "Group comparisons"
                ],
                "helpTextContentNames": [],
                "settings": {
                  "indexes": [
                    "Energy",
                    "Clarity"
                  ],
                  "optionGrade": "3Grade",
                  "excludedQuestionKeys": []
                }
              }
            ]
          },
          {
            "key": "index_tei",
            "name": [
              {
                "language": "sv",
                "text": "Teameffektivitet"
              },
              {
                "language": "en",
                "text": "Team efficiency"
              },
              {
                "language": "da",
                "text": "Teameffektivitet"
              },
              {
                "language": "no",
                "text": "Teamets effektivitet"
              },
              {
                "language": "fi",
                "text": "Tiimin tehokkuus"
              },
              {
                "language": "de",
                "text": "Teameffizienz"
              },
              {
                "language": "it",
                "text": "Efficienza del team"
              },
              {
                "language": "fr",
                "text": "Efficacité de l’équipe"
              },
              {
                "language": "nl",
                "text": "Teameffectiviteit"
              },
              {
                "language": "pl",
                "text": "Wydajnosc zespolu"
              },
              {
                "language": "zh-cn",
                "text": "团队效率"
              },
              {
                "language": "ja",
                "text": "チームの効率"
              },
              {
                "language": "es",
                "text": "Eficiencia del equipo"
              },
              {
                "language": "pt",
                "text": "Eficiência da equipa"
              },
              {
                "language": "pt-br",
                "text": "Eficiência da equipe"
              },
              {
                "language": "et",
                "text": "Meeskonna tõhusus"
              },
              {
                "language": "el",
                "text": "Αποτελεσματικότητα ομάδας"
              }
            ],
            "collapseQuestions": false,
            "displayType": "CardSelector",
            "condition": {
              "operator": "eq",
              "leftDataSourceName": "Current results",
              "leftSource": "Index",
              "leftValue": "TEI",
              "leftField": "Any",
              "rightSource": "Const",
              "rightValue": "True"
            },
            "meta": [
              {
                "key": "index",
                "value": "TEI"
              },
              {
                "key": "icon",
                "value": "tei"
              }
            ],
            "reportPageItems": [
              {
                "key": "TEI_Description",
                "enabled": true,
                "condition": {
                  "operator": "in",
                  "leftSource": "Parameter",
                  "leftField": "currentGroupCategory",
                  "rightSource": "Parameter",
                  "rightField": "allowedActionPlanCategories"
                },
                "reportPageItemType": "IndexDescription",
                "dataSourceNames": [
                  "Current results"
                ],
                "helpTextContentNames": [],
                "settings": {
                  "indexKey": "TEI"
                }
              },
              {
                "key": "TEI_Index",
                "enabled": true,
                "reportPageItemType": "IndexWithComparison",
                "dataSourceNames": [
                  "Current results",
                  "Group comparisons"
                ],
                "helpTextContentNames": [
                  "tei_desc"
                ],
                "settings": {
                  "indexKey": "TEI"
                }
              },
              {
                "key": "TEI_IndexGroupDistribution",
                "enabled": true,
                "reportPageItemType": "IndexGroupDistributions",
                "dataSourceNames": [
                  "Current results"
                ],
                "settings": {
                  "indexKey": "TEI"
                },
                "condition": {
                  "operator": "eq",
                  "leftSource": "Parameter",
                  "leftField": "currentGroupIsLeafNode",
                  "rightSource": "Const",
                  "rightValue": "0"
                }
              },
              {
                "key": "TEI_Questions",
                "enabled": true,
                "reportPageItemType": "QuestionResults",
                "dataSourceNames": [
                  "Current results",
                  "Group comparisons"
                ],
                "helpTextContentNames": [],
                "settings": {
                  "indexes": [
                    "TEI"
                  ],
                  "optionGrade": "3Grade",
                  "expandAllCombinations": false,
                  "excludedQuestionKeys": []
                }
              }
            ]
          },
          {
            "key": "index_lsi",
            "name": [
              {
                "language": "sv",
                "text": "Ledarskap"
              },
              {
                "language": "en",
                "text": "Leadership"
              },
              {
                "language": "da",
                "text": "Lederskab"
              },
              {
                "language": "no",
                "text": "Lederskap"
              },
              {
                "language": "fi",
                "text": "Johtajuus"
              },
              {
                "language": "de",
                "text": "Führung"
              },
              {
                "language": "it",
                "text": "Leadership"
              },
              {
                "language": "fr",
                "text": "Leadership"
              },
              {
                "language": "nl",
                "text": "Leiderschap"
              },
              {
                "language": "pl",
                "text": "Przywództwo"
              },
              {
                "language": "zh-cn",
                "text": "领导力"
              },
              {
                "language": "ja",
                "text": "リーダーシップ"
              },
              {
                "language": "es",
                "text": "Liderazgo"
              },
              {
                "language": "pt",
                "text": "Liderança"
              },
              {
                "language": "pt-br",
                "text": "Liderança"
              },
              {
                "language": "et",
                "text": "Juhtimine"
              },
              {
                "language": "el",
                "text": "Ηγεσία"
              }
            ],
            "enabled": true,
            "collapseQuestions": false,
            "parameter": null,
            "displayType": "CardSelector",
            "condition": {
              "operator": "eq",
              "leftDataSourceName": "Current results",
              "leftSource": "Index",
              "leftValue": "LSI",
              "leftField": "Any",
              "rightSource": "Const",
              "rightValue": "True"
            },
            "meta": [
              {
                "key": "index",
                "value": "LSI"
              },
              {
                "key": "icon",
                "value": "leadership"
              }
            ],
            "reportPageItems": [
              {
                "key": "LSI_Description",
                "enabled": true,
                "condition": {
                  "operator": "in",
                  "leftSource": "Parameter",
                  "leftField": "currentGroupCategory",
                  "rightSource": "Parameter",
                  "rightField": "allowedActionPlanCategories"
                },
                "reportPageItemType": "IndexDescription",
                "dataSourceNames": [
                  "Current results"
                ],
                "helpTextContentNames": [],
                "settings": {
                  "indexKey": "LSI"
                }
              },
              {
                "key": "LSI_Index",
                "enabled": true,
                "reportPageItemType": "IndexWithComparison",
                "dataSourceNames": [
                  "Current results",
                  "Group comparisons"
                ],
                "helpTextContentNames": [
                  "leadership_desc"
                ],
                "settings": {
                  "indexKey": "LSI"
                }
              },
              {
                "key": "LSI_IndexGroupDistribution",
                "enabled": true,
                "reportPageItemType": "IndexGroupDistributions",
                "dataSourceNames": [
                  "Current results"
                ],
                "settings": {
                  "indexKey": "LSI"
                },
                "condition": {
                  "operator": "eq",
                  "leftSource": "Parameter",
                  "leftField": "currentGroupIsLeafNode",
                  "rightSource": "Const",
                  "rightValue": "0"
                }
              },
              {
                "key": "LSI_Questions",
                "enabled": true,
                "reportPageItemType": "QuestionResults",
                "dataSourceNames": [
                  "Current results",
                  "Group comparisons"
                ],
                "helpTextContentNames": [],
                "settings": {
                  "indexes": [
                    "LSI"
                  ],
                  "optionGrade": "3Grade",
                  "expandAllCombinations": false,
                  "excludedQuestionKeys": []
                }
              }
            ]
          },
          {
            "key": "index_osi",
            "name": [
              {
                "language": "en",
                "text": "OSI environment"
              },
              {
                "language": "sv",
                "text": "OSI arbetsmiljö"
              },
              {
                "language": "da",
                "text": "OSI-miljø"
              },
              {
                "language": "no",
                "text": "OSI-miljø"
              },
              {
                "language": "fi",
                "text": "OSI-ympäristö"
              },
              {
                "language": "de",
                "text": "OSI-Umgebung"
              },
              {
                "language": "it",
                "text": "Ambiente di lavoro organizzativo e sociale"
              },
              {
                "language": "fr",
                "text": "Environnement OSI"
              },
              {
                "language": "nl",
                "text": "OSI-omgeving"
              },
              {
                "language": "pl",
                "text": "Organizacyjne i spoleczne srodowisko pracy"
              },
              {
                "language": "zh-cn",
                "text": "组织和社会工作环境"
              },
              {
                "language": "ja",
                "text": "組織的および社会的職場環境"
              },
              {
                "language": "es",
                "text": "Entorno de trabajo organizativo"
              },
              {
                "language": "pt",
                "text": "Ambiente de trabalho organizacional e social"
              },
              {
                "language": "pt-br",
                "text": "Ambiente OSI"
              },
              {
                "language": "et",
                "text": "OSI keskkond"
              },
              {
                "language": "el",
                "text": "Οργανωτικό και κοινωνικό περιβάλλον εργασίας"
              }
            ],
            "enabled": true,
            "parameter": null,
            "collapseQuestions": false,
            "displayType": "CardSelector",
            "condition": {
              "operator": "eq",
              "leftDataSourceName": "Current results",
              "leftSource": "Index",
              "leftValue": "OSI",
              "leftField": "Any",
              "rightSource": "Const",
              "rightValue": "True"
            },
            "meta": [
              {
                "key": "index",
                "value": "OSI"
              },
              {
                "key": "icon",
                "value": "osi"
              }
            ],
            "reportPageItems": [
              {
                "key": "OSI_Description",
                "enabled": true,
                "condition": {
                  "operator": "in",
                  "leftSource": "Parameter",
                  "leftField": "currentGroupCategory",
                  "rightSource": "Parameter",
                  "rightField": "allowedActionPlanCategories"
                },
                "reportPageItemType": "IndexDescription",
                "dataSourceNames": [
                  "Current results"
                ],
                "helpTextContentNames": [],
                "settings": {
                  "indexKey": "OSI"
                }
              },
              {
                "key": "OSI_Index",
                "enabled": true,
                "reportPageItemType": "IndexWithComparison",
                "dataSourceNames": [
                  "Current results",
                  "Group comparisons"
                ],
                "helpTextContentNames": [
                  "osi_desc"
                ],
                "settings": {
                  "indexKey": "OSI"
                }
              },
              {
                "key": "OSI_IndexGroupDistribution",
                "enabled": true,
                "reportPageItemType": "IndexGroupDistributions",
                "dataSourceNames": [
                  "Current results"
                ],
                "settings": {
                  "indexKey": "OSI"
                },
                "condition": {
                  "operator": "eq",
                  "leftSource": "Parameter",
                  "leftField": "currentGroupIsLeafNode",
                  "rightSource": "Const",
                  "rightValue": "0"
                }
              },
              {
                "key": "OSI_Questions",
                "enabled": true,
                "reportPageItemType": "QuestionResults",
                "dataSourceNames": [
                  "Current results",
                  "Group comparisons"
                ],
                "helpTextContentNames": [],
                "settings": {
                  "indexes": [
                    "OSI"
                  ],
                  "optionGrade": "3Grade",
                  "excludedQuestionKeys": []
                }
              }
            ]
          },
          {
            "key": "index_enps",
            "name": [
              {
                "language": "en",
                "text": "eNPS"
              },
              {
                "language": "sv",
                "text": "eNPS"
              },
              {
                "language": "da",
                "text": "eNPS"
              },
              {
                "language": "no",
                "text": "eNPS"
              },
              {
                "language": "fi",
                "text": "eNPS"
              },
              {
                "language": "de",
                "text": "eNPS"
              },
              {
                "language": "it",
                "text": "eNPS"
              },
              {
                "language": "fr",
                "text": "eNPS"
              },
              {
                "language": "nl",
                "text": "eNPS"
              },
              {
                "language": "pl",
                "text": "eNPS"
              },
              {
                "language": "zh-cn",
                "text": "eNPS"
              },
              {
                "language": "ja",
                "text": "eNPS"
              },
              {
                "language": "es",
                "text": "eNPS"
              },
              {
                "language": "pt",
                "text": "eNPS"
              },
              {
                "language": "pt-br",
                "text": "eNPS"
              },
              {
                "language": "et",
                "text": "eNPS"
              },
              {
                "language": "el",
                "text": "Ηλεκτρονική βαθμολογία NPS"
              }
            ],
            "meta": [
              {
                "key": "index",
                "value": "eNPS"
              },
              {
                "key": "icon",
                "value": "enps"
              }
            ],
            "displayType": "CardSelector",
            "condition": {
              "operator": "eq",
              "leftDataSourceName": "Current results",
              "leftSource": "Index",
              "leftValue": "eNPS",
              "leftField": "Any",
              "rightSource": "Const",
              "rightValue": "True"
            },
            "children": [],
            "reportPageItems": [
              {
                "key": "eNPS",
                "enabled": true,
                "reportPageItemType": "ENps",
                "dataSourceNames": [
                  "Current results",
                  "Group comparisons"
                ],
                "helpTextContentNames": [
                  "enps_desc"
                ],
                "settings": null
              },
              {
                "key": "eNPS_Ambassadors",
                "enabled": true,
                "reportPageItemType": "ENpsAmbassadors",
                "dataSourceNames": [
                  "Current results"
                ],
                "helpTextContentNames": [
                  "enps_desc"
                ],
                "settings": null
              },
              {
                "key": "eNPS_Distribution",
                "enabled": true,
                "reportPageItemType": "ENpsDistribution",
                "dataSourceNames": [
                  "Current results"
                ],
                "helpTextContentNames": [
                  "enps_que_desc"
                ],
                "settings": null
              },
              {
                "key": "eNPS_Freetext",
                "enabled": true,
                "reportPageItemType": "ENpsFreetext",
                "dataSourceNames": [
                  "Current results"
                ],
                "helpTextContentNames": [],
                "condition": {
                  "operator": "Eq",
                  "leftSource": "Permission",
                  "leftSourceId": 76,
                  "rightSource": "Const",
                  "rightValue": "True"
                },
                "settings": {
                  "commentQuestionKey": "eNPSFreeText",
                  "valueQuestionKey": "eNPS"
                }
              }
            ]
          },
          {
            "key": "index_mi",
            "name": [
              {
                "language": "sv",
                "text": "Ledning"
              },
              {
                "language": "pt",
                "text": "Gestão"
              },
              {
                "language": "fr",
                "text": "Gestion"
              },
              {
                "language": "cs",
                "text": "Řízení"
              },
              {
                "language": "sk",
                "text": "Vedenie"
              },
              {
                "language": "de",
                "text": "Management"
              },
              {
                "language": "el",
                "text": "Διοίκηση"
              },
              {
                "language": "ru",
                "text": "Руководство"
              },
              {
                "language": "da",
                "text": "Ledelse"
              },
              {
                "language": "es",
                "text": "Gestión"
              },
              {
                "language": "pl",
                "text": "Zarządzanie"
              },
              {
                "language": "ro",
                "text": "Management"
              },
              {
                "language": "no",
                "text": "Ledelse"
              },
              {
                "language": "lv",
                "text": "Pārvaldība"
              },
              {
                "language": "it",
                "text": "Management"
              },
              {
                "language": "lt",
                "text": "Vadovybė"
              },
              {
                "language": "zh-cn",
                "text": "管理"
              },
              {
                "language": "ja",
                "text": "マネジメント"
              },
              {
                "language": "nl",
                "text": "Management"
              },
              {
                "language": "hu",
                "text": "Vezetés"
              },
              {
                "language": "fi",
                "text": "Yritysjohto"
              },
              {
                "language": "pt-br",
                "text": "Gerenciamento"
              },
              {
                "language": "en",
                "text": "Management"
              },
              {
                "language": "et",
                "text": "Juhtkond"
              },
              {
                "language": "tr",
                "text": "Yönetim"
              },
              {
                "language": "bg",
                "text": "Ръководство"
              }
            ],
            "enabled": true,
            "collapseQuestions": false,
            "parameter": null,
            "displayType": "CardSelector",
            "condition": {
              "operator": "eq",
              "leftDataSourceName": "Current results",
              "leftSource": "Index",
              "leftValue": "MI",
              "leftField": "Any",
              "rightSource": "Const",
              "rightValue": "True"
            },
            "meta": [
              {
                "key": "index",
                "value": "MI"
              },
              {
                "key": "icon",
                "value": "management"
              }
            ],
            "reportPageItems": [
              {
                "key": "MI_Description",
                "enabled": true,
                "condition": {
                  "operator": "in",
                  "leftSource": "Parameter",
                  "leftField": "currentGroupCategory",
                  "rightSource": "Parameter",
                  "rightField": "allowedActionPlanCategories"
                },
                "reportPageItemType": "IndexDescription",
                "dataSourceNames": [
                  "Current results"
                ],
                "helpTextContentNames": [],
                "settings": {
                  "indexKey": "MI"
                }
              },
              {
                "key": "MI_Index",
                "enabled": true,
                "reportPageItemType": "IndexWithComparison",
                "dataSourceNames": [
                  "Current results",
                  "Group comparisons"
                ],
                "helpTextContentNames": [
                  "management_desc"
                ],
                "settings": {
                  "indexKey": "MI"
                }
              },
              {
                "key": "MI_IndexGroupDistribution",
                "enabled": true,
                "reportPageItemType": "IndexGroupDistributions",
                "dataSourceNames": [
                  "Current results"
                ],
                "settings": {
                  "indexKey": "MI"
                },
                "condition": {
                  "operator": "eq",
                  "leftSource": "Parameter",
                  "leftField": "currentGroupIsLeafNode",
                  "rightSource": "Const",
                  "rightValue": "0"
                }
              },
              {
                "key": "MI_Questions",
                "enabled": true,
                "reportPageItemType": "QuestionResults",
                "dataSourceNames": [
                  "Current results",
                  "Group comparisons"
                ],
                "helpTextContentNames": [],
                "settings": {
                  "indexes": [
                    "MI"
                  ],
                  "optionGrade": "3Grade",
                  "expandAllCombinations": false,
                  "excludedQuestionKeys": []
                }
              }
            ]
          }
        ]
      },
      {
        "key": "questionresults",
        "name": [
          {
            "language": "en",
            "text": "Questions"
          },
          {
            "language": "sv",
            "text": "Frågor"
          },
          {
            "language": "da",
            "text": "Spørgsmål"
          },
          {
            "language": "no",
            "text": "Spørsmål"
          },
          {
            "language": "fi",
            "text": "Kysymykset"
          },
          {
            "language": "de",
            "text": "Fragen"
          },
          {
            "language": "it",
            "text": "Domande"
          },
          {
            "language": "fr",
            "text": "Questions"
          },
          {
            "language": "nl",
            "text": "Vragen"
          },
          {
            "language": "pl",
            "text": "Pytania"
          },
          {
            "language": "zh-cn",
            "text": "问题"
          },
          {
            "language": "ja",
            "text": "質問"
          },
          {
            "language": "es",
            "text": "Preguntas"
          },
          {
            "language": "pt",
            "text": "Perguntas"
          },
          {
            "language": "pt-br",
            "text": "Perguntas"
          },
          {
            "language": "et",
            "text": "Küsimused"
          },
          {
            "language": "el",
            "text": "Ερωτήσεις"
          }
        ],
        "meta": [
          {
            "key": "icon",
            "value": "buildings"
          }
        ],
        "reportGroupSelectorSettings": null,
        "displayType": "Tab",
        "children": [],
        "reportPageItems": [
          {
            "key": "FilterBar",
            "enabled": true,
            "reportPageItemType": "FilterBar",
            "dataSourceNames": [],
            "helpTextContentNames": [],
            "settings": {
              "filters": [
                {
                  "filterType": "GroupFilter",
                  "parameter": "selectedComparisonGroups",
                  "filterSettings": {
                    "dependsOnParameters": [
                      "currentGroup"
                    ],
                    "maximumSelectionCount": 30,
                    "backgroundFilter": true
                  }
                },
                {
                  "filterType": "SnapshotFilter",
                  "parameter": "selectedSnapshots",
                  "filterSettings": {
                    "dependsOnGroupParameter": "currentGroup"
                  }
                },
                {
                  "filterType": "BenchmarkFilter",
                  "parameter": "selectedBenchmarks",
                  "filterSettings": {
                    "dataSource": "All Benchmarks"
                  }
                },
                {
                  "filterType": "IndexFilter",
                  "parameter": "resultListIndexFilter",
                  "filterSettings": {
                    "invalidates": false
                  }
                },
                {
                  "filterType": "QuestionFilter"
                },
                {
                  "filterType": "SettingsFilter"
                },
                {
                  "filterType": "SortingFilter"
                }
              ]
            }
          },
          {
            "key": "QuestionResults",
            "enabled": true,
            "reportPageItemType": "QuestionResults",
            "dataSourceNames": [
              "Selected comparison results",
              "Selected benchmarks"
            ],
            "helpTextContentNames": [],
            "settings": {
              "collapsable": false,
              "indexes": [],
              "optionGrade": "3Grade",
              "SnapshotCountParam": "snapshotCounts",
              "excludedQuestionKeys": [],
              "showSettings": true,
              "selectedSnapshotsParam": "selectedSnapshots",
              "selectedGroupsParameter": "selectedComparisonGroups",
              "benchmarkListDataSourceName": "All Benchmarks",
              "selectedBenchmarksParameter": "selectedBenchmarks",
              "contextGroupParameter": "currentGroup"
            }
          }
        ]
      },
      {
        "name": [
          {
            "language": "sv",
            "text": "Analysera"
          },
          {
            "language": "en",
            "text": "Analyse"
          },
          {
            "language": "da",
            "text": "Analysere"
          },
          {
            "language": "no",
            "text": "Analysere"
          },
          {
            "language": "fi",
            "text": "Analysoida"
          },
          {
            "language": "de",
            "text": "Analysieren"
          },
          {
            "language": "it",
            "text": "Analizzare"
          },
          {
            "language": "fr",
            "text": "Analyser"
          },
          {
            "language": "nl",
            "text": "Analyseren"
          },
          {
            "language": "pl",
            "text": "Analizowac"
          },
          {
            "language": "zh-cn",
            "text": "分析"
          },
          {
            "language": "ja",
            "text": "分析"
          },
          {
            "language": "es",
            "text": "Analizar"
          },
          {
            "language": "pt",
            "text": "Analisar"
          },
          {
            "language": "pt-br",
            "text": "Analisar"
          },
          {
            "language": "et",
            "text": "Analüüsi"
          },
          {
            "language": "el",
            "text": "Ανάλυση"
          }
        ],
        "key": "analyse",
        "enabled": true,
        "parameter": null,
        "displayType": "Tab",
        "meta": [],
        "reportGroupSelectorSettings": null,
        "children": [
          {
            "key": "indexlist",
            "name": [
              {
                "language": "sv",
                "text": "Indexlista"
              },
              {
                "language": "en",
                "text": "Index list"
              },
              {
                "language": "da",
                "text": "Indeksliste"
              },
              {
                "language": "no",
                "text": "Indeksliste"
              },
              {
                "language": "fi",
                "text": "Indeksiluettelo"
              },
              {
                "language": "de",
                "text": "Indexliste"
              },
              {
                "language": "it",
                "text": "Elenco degli indici"
              },
              {
                "language": "fr",
                "text": "Liste des index"
              },
              {
                "language": "nl",
                "text": "Indexlijst"
              },
              {
                "language": "pl",
                "text": "Lista wskaźników"
              },
              {
                "language": "zh-cn",
                "text": "指数列表"
              },
              {
                "language": "ja",
                "text": "インデックスリスト"
              },
              {
                "language": "es",
                "text": "Lista de índices"
              },
              {
                "language": "pt",
                "text": "Lista de índice"
              },
              {
                "language": "pt-br",
                "text": "Lista de índice"
              },
              {
                "language": "et",
                "text": "Indeksiloend"
              },
              {
                "language": "el",
                "text": "Λίστα δεικτών"
              }
            ],
            "meta": [
              {
                "key": "icon",
                "value": "bar4"
              }
            ],
            "displayType": "CardSelector",
            "children": [],
            "reportPageItems": [
              {
                "key": "FilterBar",
                "enabled": true,
                "reportPageItemType": "FilterBar",
                "dataSourceNames": [],
                "helpTextContentNames": [],
                "settings": {
                  "filters": [
                    {
                      "filterType": "GroupFilter",
                      "parameter": "selectedComparisonGroups",
                      "filterSettings": {
                        "dependsOnParameters": [
                          "currentGroup"
                        ],
                        "backgroundFilter": true
                      }
                    },
                    {
                      "filterType": "SnapshotFilter",
                      "parameter": "selectedSnapshots"
                    },
                    {
                      "filterType": "IndexFilter",
                      "parameter": "resultListIndexFilter"
                    },
                    {
                      "filterType": "SettingsFilter",
                      "filterSettings": {
                        "keys": [
                          "hide-empty-results"
                        ]
                      }
                    }
                  ]
                }
              },
              {
                "key": "IndexList",
                "enabled": true,
                "reportPageItemType": "ResultList",
                "dataSourceNames": [
                  "Index list comparison groups"
                ],
                "helpTextContentNames": [
                  "HelpTextResultList"
                ],
                "settings": {
                  "indexFilterParam": "resultListIndexFilter",
                  "selectedSnapshotsParam": "selectedSnapshots"
                }
              }
            ]
          },
          {
            "key": "engagement_comp",
            "name": [
              {
                "language": "en",
                "text": "Engagement"
              },
              {
                "language": "sv",
                "text": "Engagemang"
              },
              {
                "language": "da",
                "text": "Engagement"
              },
              {
                "language": "no",
                "text": "Engasjement"
              },
              {
                "language": "fi",
                "text": "Sitoutuneisuus"
              },
              {
                "language": "de",
                "text": "Engagement"
              },
              {
                "language": "it",
                "text": "Partecipazione"
              },
              {
                "language": "fr",
                "text": "Engagement"
              },
              {
                "language": "nl",
                "text": "Betrokkenheid"
              },
              {
                "language": "pl",
                "text": "Zaangazowanie"
              },
              {
                "language": "zh-cn",
                "text": "参与度"
              },
              {
                "language": "ja",
                "text": "エンゲージメント"
              },
              {
                "language": "es",
                "text": "Compromiso"
              },
              {
                "language": "pt",
                "text": "Envolvimento"
              },
              {
                "language": "pt-br",
                "text": "Envolvimento"
              },
              {
                "language": "et",
                "text": "Seotus"
              },
              {
                "language": "el",
                "text": "Αφοσίωση"
              }
            ],
            "meta": [
              {
                "key": "icon",
                "value": "rocket"
              }
            ],
            "condition": {
              "operator": "eq",
              "leftDataSourceName": "Current results",
              "leftSource": "Index",
              "leftValue": "EI",
              "leftField": "Any",
              "rightSource": "Const",
              "rightValue": "True"
            },
            "reportGroupSelectorSettings": null,
            "displayType": "CardSelector",
            "children": [],
            "reportPageItems": [
              {
                "key": "FilterBar",
                "enabled": true,
                "reportPageItemType": "FilterBar",
                "dataSourceNames": [],
                "helpTextContentNames": [],
                "settings": {
                  "filters": [
                    {
                      "filterType": "GroupFilter",
                      "parameter": "selectedComparisonGroups",
                      "filterSettings": {
                        "dependsOnParameters": [
                          "currentGroup"
                        ],
                        "backgroundFilter": true
                      }
                    },
                    {
                      "filterType": "SnapshotFilter",
                      "parameter": "selectedSnapshots"
                    }
                  ]
                }
              },
              {
                "key": "EngagementComp",
                "enabled": true,
                "reportPageItemType": "EngagementMatrix",
                "dataSourceNames": [
                  "Selected comparison results"
                ],
                "helpTextContentNames": [
                  "engagement_desc"
                ],
                "settings": {
                  "SelectedGroupComparison": true
                }
              }
            ]
          },
          {
            "name": [
              {
                "language": "en",
                "text": "Equal treatment"
              },
              {
                "language": "sv",
                "text": "Likabehandling"
              },
              {
                "language": "pl",
                "text": "Równe traktowanie"
              },
              {
                "language": "de",
                "text": "Gleichbehandlung"
              },
              {
                "language": "da",
                "text": "Ligebehandling"
              },
              {
                "language": "bg",
                "text": "Еднакво отношение"
              },
              {
                "language": "zh-cn",
                "text": "平等待遇"
              },
              {
                "language": "pt-br",
                "text": "Tratamento igualitário"
              },
              {
                "language": "pt",
                "text": "Igualdade de tratamento"
              },
              {
                "language": "es",
                "text": "Igualdad de trato"
              },
              {
                "language": "no",
                "text": "Likebehandling"
              },
              {
                "language": "nl",
                "text": "Gelijke behandeling"
              },
              {
                "language": "ja",
                "text": "平等な処遇"
              },
              {
                "language": "it",
                "text": "Pari opportunità"
              },
              {
                "language": "fr",
                "text": "Égalité de traitement"
              },
              {
                "language": "fi",
                "text": "Tasavertainen kohtelu"
              },
              {
                "language": "et",
                "text": "Võrdne kohtlemine"
              },
              {
                "language": "el",
                "text": "Ίση μεταχείριση"
              }
            ],
            "key": "harassment",
            "enabled": true,
            "meta": [
              {
                "key": "icon",
                "value": "equal"
              }
            ],
            "condition": {
              "operator": "Eq",
              "leftSource": "Permission",
              "leftValue": "Results.SeeHarassment",
              "LeftSourceId": 112,
              "leftField": "Any",
              "rightSource": "Const",
              "rightValue": "True"
            },
            "displayType": "CardSelector",
            "reportPageItems": [
              {
                "key": "HarassmentDescription",
                "enabled": true,
                "reportPageItemType": "Description",
                "settings": {
                  "color": "#c1dddb",
                  "icon": "equal",
                  "text": "harassment_desc"
                }
              },
              {
                "key": "FilterBar",
                "enabled": true,
                "reportPageItemType": "FilterBar",
                "dataSourceNames": [],
                "helpTextContentNames": [],
                "settings": {
                  "filters": [
                    {
                      "filterType": "GroupFilter",
                      "parameter": "selectedComparisonGroups",
                      "filterSettings": {
                        "dependsOnParameters": [
                          "currentGroup"
                        ],
                        "maximumSelectionCount": 30,
                        "backgroundFilter": true
                      }
                    },
                    {
                      "filterType": "SnapshotFilter",
                      "parameter": "selectedSnapshots",
                      "filterSettings": {
                        "dependsOnGroupParameter": "currentGroup"
                      }
                    },
                    {
                      "filterType": "QuestionFilter"
                    }
                  ]
                }
              },
              {
                "key": "HarassmentQuestionResults",
                "enabled": true,
                "reportPageItemType": "QuestionResults",
                "dataSourceNames": [
                  "Selected comparison results"
                ],
                "helpTextContentNames": [],
                "settings": {
                  "collapsable": false,
                  "indexes": [],
                  "optionGrade": "3Grade",
                  "SnapshotCountParam": "snapshotCounts",
                  "excludedQuestionKeys": [],
                  "questionCategories": [
                    "Harassment",
                    "HarassmentSensitive"
                  ],
                  "showSettings": true,
                  "selectedSnapshotsParam": "selectedSnapshots",
                  "selectedGroupsParameter": "selectedComparisonGroups",
                  "benchmarkListDataSourceName": "All Benchmarks",
                  "selectedBenchmarksParameter": "selectedBenchmarks",
                  "contextGroupParameter": "currentGroup",
                  "showLogic": true
                }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    "key": "actionplans",
    "name": [
      {
        "language": "sv",
        "text": "Handlingsplaner"
      },
      {
        "language": "en",
        "text": "Action plans"
      },
      {
        "language": "da",
        "text": "Handlingsplaner"
      },
      {
        "language": "no",
        "text": "Handlingsplaner"
      },
      {
        "language": "fi",
        "text": "Toimintasuunnitelmat"
      },
      {
        "language": "de",
        "text": "Aktionspläne"
      },
      {
        "language": "it",
        "text": "Piani d’azione"
      },
      {
        "language": "fr",
        "text": "Plans d’action"
      },
      {
        "language": "nl",
        "text": "Actieplannen"
      },
      {
        "language": "pl",
        "text": "Plany dzialania"
      },
      {
        "language": "zh-cn",
        "text": "我的行动计划"
      },
      {
        "language": "ja",
        "text": "マイアクションプラン"
      },
      {
        "language": "es",
        "text": "Planes de acción"
      },
      {
        "language": "pt",
        "text": "Planos de ação"
      },
      {
        "language": "pt-br",
        "text": "Planos de ação"
      },
      {
        "language": "et",
        "text": "Tegevuskavad"
      },
      {
        "language": "el",
        "text": "Τα σχέδια δράσης μου"
      }
    ],
    "meta": [
      {
        "key": "icon",
        "value": "arrow-dot"
      }
    ],
    "groupName": "MyPages",
    "displayType": "Sidebar",
    "condition": {
      "operator": "Eq",
      "leftSource": "Permission",
      "leftSourceId": 61,
      "rightSource": "Const",
      "rightValue": "True"
    },
    "children": [
      {
        "name": [
          {
            "language": "en",
            "text": "Action plan"
          },
          {
            "language": "sv",
            "text": "Handlingsplan"
          },
          {
            "language": "da",
            "text": "Handlingsplan"
          },
          {
            "language": "no",
            "text": "Handlingsplan"
          },
          {
            "language": "fi",
            "text": "Toimintasuunnitelma"
          },
          {
            "language": "de",
            "text": "Aktionsplan"
          },
          {
            "language": "it",
            "text": "Piano d’azione"
          },
          {
            "language": "fr",
            "text": "Plan d’action"
          },
          {
            "language": "nl",
            "text": "Actieplan"
          },
          {
            "language": "pl",
            "text": "Plan dzialania"
          },
          {
            "language": "zh-cn",
            "text": "行动计划"
          },
          {
            "language": "ja",
            "text": "アクションプラン"
          },
          {
            "language": "es",
            "text": "Plan de acción"
          },
          {
            "language": "pt",
            "text": "Plano de ação"
          },
          {
            "language": "pt-br",
            "text": "Plano de ação"
          },
          {
            "language": "et",
            "text": "Tegevuskava"
          },
          {
            "language": "el",
            "text": "Τα σχέδια δράσης μου"
          }
        ],
        "key": "group_actions",
        "reportGroupSelectorSettings": {
          "priorityHierarchies": [
            "teams"
          ],
          "parameter": "currentGroup",
          "isVisible": true
        },
        "enabled": true,
        "allowCache": false,
        "condition": {
          "operator": "in",
          "leftSource": "Parameter",
          "leftField": "currentGroupCategory",
          "rightSource": "Parameter",
          "rightField": "allowedActionPlanCategories"
        },
        "parameter": null,
        "displayType": "Tab",
        "meta": [],
        "reportPageItems": [
          {
            "key": "CreateFollowUp",
            "enabled": true,
            "reportPageItemType": "FollowUp",
            "dataSourceNames": [
              "Current results"
            ],
            "helpTextContentNames": [],
            "settings": {
              "recommendationsCount": 3,
              "text": "followup_desc",
              "errorText": "followup_error_desc",
              "contextGroupParameter": "currentGroup"
            }
          },
          {
            "key": "ActionPlanList",
            "enabled": true,
            "reportPageItemType": "ActionPlanList",
            "dataSourceNames": [
              "Current group products"
            ],
            "helpTextContentNames": [],
            "settings": {
              "category": "ERM",
              "type": "Followup"
            }
          }
        ]
      },
      {
        "name": [
          {
            "language": "sv",
            "text": "Statistik"
          },
          {
            "language": "en",
            "text": "Statistics"
          },
          {
            "language": "da",
            "text": "Statistik"
          },
          {
            "language": "no",
            "text": "Statistikk"
          },
          {
            "language": "fi",
            "text": "Tilastot"
          },
          {
            "language": "de",
            "text": "Statistiken"
          },
          {
            "language": "it",
            "text": "Statistiche"
          },
          {
            "language": "fr",
            "text": "Statistiques"
          },
          {
            "language": "nl",
            "text": "Statistieken"
          },
          {
            "language": "pl",
            "text": "Statystyki"
          },
          {
            "language": "zh-cn",
            "text": "统计"
          },
          {
            "language": "ja",
            "text": "統計"
          },
          {
            "language": "es",
            "text": "Estadísticas"
          },
          {
            "language": "pt",
            "text": "Estatísticas"
          },
          {
            "language": "pt-br",
            "text": "Estatísticas"
          },
          {
            "language": "et",
            "text": "Statistika"
          },
          {
            "language": "el",
            "text": "Στατιστικά"
          }
        ],
        "key": "actionplans_stats",
        "reportGroupSelectorSettings": {
          "priorityHierarchies": [
            "teams"
          ],
          "parameter": "currentGroup",
          "isVisible": true
        },
        "enabled": true,
        "parameter": null,
        "displayType": "Tab",
        "meta": [],
        "children": [
          {
            "name": [
              {
                "language": "zh-cn",
                "text": "概览"
              },
              {
                "language": "pt",
                "text": "Visão geral"
              },
              {
                "language": "pl",
                "text": "Przegląd"
              },
              {
                "language": "ja",
                "text": "概要"
              },
              {
                "language": "nl",
                "text": "Overzicht"
              },
              {
                "language": "fr",
                "text": "Vue d’ensemble"
              },
              {
                "language": "it",
                "text": "Panoramica"
              },
              {
                "language": "es",
                "text": "Resumen"
              },
              {
                "language": "de",
                "text": "Übersicht"
              },
              {
                "language": "fi",
                "text": "Yleiskuvaus"
              },
              {
                "language": "no",
                "text": "Oversikt"
              },
              {
                "language": "da",
                "text": "Oversigt"
              },
              {
                "language": "en",
                "text": "Overview"
              },
              {
                "language": "sv",
                "text": "Översikt"
              },
              {
                "language": "pt-br",
                "text": "Visão geral"
              },
              {
                "language": "et",
                "text": "Ülevaade"
              },
              {
                "language": "el",
                "text": "Επισκόπηση"
              }
            ],
            "key": "actionplans_stats_overview",
            "enabled": true,
            "allowCache": false,
            "reportGroupSelectorSettings": null,
            "parameter": null,
            "displayType": "CardSelector",
            "meta": [
              {
                "key": "icon",
                "value": ""
              }
            ],
            "reportPageItems": [
              {
                "key": "FilterBar",
                "enabled": true,
                "reportPageItemType": "FilterBar",
                "dataSourceNames": [],
                "settings": {
                  "filters": [
                    {
                      "filterType": "TimeFilter",
                      "parameter": "actionplanOverviewTimeFrame",
                      "filterSettings": {
                        "invalidates": true
                      }
                    }
                  ]
                }
              },
              {
                "key": "ActionPlansOverview",
                "enabled": true,
                "reportPageItemType": "ActionPlansOverview",
                "dataSourceNames": [
                  "Current summation group children",
                  "Current summation group children products"
                ]
              },
              {
                "key": "ActionPlansAreas",
                "enabled": true,
                "reportPageItemType": "ActionPlansAreas",
                "dataSourceNames": [
                  "Current summation group children products"
                ]
              },
              {
                "key": "RecommendationsStats",
                "enabled": true,
                "reportPageItemType": "RecommendationsStats",
                "dataSourceNames": [
                  "Current summation group children result"
                ]
              }
            ]
          },
          {
            "name": [
              {
                "language": "en",
                "text": "Explore"
              },
              {
                "language": "sv",
                "text": "Utforska"
              },
              {
                "language": "zh-cn",
                "text": "探索"
              },
              {
                "language": "pt-br",
                "text": "Explorar"
              },
              {
                "language": "pt",
                "text": "Explorar"
              },
              {
                "language": "pl",
                "text": "Odkrywaj"
              },
              {
                "language": "no",
                "text": "Utforsk"
              },
              {
                "language": "nl",
                "text": "Verkennen"
              },
              {
                "language": "ja",
                "text": "探索"
              },
              {
                "language": "it",
                "text": "Esplora"
              },
              {
                "language": "fr",
                "text": "Découvrir"
              },
              {
                "language": "fi",
                "text": "Tutki"
              },
              {
                "language": "et",
                "text": "Avasta"
              },
              {
                "language": "es",
                "text": "Explorar"
              },
              {
                "language": "de",
                "text": "Erkunden"
              },
              {
                "language": "da",
                "text": "Udforsk"
              },
              {
                "language": "el",
                "text": "Εξερευνώ"
              }
            ],
            "key": "actionplans_stats_explore",
            "enabled": true,
            "allowCache": false,
            "reportGroupSelectorSettings": null,
            "parameter": null,
            "displayType": "CardSelector",
            "meta": [
              {
                "key": "icon",
                "value": ""
              }
            ],
            "reportPageItems": [
              {
                "key": "FilterBar",
                "enabled": true,
                "reportPageItemType": "FilterBar",
                "dataSourceNames": [],
                "settings": {
                  "filters": [
                    {
                      "filterType": "GroupFilter",
                      "parameter": "selectedActionplanGroups",
                      "filterSettings": {
                        "dependsOnParameters": [
                          "currentGroup"
                        ],
                        "hideHierarchies": false,
                        "backgroundFilter": false,
                        "disableNonActionPlanGroups": true
                      }
                    },
                    {
                      "filterType": "TimeFilter",
                      "parameter": "actionplanExploreTimeFrame",
                      "filterSettings": {
                        "invalidates": true
                      }
                    },
                    {
                      "filterType": "StatusFilter"
                    },
                    {
                      "filterType": "AreaFilter"
                    },
                    {
                      "filterType": "SnapshotFilter",
                      "parameter": "selectedActionplanSnapshots",
                      "filterSettings": {
                        "dependsOnGroupParameter": "currentGroup",
                        "invalidates": true
                      }
                    }
                  ]
                }
              },
              {
                "key": "ActionPlanSummaryList",
                "enabled": true,
                "reportPageItemType": "ActionPlanSummaryList",
                "dataSourceNames": [
                  "Selected actionplan groups",
                  "Selected actionplans"
                ]
              }
            ]
          }
        ]
      }
    ]
  }
]
