import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateModuleCategory, ModuleCategory } from 'app/shared/models';
import { ConfigurationService } from 'app/shared/services/configuration.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModuleCategoryService {

  constructor(private _http: HttpClient, private _config: ConfigurationService) {
  }

  moduleCategories(): Observable<ModuleCategory[]> {
    return this._http.get<ModuleCategory[]>(`${this._config.apiUrl}/api/module-categories`);
  }
  getForEdit(id: number): Observable<CreateModuleCategory> {
    return this._http.get<CreateModuleCategory>(`${this._config.apiUrl}/api/module-categories/${id}/edit`);
  }
  create(model: CreateModuleCategory): Observable<ModuleCategory> {
    return this._http.post<ModuleCategory>(`${this._config.apiUrl}/api/module-categories`, model);
  }
  update(model: CreateModuleCategory): Observable<void> {
    return this._http.put<void>(`${this._config.apiUrl}/api/module-categories/${model.id}`, model);
  }
}
