import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UIKitAnchor, UIKitButton } from './button';
import { UIKitIconModule } from '../icon';

@NgModule({
    declarations: [UIKitButton, UIKitAnchor],
    imports: [CommonModule, UIKitIconModule],
    exports: [UIKitButton, UIKitAnchor]
})
export class UIKitButtonModule { }
